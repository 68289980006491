import { useContext, useState } from "react";
import Base from "./Base";
import { useParams } from "react-router-dom";
import { useDocumentTitle, useFetch } from "../hooks";
import PrivacyToggle from "../components/PrivacyToggle";
import FinishedEditingLink from "../components/FinishedEditingLink";
import ProjectDetailsForm from "../components/ProjectDetailsForm";
import { UserContext } from "../contexts";
import DeleteProject from "../components/DeleteProject";
import Permissions from "../components/Permissions";
import ProjectMetadataEditor from "../components/ProjectMetadataEditor";

const EditProjectPage = () => {

  const { id } = useParams();

  const [project, setProject] = useState(null);

  const [user,] = useContext(UserContext);
  console.log(user);

  const { loading, status } = useFetch(`/projects/${id}/edit`, {
    onCompleted: project => setProject(project)
  });
  const { data: organisms, loading: organismsLoading } = useFetch("/organisms");
  const { data: sources, loading: sourcesLoading } = useFetch("/samples/sources", {params: {count: 5}});
  const { data: targets, loading: targetsLoading } = useFetch("/samples/purification_targets", {params: {count: 5}});


  useDocumentTitle(project ? `Edit ${project.name} - Flow` : "Flow");

  if (loading || organismsLoading || sourcesLoading || targetsLoading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const isOwned = project.owner ? user.username === project.owner.username : user.memberships.map(m => m.slug).includes(project.group_owner.slug);

  return (
    <Base>
      <div className="flex flex-wrap items-start break-all justify-between gap-x-4 gap-y-1 mb-4 md:mb-10">
        <h1 className="mb-0">{project.name}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={project} setObject={setProject} />
      <ProjectDetailsForm project={project} setProject={setProject} />
      {project.samples.length > 1 && (
        <ProjectMetadataEditor
          project={project}
          setProject={setProject}
          organisms={organisms}
          initialSources={sources}
          initialTargets={targets}
        />
      )}
      
      {project.can_share && (
        <Permissions object={project} setObject={setProject} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}
      {isOwned && <DeleteProject project={project} />}
    </Base>
  );
};

EditProjectPage.propTypes = {
  
};

export default EditProjectPage;