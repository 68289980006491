import { useContext, useState } from "react";
import Base from "./Base";
import { useParams } from "react-router-dom";
import { useFetch, useLazyFetch, useDocumentTitle } from "../hooks";
import PrivacyToggle from "../components/PrivacyToggle";
import MetadataForm from "../components/MetadataForm";
import FinishedEditingLink from "../components/FinishedEditingLink";
import { UserContext } from "../contexts";
import DeleteSample from "../components/DeleteSample";
import Permissions from "../components/Permissions";
import Button from "../components/Button";

const EditSamplePage = () => {

  const { id } = useParams();

  const [sample, setSample] = useState(null);
  const [metadata, setMetadata] = useState({});
  const [metadataErrors, setMetadataErrors] = useState({});

  const [user,] = useContext(UserContext);

  const { loading, status } = useFetch(`/samples/${id}/edit`, {
    onCompleted: sample => setSample(sample)
  });
  const { data: organisms, loading: organismsLoading } = useFetch("/organisms");
  const { data: ownedProjects, loading: ownedProjectsLoading } = useFetch("/projects/owned");

  const [{loading: updateLoading}, updateSample] = useLazyFetch(`/samples/${id}/edit`, {
    method: "POST",
    onCompleted: sample => setSample(sample),
    onError: error => setMetadataErrors(error.error)
  });

  useDocumentTitle(sample ? `Edit ${sample.name} - Flow` : "Flow");

  if (loading || organismsLoading || ownedProjectsLoading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const metadataToUse = {
    name: "name" in metadata ? metadata.name : sample.name,
    category: "category" in metadata ? metadata.category : sample.category,
    organism: "organism" in metadata ? metadata.organism : sample.organism?.id,
    project: "project" in metadata ? metadata.project : sample.project?.id,
    source: "source" in metadata ? metadata.source : sample.source,
    source_text: "source_text" in metadata ? metadata.source_text : sample.source_text,
    purification_target: "purification_target" in metadata ? metadata.purification_target : sample.purification_target,
    purification_target_text: "purification_target_text" in metadata ? metadata.purification_target_text : sample.purification_target_text,
    scientist: "scientist" in metadata ? metadata.scientist : sample.scientist,
    pi: "pi" in metadata ? metadata.pi : sample.pi,
    organisation: "organisation" in metadata ? metadata.organisation : sample.organisation,
    purification_agent: "purification_agent" in metadata ? metadata.purification_agent : sample.purification_agent,
    experimental_method: "experimental_method" in metadata ? metadata.experimental_method : sample.experimental_method,
    condition: "condition" in metadata ? metadata.condition : sample.condition,
    sequencer: "sequencer" in metadata ? metadata.sequencer : sample.sequencer,
    comments: "comments" in metadata ? metadata.comments : sample.comments,
    five_prime_barcode_sequence: "five_prime_barcode_sequence" in metadata ? metadata.five_prime_barcode_sequence : sample.five_prime_barcode_sequence,
    three_prime_barcode_sequence: "three_prime_barcode_sequence" in metadata ? metadata.three_prime_barcode_sequence : sample.three_prime_barcode_sequence,
    three_prime_adapter_name: "three_prime_adapter_name" in metadata ? metadata.three_prime_adapter_name : sample.three_prime_adapter_name,
    three_prime_adapter_sequence: "three_prime_adapter_sequence" in metadata ? metadata.three_prime_adapter_sequence : sample.three_prime_adapter_sequence,
    read1_primer: "read1_primer" in metadata ? metadata.read1_primer : sample.read1_primer,
    read2_primer: "read2_primer" in metadata ? metadata.read2_primer : sample.read2_primer,
    rt_primer: "rt_primer" in metadata ? metadata.rt_primer : sample.rt_primer,
    umi_barcode_sequence: "umi_barcode_sequence" in metadata ? metadata.umi_barcode_sequence : sample.umi_barcode_sequence,
    umi_separator: "umi_separator" in metadata ? metadata.umi_separator : sample.umi_separator,
    strandedness: "strandedness" in metadata ? metadata.strandedness : sample.strandedness,
    rna_selection_method: "rna_selection_method" in metadata ? metadata.rna_selection_method : sample.rna_selection_method,
    geo: "geo" in metadata ? metadata.geo : sample.geo,
    ena: "ena" in metadata ? metadata.ena : sample.ena,
    pubmed: "pubmed" in metadata ? metadata.pubmed : sample.pubmed,
  }

  const save = () => {
    setMetadataErrors({});
    updateSample({params: metadata})
  }

  const isOwned = sample.owner ? user.username === sample.owner.username : user.memberships.map(m => m.slug).includes(sample.group_owner.slug);

  return (
    <Base>
      <div className="flex flex-wrap items-start break-all justify-between gap-x-4 gap-y-1 mb-4 md:mb-10">
        <h1 className="mb-0">{sample.name}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={sample} setObject={setSample} />
      <div className="edit-section">
        <div className="edit-heading">Metadata</div>
        <MetadataForm
          metadata={[metadataToUse]}
          setMetadata={m => setMetadata(m[0])}
          organisms={organisms}
          projects={ownedProjects.projects}
          errors={[metadataErrors]}
        />
        <Button className="btn-primary mt-10" onClick={save} loading={updateLoading}>
          Save Metadata
        </Button>
      </div>
      {sample.can_share && (
        <Permissions object={sample} setObject={setSample} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}
      {isOwned && <DeleteSample sample={sample} />}
    </Base>
  );
};

EditSamplePage.propTypes = {
  
};

export default EditSamplePage;