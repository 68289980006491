import React from "react";
import PropTypes from "prop-types";

const Table = props => {

  const { children } = props;

  const rowClass = "";

  return (
    <div className="overflow-y-auto rounded shadow w-full">
      <table className="border-collapse w-full border-0 overflow-hidden">
        <tbody>
          {React.Children.map(children, (child, index) => (
            React.cloneElement(child, {
              className: `${rowClass} ${index % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`
            })
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Table;