import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as AnonymousUser } from "../images/anonymous-user.svg";
import { Link } from "react-router-dom";

const UserImage = props => {

  const { user, useInitials, isLink, onClick, className } = props;

  const Element = isLink ? Link : "div";

  const initials = user.name.split(/[ -]/g).filter(x => x).map(word => word[0]).filter(c => c === c.toUpperCase()).join("");

  const width = className.match(/w-([0-9]+)/)[1] || 0;

  return (
    <Element to={`/users/${user.username}/`} className={`hover:no-underline flex-shrink-0 ${className}`} onClick={onClick}>
      {user.image && (
        <img
          className={`rounded-full object-cover border border-[#F2F2F2] border-opacity-50 ${className}`}
          src={`${process.env.REACT_APP_MEDIA}/${user.image}`} alt=""
        />
      )}

      {!user.image && useInitials && (
        <div className={`rounded-full flex items-center justify-center bg-[#D38181] text-white ${className}`}>
          {initials}
        </div>
      )}

      {!user.image && !useInitials && (
        <AnonymousUser
          alt="" fill="#B5B5B5"
          className={`bg-[#EEEEEE] rounded-full ${width > 15 ? "p-3" : width > 5 ? "p-2" : "p-1"} ${className}`}
        />
      )}
    </Element>
  )

};

UserImage.propTypes = {
  user: PropTypes.object.isRequired,
  useInitials: PropTypes.bool,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
};

export default UserImage;