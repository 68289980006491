import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const PekaSearcher = props => {

  const { proteins, motifs } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const searchSelected = name => {
    navigate(`/peka?${name.length === 5 ? "motif" : "rbp"}=${name}`);
    setSearchTerm("");
  }

  const searchData = proteins.concat(motifs);

  const filteredData = searchData.filter(d => d.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="w-48 relative">
      <input
        className="w-full border border-gray-200 rounded py-1 px-2 placeholder:text-xs placeholder:text-gray-300"
        value={searchTerm}
        placeholder="Search for a protein or motif"
        onChange={e => setSearchTerm(e.target.value)}
      />
      {searchTerm.length > 0 && (
        <div className="absolute bg-white top-9 text-sm border border-gray-300 rounded w-full max-w-sm mt-1 h-36 z-40 overflow-y-auto">
          {filteredData.map(d => (
            <div
              key={d}
              className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => searchSelected(d)}
            >
              {d}
            </div>
          ))}
          {filteredData.length === 0 && (
            <div className="mx-auto w-fit my-16">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PekaSearcher.propTypes = {
  proteins: PropTypes.array.isRequired,
  motifs: PropTypes.array.isRequired,
};

export default PekaSearcher;