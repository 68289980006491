import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";

const AnnotationWarningModal = props => {

  const { warnings, setShowModal, upload } = props;

  const uploadAnyway = e => {
    setShowModal(false);
    upload(e, true);
  }

  return (
    <Modal setShowModal={setShowModal} className="max-w-lg text-yellow-600" closable={true}>
      <div className="text-yellow-600 font-medium text-sm mb-4">
        The annotation sheet was valid, but some columns had non-standard terms.
        Please check that these are the correct terms and spellings, and continue if so.
      </div>
      <div className="flex flex-col gap-6">
        {warnings.map((warning, i) => {
          const hasError = Object.keys(warning).length > 0;
          if (!hasError) return null;
          return (
            <div key={i} className="border border-yellow-600 text-yellow-700 rounded py-1 px-2">
              <div className="font-medium text-sm mb-1 ">Sample {i + 1}</div>
              <ul className="text-sm">
                {Object.keys(warning).map(key => (
                  <li key={key} className="list-disc ml-4">
                    <span className="font-medium ">{key}</span> <span className="text-xs text-yellow-600">{warning[key]}</span>
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>
      <div className="flex gap-4 mt-7">
        <Button className="btn-primary" onClick={uploadAnyway}>Upload Anyway</Button>
        <Button className="btn-secondary" onClick={() => setShowModal(false)}>Go Back</Button>
      </div>
    </Modal>
  );
};

AnnotationWarningModal.propTypes = {
  warnings: PropTypes.array.isRequired,
  setShowModal: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
};

export default AnnotationWarningModal;