import React from "react";
import PropTypes from "prop-types";
import Itemiser from "./Itemiser";
import ProjectCard from "./ProjectCard";

const ProjectGrid = props => {

  const { projects, pageLength, noMessage, loading, currentPage, totalItems, pageChange } = props;

  const filter = (projects, query) => projects.filter(p => {
    return (
      p.name.toLowerCase().includes(query.toLowerCase())
    )
  })

  return (
    <Itemiser
      items={projects} pageLength={pageLength} keepHeight={true} noMessage={noMessage}
      loading={loading} currentPage={currentPage} totalItems={totalItems} pageChange={pageChange}
      filter={filter}
    >
      {
        ({items}) => (
          <div className="flex w-full flex-wrap gap-x-6 gap-y-5">
            {items.map(p => (
              <ProjectCard project={p} key={p.id} />
            ))}
          </div>
        )
      }
    </Itemiser>
  );
};

ProjectGrid.propTypes = {
  projects: PropTypes.array.isRequired,
  pageLength: PropTypes.number.isRequired,
  keepHeight: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  pageChange: PropTypes.func,
};

export default ProjectGrid;