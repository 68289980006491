import { useContext, useState } from "react";
import Base from "./Base";
import { useParams } from "react-router-dom";
import { useFetch, useDocumentTitle } from "../hooks";
import PrivacyToggle from "../components/PrivacyToggle";
import FinishedEditingLink from "../components/FinishedEditingLink";
import PairingEditor from "../components/PairingEditor";
import { UserContext } from "../contexts";
import DeleteData from "../components/DeleteData";
import Permissions from "../components/Permissions";

const EditDataPage = () => {

  const { id } = useParams();

  const [data, setData] = useState(null);

  const [user,] = useContext(UserContext);

  const { loading, status } = useFetch(`/data/${id}/edit`, {
    onCompleted: data => setData(data)
  });

  useDocumentTitle(data ? `Edit ${data.filename} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const isOwned = data.owner ? user.username === data.owner.username : user.memberships.map(m => m.slug).includes(data.group_owner.slug);

  return (
    <Base>
      <div className="flex flex-wrap items-start break-all justify-between gap-x-4 gap-y-1 mb-4 md:mb-10">
        <h1 className="mb-0">{data.filename}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={data} setObject={setData} />

      {data.category === 3 && isOwned && (
        <PairingEditor data={data} setData={setData} />
      )}
      {data.can_share && (
        <Permissions object={data} setObject={setData} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}

      {isOwned && <DeleteData data={data} />}
    </Base>
  );
};

EditDataPage.propTypes = {
  
};

export default EditDataPage;