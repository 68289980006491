import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { CHECKOUT_REPO } from "../mutations";

const RepoCheckoutModal = props => {

  const { repo, setShow } = props;

  const [output, setOutput] = useState("");
  const [branch, setBranch] = useState("");

  const [checkoutRepo, checkoutRepoMutation] = useMutation(CHECKOUT_REPO, {
    variables: {id: repo.id, branch},
    onCompleted: data => {
      setOutput(data.checkoutPipelineRepo.output);
    }
  });

  const message = "This will checkout a specific branch on the pipeline repo. This may be necessary before pulling from a new branch for the first time.";

  return (
    <Modal setShowModal={setShow} title="Checkout Repo" text={message} className="max-w-lg">
      <div className="w-fit mx-auto mb-4">
        <label className="">Branch</label>
        <input
          className="border-b text-sm rounded-none"
          value={branch}
          onChange={e => setBranch(e.target.value)}
        />
      </div>
      {(output || checkoutRepoMutation.loading) && (
        <div className="bg-slate-800 text-white font-mono text-sm h-fit max-h-96 whitespace-pre overflow-auto w-full p-3 mb-4 rounded">
          {output}
        </div>
      )}
      <div className="flex gap-3 mx-auto w-fit">
        <Button className={`btn-primary ${branch ? "" : "pointer-events-none opacity-50"}`} loading={checkoutRepoMutation.loading} onClick={checkoutRepo}>Checkout</Button>
        <button className="btn-secondary" onClick={() => setShow(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

RepoCheckoutModal.propTypes = {
  repo: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default RepoCheckoutModal;