import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Base from "./Base";
import { useDocumentTitle, useLazyFetch, useFetch } from "../hooks";
import Tabs from "../components/Tabs";
import ExecutionStatus from "../components/ExecutionStatus";
import ExecutionTiming from "../components/ExecutionTiming";
import ExecutionTerminals from "../components/ExecutionTerminals";
import CuratedOutputs from "../components/CuratedOutputs";
import ExecutionInputs from "../components/ExecutionInputs";
import DataTable2 from "../components/DataTable2";
import ExecutionReports from "../components/ExecutionReports";
import CancelExecution from "../components/CancelExecution";
import ObjectTitle2 from "../components/ObjectTitle2";
import ObjectAttributes from "../components/ObjectAttributes";
import ExecutionProcessExecutions from "../components/ExecutionProcessExecutions";
import ExecutionCommand from "../components/ExecutionCommand";
import { UserContext } from "../contexts";

const ExecutionPage = () => {

  const { id } = useParams();

  const [execution, setExecution] = useState(null);
  const [tab, setTab] = useState("General");
  const [user,] = useContext(UserContext);
  const [dataPage, setDataPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  const dataPageSize = 25;

  const { loading, data, status } = useFetch(`/executions/${id}`, {
    onCompleted: data => setExecution(data)
  });

  useDocumentTitle(data ? `${data.pipeline.name} - Flow` : "Flow");

  const [,refreshPage] = useLazyFetch(`/executions/${id}`, {
    onCompleted: data => setExecution(data)
  })

  useEffect(() => {
    if (execution && execution.finished) return;
    const interval = setInterval(() => refreshPage({
      params: {log: execution ? execution.log.length : 0},
      onCompleted: data => setExecution({...data, log: execution.log + data.log})
    }), 1000);
    return () => clearInterval(interval);
  }, [refreshPage, execution])

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const downstreamData = execution.process_executions.reduce((p, c) => [
    ...p, ...c.downstream_data.map(d => ({...d, process_execution_name: c.process_name}))
  ], []);
  downstreamData.sort((a, b) => a.created - b.created);

  const dataToUse = filteredData || downstreamData;

  const onFilter = s => {
    if (s) {
      const data = downstreamData.filter(d => (
        d.filename.toLowerCase().includes(s.toLowerCase()) ||
        d.process_execution_name.toLowerCase().includes(s.toLowerCase()
      )));
      const maxPossiblePage = Math.ceil(data.length / dataPageSize);
      setFilteredData(data);
      if (dataPage > maxPossiblePage) setDataPage(maxPossiblePage || 1);
    } else {
      setFilteredData(null)
    }
  }

  return (
    <Base>
      <ObjectTitle2 
        isPrivate={execution.private}
        title={`${execution.pipeline.name} [${execution.pipeline.version_name}]`} 
        type="execution"
        editUrl={execution.can_edit && `/executions/${id}/edit`}
        corner={<><CancelExecution execution={execution} /><ExecutionStatus execution={execution} /></>}
      />
      <ObjectAttributes object={execution} className="mb-6 md:mb-8 lg:mb-10" />
      <Tabs labels={["General", "Parameters", "Data Produced"]} selected={tab} setSelected={setTab} />
      {tab === "General" && (
        <div>
          <div className="flex gap-x-8 gap-y-6 border-b border-[#F2F2F2] pb-4 mb-8 sm:gap-x-12 sm:pb-6 sm:flex-wrap">
            <ExecutionTiming execution={execution} />
            <div className="flex flex-col items-start gap-y-4">
              <ExecutionTerminals execution={execution} />
              <ExecutionReports execution={execution} />
            </div>
          </div>
          <div className="flex flex-wrap-reverse gap-x-12 gap-y-12">
            <ExecutionProcessExecutions execution={execution} />
            <CuratedOutputs execution={execution} />
          </div>
        </div>
      )}
      {tab === "Parameters" && (
        <div className="flex flex-wrap gap-x-24 gap-y-8 items-start">
          <ExecutionInputs execution={execution} />
          {execution.command && <ExecutionCommand command={execution.command} className="max-w-sm" />}
        </div>
      )}
      {tab === "Data Produced" && downstreamData.length > 0 && (
        <DataTable2
          data={dataToUse.slice((dataPage - 1) * dataPageSize, dataPage * dataPageSize)}
          page={dataPage}
          pageSize={dataPageSize}
          setPage={setDataPage}
          totalCount={dataToUse.length}
          loading={false}
          onFilter={onFilter}
          onBulkDelete={user.username === execution.owner?.username ? refreshPage : null}
          noMessage="No data matches that term."
        />
      )}
      {tab === "Data Produced" && downstreamData.length === 0 && (
        <div className="info text-sm">No data has been produced.</div>
      )}
    </Base>
  )
}

ExecutionPage.propTypes = {
  
};

export default ExecutionPage;