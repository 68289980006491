import { useContext, useState } from "react";
import PropTypes from "prop-types";
import PermissionEditor from "./PermissionEditor";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts";
import GroupOwnerModal from "./GroupOwnerModal";
import UserOwnerModal from "./UserOwnerModal";

const Permissions = props => {

  const { object, setObject } = props;

  const [showUserOwnerModal, setShowUserOwnerModal] = useState(false);
  const [showGroupOwnerModal, setShowGroupOwnerModal] = useState(false);
  const [user,] = useContext(UserContext);

  const borderClass = "2xl:mr-8 2xl:pr-8 2xl:border-r 2xl:border-[#e8e8e8]";
  const ownerClass = "info mb-10 pl-2 text-sm max-w-sm";

  const isOwned = object.owner ? user.username === object.owner.username : user.memberships.filter(m => m.is_admin).map(m => m.slug).includes(object.group_owner.slug);

  return (
    <div className="edit-section">
      <div className="edit-heading">Permissions</div>
      {object.group_owner && (
        <div className={ownerClass}>
          <span>The group </span>
          <Link className="font-medium" to={`/groups/@${object.group_owner.slug}/`}>
            {object.group_owner.name}
          </Link>
          <span> owns this object, and all its members will also have full permissions on it.</span>
          {isOwned && (
            <span> You can <span onClick={() => setShowUserOwnerModal(true)} className="text-[#3B59C3] cursor-pointer hover:underline">transfer it to a member</span> if you wish.</span>
          )}
        </div>
      )}
      {object.owner && (
        <div className={ownerClass}>
          <span>The user </span>
          <Link className="font-medium" to={`/users/${object.owner.username}/`}>
            {object.owner.name}
          </Link>
          <span> owns this object, and they will also have full permissions on it. </span>
          {isOwned && (
            <span> You can <span onClick={() => setShowGroupOwnerModal(true)} className="text-[#3B59C3] cursor-pointer hover:underline">transfer it to a group</span> if you wish.</span>
          )}
        </div>
      )}
      <div className="flex flex-col gap-y-6 md:gap-y-12 2xl:flex-row">
        <PermissionEditor object={object} setObject={setObject} permission={1} className={borderClass} />
        <PermissionEditor object={object} setObject={setObject} permission={2} className={borderClass} />
        <PermissionEditor object={object} setObject={setObject} permission={3} className="" />
      </div>
      {showGroupOwnerModal && (
        <GroupOwnerModal object={object} setObject={setObject} setShowModal={setShowGroupOwnerModal} />
      )}
      {showUserOwnerModal && (
        <UserOwnerModal object={object} setObject={setObject} setShowModal={setShowUserOwnerModal} />
      )}
    </div>
  );
};

Permissions.propTypes = {
  object: PropTypes.object.isRequired,
  setObject: PropTypes.func.isRequired
};

export default Permissions;