import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import UserImage from "../components/UserImage";
import ProjectGrid from "../components/ProjectGrid";
import SampleTable from "../components/SampleTable";
import DataTable from "../components/DataTable";
import { useDocumentTitle } from "../hooks";
import { PUBLIC_USER, PUBLIC_USER_DATA, PUBLIC_USER_PROJECTS, PUBLIC_USER_SAMPLES } from "../queries";
import Base from "./Base";
import moment from "moment";
import Tabs from "../components/Tabs";
import GroupSummaries from "../components/GroupSummaries";

const UserPage = () => {

  const { username } = useParams();

  const [tab, setTab] = useState("Groups");
  const [publicProjects, setPublicProjects] = useState([]);
  const [publicProjectsCount, setPublicProjectsCount] = useState(null);
  const [projectsPage, setProjectsPage] = useState(1);
  const [publicSamples, setPublicSamples] = useState([]);
  const [publicSamplesCount, setPublicSamplesCount] = useState(null);
  const [samplesPage, setSamplesPage] = useState(1);
  const [publicData, setPublicData] = useState([]);
  const [publicDataCount, setPublicDataCount] = useState(null);
  const [dataPage, setDataPage] = useState(1);

  const projectsRowCount = 12;
  const samplesRowCount = 20;
  const dataRowCount = 25;

  const { loading, data } = useQuery(PUBLIC_USER, {variables: {username}});

  const { loading: projectsLoading } = useQuery(PUBLIC_USER_PROJECTS, {
    variables: {username, first: projectsRowCount * projectsPage, last: projectsRowCount},
    onCompleted: data => {
      setPublicProjectsCount(data.user.publicProjects.count);
      setPublicProjects(data.user.publicProjects.edges.map(e => e.node));
    }
  });

  const { loading: samplesLoading } = useQuery(PUBLIC_USER_SAMPLES, {
    variables: {username, first: samplesRowCount * samplesPage, last: samplesRowCount},
    onCompleted: data => {
      setPublicSamplesCount(data.user.publicSamples.count);
      setPublicSamples(data.user.publicSamples.edges.map(e => e.node));
    }
  });

  const { loading: dataLoading } = useQuery(PUBLIC_USER_DATA, {
    variables: {username, first: dataRowCount * dataPage, last: dataRowCount},
    onCompleted: data => {
      setPublicDataCount(data.user.publicData.count);
      setPublicData(data.user.publicData.edges.map(e => e.node));
    }
  });

  useDocumentTitle(data?.user ? `${data.user.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (!data.user) return <Base notFound={true} />

  const user = data.user;

  return (
    <Base>
      <h1>{user.name}</h1>
      <div className="flex items-center gap-2 my-6 sm:gap-4">
        <UserImage user={user} className="w-16 h-16 sm:w-24 sm:h-24" />
        <div>
          <div className="text-lg font-medium text-[#3B59C3] sm:mb-0.5 sm:text-2xl">{user.username}</div>
          <div className="text-sm text-[#595959] sm:text-base">Joined <time>{moment(user.created * 1000).format("Do MMMM YYYY")}</time></div>
        </div>
      </div>

      <Tabs labels={["Groups", "Projects", "Samples", "Data"]} selected={tab} setSelected={setTab} />
      {tab === "Groups" && (
        <div className="flex flex-wrap gap-y-6 gap-x-12">
          {user.memberships.map(group => (
            <Link className="hover:no-underline max-w-md" to={`/groups/@${group.slug}/`} key={group.id}>
              <div className="flex gap-2 font-medium">
                <div>{group.name}</div>
                <div className="text-[#3B59C3]">@{group.slug}</div>
              </div>
              <div className="text-sm">{group.description}</div>
            </Link>
          ))}
          {user.memberships.length === 0 && (
            <div>User is not a member of any groups.</div>
          )}
        </div>
      )}
      {tab === "Projects" && (
        <ProjectGrid
          projects={publicProjects}
          pageLength={projectsRowCount}
          noMessage="User has no public projects."
          loading={projectsLoading}
          currentPage={projectsPage}
          totalItems={publicProjectsCount}
          pageChange={setProjectsPage}
        />
      )}
      {tab === "Samples" && (
        <SampleTable
          samples={publicSamples}
          pageLength={samplesRowCount}
          noMessage="User has no public samples."
          loading={samplesLoading}
          currentPage={samplesPage}
          totalItems={publicSamplesCount}
          pageChange={setSamplesPage}
          refetch={[{query: PUBLIC_USER_SAMPLES, variables: {username, first: samplesRowCount * samplesPage, last: samplesRowCount}}]}
        />
      )}
      {tab === "Data" && (
        <DataTable
          data={publicData}
          pageLength={dataRowCount}
          noMessage="User has no public data."
          loading={dataLoading}
          currentPage={dataPage}
          totalItems={publicDataCount}
          pageChange={setDataPage}
          refetch={[{query: PUBLIC_USER_DATA, variables: {username, first: dataRowCount * dataPage, last: dataRowCount}}]}
        />
      )}
    </Base>
  );
};

UserPage.propTypes = {
  
};

export default UserPage;