import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as GitIcon } from "../images/git.svg";
import { ReactComponent as LockIcon } from "../images/private.svg";
import PipelineVersionForm from "./PipelineVersionForm";
import DeletePipelineVersion from "./DeletePipelineVersion";

const PipelineVersionAdmin = props => {

  const { pipelineVersion, pipelineId, allGenomeVersions } = props;

  const labelClass = "font-sans font-medium";
  const pathClass = "font-mono text-xs text-[#4A4A4A]";

  const [editing, setEditing] = useState(false);

  if (editing) return (
    <PipelineVersionForm
      pipelineVersion={pipelineVersion}
      setShow={setEditing}
      pipelineId={pipelineId}
      allGenomeVersions={allGenomeVersions}
    />
  );

  return (
    <div className={`border-t pt-2 px-3 relative ${pipelineVersion.active ? "" : "opacity-50"}`}>
      <div className="absolute top-1 right-1 text-2xs flex items-baseline gap-1">
        {pipelineVersion.executionCount === 0 && <DeletePipelineVersion version={pipelineVersion} />}
        <div
          className="link font-normal inline-block text-[#3B59C3] hover:underline"
          onClick={() => setEditing(true)}
        >
          Edit
        </div>
      </div>
      <div className="flex items-baseline gap-2 mb-1">
        <div className="text-lg font-medium">{pipelineVersion.name}</div>
        <div className="font-mono">(<GitIcon className="inline w-4 h-auto mr-1" />{pipelineVersion.git})</div>
        {pipelineVersion.private && <LockIcon className="inline w-4 h-auto mr-1" />}
      </div>
      <div className="flex flex-col gap-1">
        <div className={pathClass}>
          <span className={labelClass}>Path: </span>
          {pipelineVersion.path}
        </div>
        <div className={pathClass}>
          <span className={labelClass}>Schema: </span>
          {pipelineVersion.schemaPath}
        </div>
        <div className={pathClass}>
          <span className={labelClass}>Config: </span>
          {pipelineVersion.configPaths}
        </div>
      </div>
    </div>
  );
};

PipelineVersionAdmin.propTypes = {
  pipelineVersion: PropTypes.object.isRequired,
};

export default PipelineVersionAdmin;