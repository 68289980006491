import React from "react";
import PropTypes from "prop-types";

const Input = props => {

  const {
    className, type, value, onChange, required, autoCapitalize, autoComplete,
    errors, textarea, containerClassName
  } = props;

  const hasError = errors && errors.length > 0;

  const Element = textarea ? "textarea" : "input";

  const textStripped = className?.replace(/text-\[#[0-9a-fA-F]+\]/g, "").replace(/text-[a-z]+-\d+/g, "");


  return (
    <div className={containerClassName || ""}>
      {hasError && (
        <div>
          {errors.map(error => <div className="text-xs text-red-600 mb-0.5">{error}</div>)}
        </div>
      )}
      <Element
        className={hasError ? textStripped + " border-red-700 border-2 text-red-700" : className}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
      />
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  autoComplete: PropTypes.string,
  errors: PropTypes.array,
  textarea: PropTypes.bool,
};

export default Input;