import React from "react";
import { useDocumentTitle } from "../hooks";
import Div100vh from "react-div-100vh";
import AuthForm from "../components/AuthForm";

const SignupPage = () => {

  useDocumentTitle("Sign In - Flow");

  return (
    <Div100vh className="flex">
      <AuthForm isLogin={false} />
    </Div100vh>
  );
};

SignupPage.propTypes = {
  
};

export default SignupPage;