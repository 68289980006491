import React, { useState } from "react";
import PropTypes from "prop-types";
import PipelineVersionAdmin from "../components/PipelineVersionAdmin";
import PipelineForm from "./PipelineForm";
import DeletePipeline from "./DeletePipeline";
import PipelineVersionForm from "./PipelineVersionForm";
import { ReactComponent as DownIcon } from "../images/down.svg";
import { useMutation } from "@apollo/client";
import { MOVE_PIPELINE_DOWN, MOVE_PIPELINE_UP } from "../mutations";
import { PIPELINE_ADMIN } from "../queries";

const PipelineAdmin = props => {

  const { pipeline, subcategories, genomePipelineVersions, repoId, isFirst, isLast } = props;

  const [editing, setEditing] = useState(false);
  const [showNewVersion, setShowNewVersion] = useState(false);

  const [moveUp,] = useMutation(MOVE_PIPELINE_UP, {
    variables: { id: pipeline.id },
    refetchQueries: [{ query: PIPELINE_ADMIN }],
  });

  const [moveDown,] = useMutation(MOVE_PIPELINE_DOWN, {
    variables: { id: pipeline.id },
    refetchQueries: [{ query: PIPELINE_ADMIN }],
  });

  const executionCount = pipeline.versions.reduce((acc2, version) => {
    return acc2 + version.executionCount;
  }, 0);

  if (editing) return (
    <PipelineForm
      pipeline={pipeline}
      setShow={setEditing}
      repoId={repoId}
      subcategories={subcategories}
    />
  );

  return (
    <div className="border relative rounded py-3 w-fit">
      <div className="absolute top-1 right-1 text-xs flex items-baseline gap-1">
        {executionCount === 0 && <DeletePipeline pipeline={pipeline} />}
        <div
          className="link font-normal inline-block text-[#3B59C3] hover:underline"
          onClick={() => setEditing(true)}
        >
          Edit
        </div>
        {!isFirst && (
          <DownIcon className="cursor-pointer relative bottom-0 h-3 pb-px w-auto rotate-90 fill-[#3B59C3] inline" onClick={moveUp} />
        )}
        {!isLast && (
          <DownIcon className="cursor-pointer relative bottom-0 h-3 pt-px w-auto -rotate-90 fill-[#3B59C3] inline" onClick={moveDown} />
        )}
      </div>
      <div className="text-xl font-medium px-3">{pipeline.name}</div>
      <div className="text-sm text-gray-400 px-3">
        {pipeline.subcategory.category.name}/{pipeline.subcategory.name}
      </div>
      <div className="mt-2 flex flex-col gap-4">
        {pipeline.versions.map(version => (
          <PipelineVersionAdmin
            key={version.id}
            pipelineVersion={version}
            pipelineId={pipeline.id}
            allGenomeVersions={genomePipelineVersions}
          />
        ))}
        <div className="border-t pt-3">
          {showNewVersion && (
            <PipelineVersionForm
              pipelineId={pipeline.id}
              setShow={setShowNewVersion}
              allGenomeVersions={genomePipelineVersions}
            />
          )}
          {!showNewVersion && (
            <button className="btn-primary text-xs py-1 px-2.5 mx-auto" onClick={() => setShowNewVersion(true)}>
              Add New Version
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

PipelineAdmin.propTypes = {
  pipeline: PropTypes.object.isRequired,
  subcategories: PropTypes.array.isRequired,
  genomePipelineVersions: PropTypes.array.isRequired
};

export default PipelineAdmin;