import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as ExternalLink } from "../images/external.svg";

const SampleInfo = props => {

  const { sample } = props;

  const labelClass = "text-[#777777]";
  const valueClass = "font-medium";

  const textFields = [
    ["source", "Source"],
    ["purification_target", "Purification Target"],

    ["scientist", "Scientist"],
    ["pi", "PI"],
    ["organisation", "Organisation"],
    ["purification_agent", "Purification Agent"],
    ["experimental_method", "Experimental Method"],
    ["condition", "Condition"],
    ["sequencer", "Sequencer"],
    ["comments", "Comments"],

    ["five_prime_barcode_sequence", "5' Barcode Sequence"],
    ["three_prime_barcode_sequence", "3' Barcode Sequence"],
    ["three_prime_adapter_name", "3' Adapter Name"],
    ["three_prime_adapter_sequence", "3' Adapter Sequence"],
    ["read1_primer", "Read 1 Primer"],
    ["read2_primer", "Read 2 Primer"],
    ["rt_primer", "RT Primer"],

    ["umi_barcode_sequence", "UMI Barcode Sequence"],
    ["umi_separator", "UMI Separator"],

    ["strandedness", "Strandedness"],
    ["rna_selection_method", "RNA Selection Method"],

    ["geo", "GEO", "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc="],
    ["ena", "ENA", "https://www.ebi.ac.uk/ena/browser/view/"],
    ["pubmed", "PubMed", "https://pubmed.ncbi.nlm.nih.gov/"],
  ]

  return (
    <div className={`bg-[#F9F9F9] rounded-md w-full max-w-2xl p-3 flex flex-col gap-x-3 gap-y-1.5 sm:p-4 sm:flex-row sm:flex-wrap ${props.className || ""}`}>
      <div>
        <span className={labelClass}>Category: </span>
        <span className={valueClass}>{sample.category || "Generic"}</span>
      </div>
      <div>
        <span className={labelClass}>Organism: </span>
        <span className={valueClass}>{sample.organism ? <Link to={`/organisms/${sample.organism.id}/`}>{sample.organism.name}</Link> : "N/A"}</span>
      </div>
      {textFields.filter(f => sample[f[0]]).map(([attribute, name, url]) => {
        const Element = url ? "a" : "div";
        return (
          <Element key={attribute} href={url ? url + sample[attribute] : null} className="block">
            <span className={labelClass}>{name}: </span>
            <span className={valueClass}>{sample[attribute] === "polya" && attribute === "rna_selection_method" ? "polyA" : sample[attribute]}</span>
            {url && <ExternalLink className="w-2.5 relative bottom-0.5 ml-1 h-auto inline" />}
          </Element>
        )
      })}
    </div>
  );
};

SampleInfo.propTypes = {
  sample: PropTypes.object.isRequired
};

export default SampleInfo;