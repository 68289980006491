import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { truncateText } from "../utils";

const ProjectCard2 = props => {

  const { project } = props;

  return (
    <Link
      to={`/projects/${project.id}/`}
      className="bg-[#F9F9F9] border-[#F8F9FB] shadow rounded px-4 py-3 w-full flex flex-col justify-between max-w-sm hover:no-underline hover:bg-[#F6F6F6]"
    >
      <div>
        <div className="text-[#3B59C3] text-lg font-medium mb-0.5">{project.name}</div>
        <div className="text-xs mb-2">{truncateText(project.description, 150)}</div>
      </div>
      <div className="text-xs text-right font-medium">{project.owner_name}</div>
    </Link>
  );
};

ProjectCard2.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectCard2;