import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { DELETE_PIPELINE } from "../mutations";
import { useMutation } from "@apollo/client";
import Button from "./Button";
import { PIPELINE_ADMIN } from "../queries";

const DeletePipeline = props => {

  const { pipeline } = props;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const [deletePipeline, deletePipelineMutation] = useMutation(DELETE_PIPELINE, {
    variables: {id: pipeline.id},
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShowModal(false),
    onError: () => setError("There was a problem deleting the pipeline.")
  });

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  const text = `There are no executions from this pipeline.`

  return (
    <div>
      <button
        className="text-xs text-red-600"
        onClick={() => setShowModal(true)}
      >
        Delete
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={`Delete ${pipeline.name}?`} text={text}>
          {error && <div className="text-red-900 text-center text-sm mb-4">{error}</div>}
          <div className="flex gap-3 mx-auto w-fit mt-4">
            <Button className={primaryClass} loading={deletePipelineMutation.loading} onClick={deletePipeline}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeletePipeline.propTypes = {
  pipeline: PropTypes.object.isRequired,
};

export default DeletePipeline;