import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import * as Sentry from "@sentry/react";
import "./index.css";

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/*.flow.bio/,],
      }),
      new Sentry.Replay()
    ],
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App />
);


