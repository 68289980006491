import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const SearchInput = props => {

  const { label, value, setValue, type, values } = props;

  const style = {
    control: {background: "#F3F3F3", height: 36},
    singleValue: {color: "#3B59C3", fontSize: 15}
  };

  return (
    <div className={props.className || ""}>
      <label className="font-medium mb-0.5 text-sm block">{label}</label>
      {values && (
        <Select
          options={values} value={value} setValue={value => setValue(value)} 
          style={style}
        />
      )}
      {!values && (
        <input
          type={type || "text"}
          className="bg-[#F3F3F3] rounded py-2 text-sm text-[#3B59C3] w-full font-medium"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  values: PropTypes.array
};

export default SearchInput;