import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { PULL_REPO } from "../mutations";

const RepoPullModal = props => {

  const { repo, setShow } = props;

  const [output, setOutput] = useState("");

  const [pullRepo, pullRepoMutation] = useMutation(PULL_REPO, {
    variables: {id: repo.id},
    onCompleted: data => {
      setOutput(data.pullPipelineRepo.output);
    }
  });

  const message = "This will pull the latest changes from the remote repository. Consult the git branch/tag of each pipeline to determine whether this will have any immediate effects.";

  return (
    <Modal setShowModal={setShow} title="Pull Repo" text={message} className="max-w-lg">
      {(output || pullRepoMutation.loading) && (
        <div className="bg-slate-800 text-white font-mono text-sm h-fit max-h-96 whitespace-pre overflow-auto w-full p-3 mb-4 rounded">
          {output}
        </div>
      )}
      <div className="flex gap-3 mx-auto w-fit">
        <Button className="btn-primary" loading={pullRepoMutation.loading} onClick={pullRepo}>Pull</Button>
        <button className="btn-secondary" onClick={() => setShow(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

RepoPullModal.propTypes = {
  repo: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default RepoPullModal;