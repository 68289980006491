import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PekaMotif = props => {
  const { motif, download } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);
    fetch(
      `${process.env.REACT_APP_BACKEND}/graphql`.replace("graphql", `peka/motif/lines?sequence=${motif}`)
    ).then(resp => resp.json()).then(json => {
      setData(json);
    })
  }, [motif])

  const styleLookup = {dashed: "Dash"}
  
  const options = data && {
    chart: {zoomType: "xy", padding: 0, spacingBottom: 0},
    credits: {enabled: false},
    title: {text: data.title},
    legend: {itemStyle: {"fontWeight": "light"}},
    xAxis: {title: {text: data.x_label}, tickPositions: data.x_ticks},
    yAxis: {title: {text: data.y_label}, tickPositions: data.y_ticks},
    series: data.lines.map(line => ({
      data: line.xs.map((x, index) => ([x, line.ys[index]])),
      color: line.color + Math.floor(line.alpha * 255).toString(16),
      dashStyle: styleLookup[line.line_style] || "Solid",
      marker: {radius: 0}, lineWidth: line.line_width,
      name: line.label
    })),
    responsive: {  
      rules: [{  
        condition: {  
          minWidth: 500  
        },  
        chartOptions: {  
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
          },
        }  
      }]  
    }
  }

  if (!data) return <BarLoader css="margin: 64px 355px" />

  return (
    <div>
      <h2 className="font-semibold relative text-slate-600 text-3xl mb-2">
        {motif} motif enrichment profile in introns
        <button className="absolute text-xs font-medium text-[#3B59C3] -top-1.5 pl-1"onClick={() => download(data, motif)}>Download Data</button>
      </h2>

      <div className="text-xs sm:text-sm max-w-4xl mb-2 grid gap-2">
        The graph shows enrichment profiles of {motif} motif in the intron region
        for 20 RBP datasets with the highest PEKA-scores for this motif. Each
        line corresponds to k-mer occurrence at positions flanking the
        thresholded crosslink sites. Grey line represents a mean distribution
        for these 20 datasets and gives a general impression of motif-specific
        binding patterns.
      </div>

      <div className="text-xs sm:text-sm max-w-4xl pb-4 mb-6">
        <Link className="back text-[#3B59C3]" to="/peka/">Back to Main Heatmap</Link>
        <span> | </span>
        <Link className="back text-[#3B59C3]" to={`/peka?motif=${motif}`}>Back to Motif Page</Link>
      </div>

      {data && (
        <div className="max-w-4xl">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}

    </div>
  );
};

PekaMotif.propTypes = {
  motif: PropTypes.string.isRequired
};

export default PekaMotif;