import { useState } from "react";
import PropTypes from "prop-types";
import OrganismForm from "./OrganismForm";
import DeleteOrganism from "./DeleteOrganism";
import GenomeForm from "./GenomeForm";
import GenomeAdmin from "./GenomeAdmin";

const OrganismAdmin = props => {

  const { organism } = props;

  const [editing, setEditing] = useState(false);
  const [showNewGenome, setShowNewGenome] = useState(false);

  if (editing) return <OrganismForm organism={organism} setShow={setEditing} />;

  const genomeCount = organism.genomes.length;

  return (
    <div>
      <div className="text-2xl font-medium">
        {organism.id}: {organism.name}
        <div
          className="link font-normal relative bottom-4 text-sm inline-block text-[#3B59C3] link hover:underline"
          onClick={() => setEditing(true)}
        >
          Edit
        </div>
        {genomeCount === 0 && <DeleteOrganism organism={organism} />}
      </div>
      <div className="italic">{organism.latinName}</div>
      <div className="flex flex-col">
        {organism.genomes.map(genome => (
          <GenomeAdmin key={genome.id} genome={genome} organismId={organism.id} />
        ))}
        {showNewGenome && <GenomeForm organismId={organism.id} setShow={setShowNewGenome} />}
        {!showNewGenome && (
          <button className="btn-primary w-fit ml-6 py-1 text-sm mt-4" onClick={() => setShowNewGenome(true)}>
            Add New Genome
          </button>
        )}
      </div>
    </div>
  );
};

OrganismAdmin.propTypes = {
  organism: PropTypes.object.isRequired
};

export default OrganismAdmin;