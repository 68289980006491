import { useState } from "react";
import FileSelector from "./FileSelector";
import { useFetch, useLazyFetch } from "../hooks";
import Button from "./Button";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const DataImporter = () => {

  const { loading, data } = useFetch("/paths");

  const [root, setRoot] = useState(null);
  const [displayPath, setDisplayPath] = useState("");
  const [filePath, setFilePath] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [{ loading: importLoading }, importData] = useLazyFetch("/data/import", {
    method: "POST",
    params: {path: filePath},
    onCompleted: data => navigate(`/data/${data.id}`),
    onError: error => setError(error.error.path)
  });

  if (loading) return (
    <div className="flex justify-center items-center h-32">
      <ClipLoader size={70}/>
    </div>
  )

  const possibleRoots = [];
  if (data.user) possibleRoots.push({isUser: true, path: data.user});
  for (let group of data.groups) {
    if (group.path) {
      possibleRoots.push({group: group.name, isUser: false, path: group.path});
    }
    if (group.user_path) {
      possibleRoots.push({group: group.name, isUser: true, path: group.user_path});
    }
  }

  const rootToUse = root || possibleRoots[0];

  const changeRoot = path => {
    setRoot(path);
    setDisplayPath("");
    setFilePath(null);
  }

  return (
    <div>

      <div className="info mb-10 max-w-lg pl-2">
        Here you can bring data from the server into Flow. You can import data from your workspace or from a group workspace.
      </div>

      <div className="flex gap-16 mb-10">
        {possibleRoots.length === 1 && (
          <div>
            <div className="text-sm">{rootToUse.isUser ? rootToUse.group ? `Your workspace within ${rootToUse.group}` : "Your workspace" : `${rootToUse.group} group workspace`}</div>
            <div className="text-base text-[#3B59C3] font-mono">{rootToUse.path}</div>
          </div>
        )}
        {possibleRoots.length > 1 && (
          <div>
            <div className="text-sm mb-2">Where would you like to import data from?</div>
            <div className="border max-w-sm border-[#E8E8E8] divide-y divide-[#E8E8E8] rounded-lg overflow-hidden">
              {possibleRoots.map(root => (
                <div
                  key={root.path}
                  onClick={() => changeRoot(root)}
                  className={`py-1.5 px-3 cursor-pointer  ${root.path === rootToUse.path ? "bg-[#3B59C3] bg-opacity-10" : "hover:bg-gray-50"}`}
                >
                  <div className="text-xs">{root.isUser ? root.group ? `Your workspace within ${root.group}` : "Your workspace" : `${root.group} group workspace`}</div>
                  <div className="text-sm text-[#3B59C3]">{root.path}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
          <div className="text-base mb-2">
            {filePath && <div className="text-[#3B59C3] font-mono">{filePath}</div>}
            {!filePath && <div className="text-gray-500">No file selected</div>}
          </div>
          {error && <div className="text-red-500 text-sm mb-2">{error[0]}</div>}
          <Button
            loading={importLoading}
            onClick={importData}
            className={`btn-primary ${filePath ? "" : "opacity-40 pointer-events-none"}`}
          >
            Import data into Flow
          </Button>
        </div>
      </div>

      <FileSelector
        root={rootToUse.path}
        filePath={filePath}
        setFilePath={setFilePath}
        displayPath={displayPath}
        setDisplayPath={setDisplayPath}
      />
    </div>
  );
};

DataImporter.propTypes = {
  
};

export default DataImporter;