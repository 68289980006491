import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

const NonStandardWarningModal = props => {

  const { setShowModal, attribute, value, setValue } = props;

  const useAnyway = () => {
    setValue(value);
    setShowModal(false);
  }

  const selectAnother = () => {
    setValue(null);
    setShowModal(false);
  }

  const text = `'${value}' isn't a standard ${attribute} - if you pick it your sample can't be used in the global Flow dataset.`;

  return (
    <Modal
      setShowModal={setShowModal}
      title={`Non-standard ${attribute}`}
      className="max-w-md"
      text={text}
    >
      <div className="flex gap-2">
        <button className="btn-primary" onClick={useAnyway}>Use anyway</button>
        <button className="btn-secondary" onClick={selectAnother}>Select another</button>
      </div>
    </Modal>
  );
};

NonStandardWarningModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default NonStandardWarningModal;