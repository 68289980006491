import { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Modal from "./Modal";
import { useLazyFetch } from "../hooks";

const CancelExecution = props => {

  const { execution } = props;

  const [showModal, setShowModal] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  const [{ loading }, cancelExecution] = useLazyFetch(`/executions/${execution.id}/cancel`, {
    method: "POST",
    onError: () => {
      setWaiting(true);
      setError("There was a problem cancelling the execution.")
    }
  })

  const cancelClicked = () => {
    setWaiting(true);
    cancelExecution();
  };

  if (execution.finished) return null;

  const text = "Are you sure you want to cancel the run of this execution? It cannot be resumed.";

  const showLoading = loading || (waiting && !execution.finished);

  return (
    <div className="pb-1.5">
      <Button
        onClick={() => setShowModal(true)}
        className={`btn-tertiary w-fit py-0.5 px-1 -mr-1 relative top-1 text-xs md:px-2 ${props.className || ""} ${execution.pid ? "" : "pointer-events-none opacity-50"}`}
      >
        Cancel
      </Button>
      {showModal && !execution.finished && (
        <Modal
          setShowModal={setShowModal}
          className="w-full max-w-sm"
          title={`Cancel Execution?`} text={text}
        >
          {error && (
            <div className="text-red-600 text-sm text-center">{error}</div> 
          )}
          <div className="flex gap-3 flex-col w-full mx-auto mt-4 sm:w-64 sm:flex-row">
            <Button
              className="btn-primary py-1 w-full sm:w-1/2 sm:py-1.2"
              loading={showLoading}
              onClick={cancelClicked}
            >
              Yes, cancel
            </Button>
            <button className="btn-secondary py-1 w-full sm:w-1/2 sm:py-1.5" onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

CancelExecution.propTypes = {
  execution: PropTypes.object.isRequired
};

export default CancelExecution;