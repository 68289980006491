import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../contexts";
import Modal from "./Modal";
import UserImage from "./UserImage";
import { useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import Button from "./Button";
import UserSelector2 from "./UserSelector2";

const GroupAdminUserGrid = props => {

  const { group, setGroup } = props;

  const [user,] = useContext(UserContext);
  const [modalContent, setModalContent] = useState(null);
  const [newUser, setNewUser] = useState(null);

  const members = group.members.filter(u => !group.admins.map(a => a.username).includes(u.username));

  const [{ loading: demoting }, demote] = useLazyFetch(`/groups/${group.slug}/demote`, {
    method: "POST",
    onCompleted: setGroup
  });

  const [{ loading: promoting }, promote] = useLazyFetch(`/groups/${group.slug}/promote`, {
    method: "POST",
    onCompleted: setGroup
  });

  const [{ loading: removing }, remove] = useLazyFetch(`/groups/${group.slug}/remove`, {
    method: "POST",
    onCompleted: group => {
      setGroup(group);
      setModalContent(null);
    }
  });

  const [{ loading: inviting }, invite] = useLazyFetch(`/groups/${group.slug}/invite`, {
    method: "POST",
    onCompleted: group => {
      setGroup(group);
      setModalContent(null);
      setNewUser(null);
    }
  });

  const [{ loading: revoking }, revoke] = useLazyFetch(`/groups/${group.slug}/invite/cancel`, {
    method: "POST",
    onCompleted: setGroup
  });

  const headingClass = "text-xl font-medium mb-6 ml-1";
  const blockClass = "pt-10 border-t"
  const gridClass = "flex flex-wrap gap-x-12 gap-y-6";
  const userClass = "flex items-center gap-4";
  const imageClass = "w-20 h-20";
  const nameClass = "text-xl font-medium";
  const buttonsClass = "text-xs flex gap-2";
  const buttonClass = "cursor-pointer";

  const removeTitle = "Are you sure you want to remove XXX from the group?";
  const removeText = "They will need to be re-invited to rejoin the group.";
  const inviteTitle = "Are you sure you want to invite XXX to the group?";
  const inviteText = "Once accepted, they will have access to everything shared with the group.";

  return (
    <div className={`flex flex-col gap-12 relative ${props.className || ""}`}>
      {(promoting || demoting || revoking) && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center">
          <ClipLoader size={40} />
        </div>
      )}
      <div className={blockClass}>
        <h2 className={headingClass}>Admins</h2>
        <div className={gridClass}>
          {group.admins.map(admin => (
            <div key={admin.username} className={userClass}>
              <UserImage user={admin} className={imageClass} />
              <div>
                <div className={nameClass}>{admin.name}</div>
                <div className={buttonsClass}>
                  {admin.username !== user.username && (
                    <button
                      className={`${buttonClass} text-red-800`}
                      onClick={() => demote({params: {user: admin.username}})}
                    >
                      {admin.username === user.username ? "Resign" : "Demote"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={blockClass}>
        <h2 className={headingClass}>Members</h2>
        <div className={gridClass}>
          {members.map(member => (
            <div key={member.username} className={userClass}>
              <UserImage user={member} className={imageClass} />
              <div>
                <div className={nameClass}>{member.name}</div>
                <div className={buttonsClass}>
                  <button
                    className={`${buttonClass} text-green-800`}
                    onClick={() => promote({params: {user: member.username}})}
                  >
                    Promote
                  </button>
                  <button
                    className={`${buttonClass} text-red-800`}
                    onClick={() => setModalContent([removeTitle.replace("XXX", member.name), removeText, () => remove({params: {user: member.username}})])}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={blockClass}>
        <h2 className={headingClass}>Invitees</h2>

        <div className="flex w-full">
          <UserSelector2
            user={newUser}
            setUser={setNewUser}
            excludeGroup={group.slug}
            placeholder="Select a user to invite..."
            className="ml-1.5 w-full max-w-xs sm:w-auto sm:max-w-none"
            inputClass="bg-[#F9F9F9] border border-r-0 placeholder-text-[#777F9B] border-[#A5ACC6] rounded-l w-full flex-grow-1 block sm:w-64 mb-7 py-1.5 h-9 text-[#54618D] font-medium px-3 text-xs sm:text-sm"
            placeholderClass="text-[#A5ACC6] font-normal"
          />
          <button
            className={`btn-primary py-1.5 rounded-l-none h-9 text-sm sm:text-base ${newUser ? "" : "opacity-50 pointer-events-none"}`}
            onClick={() => setModalContent([inviteTitle.replace("XXX", newUser.name), inviteText, () => invite({params: {user: newUser.username}})])}
          >
            Invite
          </button>
        </div>
        <div className={gridClass}>
          {group.invitees.map(invitee => (
            <div key={invitee.username} className={userClass}>
              <UserImage user={invitee} className={`${imageClass} opacity-60`} />
              <div>
                <div className={`${nameClass} opacity-60`}>{invitee.name}</div>
                <div className={buttonsClass}>
                  <button
                    className={`${buttonClass} text-red-800`}
                    onClick={() => revoke({params: {user: invitee.username}})}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {modalContent && (
        <Modal
          className="max-w-lg"
          title={modalContent[0]}
          setShowModal={setModalContent}
          text={modalContent[1]}
        >
          <div className="flex gap-3 flex-col w-full mx-auto mt-4 sm:w-64 sm:flex-row">
            <Button
              className="btn-primary py-1 w-full sm:w-32"
              loading={removing || inviting}
              onClick={modalContent[2]}
            >
              Yes
            </Button>
            <button
              className="btn-secondary py-1 w-full sm:w-32"
              onClick={() => setModalContent(null)}
            >
              Go Back
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

GroupAdminUserGrid.propTypes = {
  group: PropTypes.object.isRequired,
};

export default GroupAdminUserGrid;