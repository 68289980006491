import React, { useRef, useState } from "react";
import FileInput from "./FileInput";
import UploadButton from "./UploadButton";
import DataSelector2 from "./DataSelector2";
import { useLazyFetch } from "../hooks";
import { upload } from "../upload";

const MultiplexedUploader2 = () => {

  const [files, setFiles] = useState([]);
  const [reads1, setReads1] = useState(null);
  const [progress, setProgress] = useState(null);
  const [dataIds, setDataIds] = useState({});
  const [error, setError] = useState("");
  const canceled = useRef(false);

  const [,uploadData] = useLazyFetch("/upload/multiplexed", {method: "POST", form: true});

  const sendChunk = async (filename, chunk, expectedFileSize, isLast, previous) => {
    const params = {
      blob: chunk, is_last: isLast, expected_file_size: expectedFileSize,
      data: previous?.data?.id || "", filename, reads1: reads1?.id || "",
    }
    return await uploadData({params});
  }

  const onNetworkError = () => {
    setError("There was a network error while uploading the data.");
  }

  const onError = () => {
    setError("There was a problem uploading the data.");
  }

  const upload_ = async () => {
    return upload(files, 1_000_000, setProgress, setDataIds, canceled, sendChunk, onNetworkError, onError);
  }

  const cancel = () => {
    setError("");
    setProgress(null);
    setDataIds({});
    setFiles([]);
    canceled.current = true;
  }

  const canCancel = progress && Object.values(progress).length > 0 && !error && Object.values(progress).some(p => p < 1);
  
  return (
    <div>
      <div className="text-[#515255] text-xl font-medium mb-3">Upload Multiplexed Reads</div>
      <FileInput
        files={files}
        setFiles={setFiles}
        multiple={false}
        accept=".fq,.fastq,.gz"
        allowDirectories={false}
        progress={progress}
        setProgress={setProgress}
        dataIds={dataIds}
        setDataIds={setDataIds}
        error={error}
        setError={setError}
      />
      <div className={`mb-4 ${files.length > 0 ? "mt-3" : ""}`}>
        <label className="text-sm font-medium mb-1">(Optional) Select file to pair with (reads 1 file must be uploaded first):</label>
        <DataSelector2
          inputClass="bg-[#F3F3F3] rounded w-full h-9 text-[#3B59C3] font-medium px-3"
          data={reads1}
          setData={setReads1}
          filetype="fq|fastq|fq.gz|fastq.gz"
          category={3}
          forceIsOwned={true}
          isSingle={true}
        />
      </div>
      <UploadButton files={files} onClick={upload_} cancel={canCancel ? cancel : null} />
    </div>
  );
};

MultiplexedUploader2.propTypes = {
  
};

export default MultiplexedUploader2;