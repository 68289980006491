import React from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";
import Paper from "./Paper";

const PublicPapers = props => {

  const { headingClass, papers } = props;

  return (
    <div className="text-[#505B60] text-sm pb-8 pt-6 border-b border-[#E0E0E0] lg:border-b-0 lg:pb-0">
      <h2 className={headingClass}>Papers with data on Flow</h2>
      {papers === null && <ClipLoader size={20} />}
      {papers && papers.length === 0 && <div className="info">Currently no papers</div>}
      {papers && papers.length > 0 && (
        <div className="grid grid-cols-1 gap-4 max-w-lg sm:grid-cols-2 md:max-w-xl lg:grid-cols-1 xl:grid-cols-2">
          {papers.map((paper, index) => (
            <Paper
              paper={paper} key={paper.id}
              lineClass={["border-purple-700", "border-orange-700", "border-green-400", "border-red-600"][index]}
            />
          ))}
        </div>
      )}
    </div>
  );
};

PublicPapers.propTypes = {
  headingClass: PropTypes.string.isRequired,
  papers: PropTypes.array,
};

export default PublicPapers;