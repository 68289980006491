import Base from "./Base";
import { useDocumentTitle } from "../hooks";
import Tabs from "../components/Tabs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SearchInputs from "../components/SearchInputs";
import { useQuery } from "@apollo/client";
import { SEARCH_DATA, SEARCH_EXECUTIONS, SEARCH_PROJECTS, SEARCH_SAMPLES } from "../queries";
import ProjectGrid from "../components/ProjectGrid";
import SamplesTable from "../components/SampleTable";
import ExecutionTable from "../components/ExecutionTable";
import DataTable from "../components/DataTable";
import { useState } from "react";

const SearchPage = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [projects, setProjects] = useState(null);
  const [samples, setSamples] = useState(null);
  const [executions, setExecutions] = useState(null);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);

  const projectsRowCount = 12;
  const samplesRowCount = 25;
  const executionsRowCount = 20;
  const dataRowCount = 50;

  const variables = [...searchParams.entries()].reduce(
    (p, c) => ({...p, [c[0]] : c[1]}), {}
  );
  
  const hasQuery = Object.values(variables).length > 0;

  const urlType = useParams().type;
  const tab = urlType ? urlType[0].toUpperCase() + urlType.slice(1) : "Projects";

  const setTab = tabName => {
    navigate(`/search/${tabName.toLowerCase()}/`);
    setProjects(null);
    setSamples(null);
    setExecutions(null);
    setData(null);
    setPage(1);
    setCount(null);
  };

  useDocumentTitle("Advanced Search - Flow");

  const { loading: projectsLoading } = useQuery(SEARCH_PROJECTS, {
    skip: !hasQuery || tab !== "Projects",
    variables: {...variables, first: page * projectsRowCount, last: projectsRowCount},
    onCompleted: data => {
      setProjects(data.searchProjects.edges.map(e => e.node));
      setCount(data.searchProjects.count);
    }
  })

  const { loading: samplesLoading } = useQuery(SEARCH_SAMPLES, {
    skip: !hasQuery || tab !== "Samples",
    variables: {...variables, first: page * samplesRowCount, last: samplesRowCount},
    onCompleted: data => {
      setSamples(data.searchSamples.edges.map(e => e.node));
      setCount(data.searchSamples.count);
    }
  })

  const { loading: executionsLoading } = useQuery(SEARCH_EXECUTIONS, {
    skip: !hasQuery || tab !== "Executions",
    variables: {...variables, first: page * executionsRowCount, last: executionsRowCount},
    onCompleted: data => {
      setExecutions(data.searchExecutions.edges.map(e => e.node));
      setCount(data.searchExecutions.count);
    }
  })

  const { loading: dataLoading } = useQuery(SEARCH_DATA, {
    skip: !hasQuery || tab !== "Data",
    variables: {...variables, first: page * dataRowCount, last: dataRowCount},
    onCompleted: data => {
      setData(data.searchData.edges.map(e => e.node));
      setCount(data.searchData.count);
    }
  })

  const loading = (
    projectsLoading || samplesLoading || executionsLoading || dataLoading
  ) && (!projects && !samples && !executions && !data);

  return (
    <Base>
      <h1>Advanced Search</h1>
      <Tabs labels={["Projects", "Samples", "Executions", "Data"]} selected={tab} setSelected={setTab} />
      
      <div className="flex flex-col 2xl:flex-row 2xl:gap-0">
        <SearchInputs
          type={tab} loading={loading}
          className="mb-10 pb-10 w-full border-[#F2F2F2] border-b sm:max-w-lg 2xl:max-w-sm 2xl:mr-10 2xl:border-r 2xl:pr-10"
        />

        {projects && (
          <ProjectGrid
            projects={projects} pageLength={projectsRowCount}
            noMessage="No projects match this search."
            loading={projectsLoading} currentPage={page}
            totalItems={count} pageChange={setPage}
          />
        )}

        {samples && (
          <SamplesTable
            samples={samples} pageLength={samplesRowCount}
            noMessage="No samples match this search."
            loading={samplesLoading} currentPage={page}
            totalItems={count} pageChange={setPage}
            refetch={[{query: SEARCH_SAMPLES, variables: {...variables, first: page * samplesRowCount, last: samplesRowCount}}]}
          />
        )}

        {executions && (
          <ExecutionTable
            executions={executions} pageLength={executionsRowCount}
            noMessage="No executions match this search."
            loading={executionsLoading} currentPage={page}
            totalItems={count} pageChange={setPage}
            refetch={[{query: SEARCH_EXECUTIONS, variables: {...variables, first: page * executionsRowCount, last: executionsRowCount}}]}
          />
        )}

        {data && (
          <DataTable
            data={data} pageLength={dataRowCount}
            noMessage="No data matches this search."
            loading={dataLoading} currentPage={page}
            totalItems={count} pageChange={setPage}
            refetch={[{query: SEARCH_DATA, variables: {...variables, first: page * dataRowCount, last: dataRowCount}}]}
          />
        )}
      </div>

    </Base>
  );
};

SearchPage.propTypes = {
  
};

export default SearchPage;