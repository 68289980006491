import PropTypes from "prop-types";
import Selector from "./Selector";
import ExecutionSelector2 from "./ExecutionSelector2";

const PipelineSectionFiller = props => {

  const {
    section, genomes, genomePipelineVersions, genomeId, setGenomeId,
    dataParams, setDataParams, lockedKeys, setLockedKeys,
    genomePrep, setGenomePrep, organism, setOrganism,
    inputClass, placeholderClass, selectOptionClass, selectOptionsClass
  } = props;

  const takesRawGenome = Boolean(genomes) && Object.values(section.properties).some(i => i.genome_file);
  const takesGenomePrep = genomePipelineVersions.length > 0 &&  section.takes_genome;

  if (!takesRawGenome && !takesGenomePrep) return null;

  const genomeSelected = genomeId => {
    if (genomeId === "-") {
      setGenomeId(null);
      const newDataParams = {...dataParams};
      for (const key of lockedKeys) {
        delete newDataParams[key];
      }
      setLockedKeys([]);
      setDataParams(newDataParams);
      return;
    }
    setGenomeId(genomeId);
    const genome = genomes.find(g => g.id === genomeId);
    const newDataParams = {...dataParams}; const newLockedKeys = [];
    for (const [key, input] of Object.entries(section.properties)) {
      if (input.genome_file === "fasta" && genome.fasta) {
        newDataParams[key] = genome.fasta;
        newLockedKeys.push(key);
      } else if (input.genome_file === "gtf" && genome.gtf) {
        newDataParams[key] = genome.gtf;
        newLockedKeys.push(key);
      } else if (input.genome_file) {
        const regex = new RegExp(input.genome_file);
        const data = genome.data.find(d => regex.test(d.filetype));
        if (data) {
          newDataParams[key] = data;
          newLockedKeys.push(key);
        }
      }
    }
    setDataParams(newDataParams);
    setLockedKeys(newLockedKeys);
  }

  const genomePrepSelected = execution => {
    setGenomePrep(execution);
    if (!execution) {
      const newDataParams = {...dataParams};
      for (const key of lockedKeys) {
        delete newDataParams[key];
      }
      setLockedKeys([]);
      setDataParams(newDataParams);
      setOrganism(null);
      setGenomeId(null);
      return;
    }
    const newDataParams = {...dataParams};
    const newLockedKeys = [];
    for (const [key, input] of Object.entries(section.properties)) {
      if (input.genome_output) {
        const data = execution.downstream_data?.find(d => {
          const processNames = d.process_execution_name.split(":");
          const matchNames = input.genome_output.process.split(":");
          const relevantProcessNames = processNames.slice(-(matchNames.length));
          const processesMatch = JSON.stringify(matchNames) === JSON.stringify(relevantProcessNames);
          const filetypesMatch = d.filetype === input.genome_output.filetype;
          return processesMatch && filetypesMatch;
        })
        if (data) {
          newDataParams[key] = data;
          newLockedKeys.push(key);
        }
      } else if (input.genome_file === "fasta" && execution.genome?.fasta) {
        newDataParams[key] = execution.genome.fasta;
        newLockedKeys.push(key);
      } else if (input.genome_file === "gtf" && execution.genome?.gtf) {
        newDataParams[key] = execution.genome.gtf;
        newLockedKeys.push(key);
      } else if (input.genome_file && execution.genome?.data) {
        const regex = new RegExp(input.genome_file);
        const data = execution.genome.data.find(d => regex.test(d.filetype));
        if (data) {
          newDataParams[key] = data;
          newLockedKeys.push(key);
        }
      }
    }
    setGenomeId(execution.genome?.id);
    setDataParams(newDataParams);
    setLockedKeys(newLockedKeys);
    setOrganism(execution.genome?.organism);
  }

  return (
    <div className="info border-l-8 pl-4 max-w-3xl">
      <div className="font-medium mb-2 text-sm text-[#37474F]">
        {takesRawGenome && "You can populate this section using a pre-defined genome:"}
        {takesGenomePrep && `These parameters can be supplied from an existing Flow genome. Select genome files to use a pre-prepared genome, or pick specific files below. ${organism ? `Must be ${organism.name} to match the samples picked.` : ""}`}
      </div>
      {takesRawGenome && (
        <Selector
          value={genomeId || "-"}
          options={genomes ? [{id: "-", label: "---"}, ...genomes.map(g => ({id: g.id, label: `${g.organism_name} ${g.name}`}))] : []}
          onChange={genomeSelected}
          inputClassName={`${inputClass} cursor-pointer max-w-sm`}
          placeholder="Select a genome"
          optionClassName={selectOptionClass}
          optionsClassName={selectOptionsClass}
        />
      )}
      {takesGenomePrep && (
        <ExecutionSelector2
          inputClass={inputClass}
          pipelineVersions={genomePipelineVersions}
          returnData={true}
          returnGenome={true}
          organismId={organism?.id}
          placeholder="Select a genome prep..."
          placeholderClass={placeholderClass}
          execution={genomePrep}
          setExecution={genomePrepSelected}
        />
      )}
    </div>
  );
};

PipelineSectionFiller.propTypes = {
  section: PropTypes.object.isRequired,
  genomes: PropTypes.array,
  genomePipelineVersions: PropTypes.array,
  genomeId: PropTypes.string,
  setGenomeId: PropTypes.func.isRequired,
  dataParams: PropTypes.object.isRequired,
  setDataParams: PropTypes.func.isRequired,
  lockedKeys: PropTypes.array.isRequired,
  setLockedKeys: PropTypes.func.isRequired,
  genomePrep: PropTypes.object,
  setGenomePrep: PropTypes.func.isRequired,
  organism: PropTypes.object,
  setOrganism: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  placeholderClass: PropTypes.string.isRequired,
  selectOptionClass: PropTypes.string.isRequired,
  selectOptionsClass: PropTypes.string.isRequired,
};

export default PipelineSectionFiller;