import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AuthLinks = props => {

  const { mobile } = props;

  return (
    <div className={`flex w-40 whitespace-nowrap font-medium ${mobile ? "mx-auto py-2 text-sm gap-2" : "text-xs gap-1"} ${props.className || ""}`}>
      <Link className={`w-1/2 px-3 ${mobile ? "btn text-white border-0" : "btn-tertiary"}`} to="/login/">Log In</Link>
      <Link className={`w-1/2 px-3 ${mobile ? "btn text-white border-0" : "btn-primary"}`} to="/signup/">Sign Up</Link>
    </div>
  );
};

AuthLinks.propTypes = {
  mobile: PropTypes.bool
};

export default AuthLinks;