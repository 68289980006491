import { useState, useRef } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";

const GroupSelector2 = props => {

  const { group, setGroup, inputClass, placeholder, placeholderClass } = props;

  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState("");
  const [possibleGroups, setPossibleGroups] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const timeout = useRef();
  const listRef = useRef();
  const pageCount = 20;

  const [{ loading }, searchGroups ] = useLazyFetch("/groups/search", {
    onCompleted: data => {
      if (data.page > 1 && possibleGroups) {
        setPossibleGroups([...possibleGroups, ...data.groups]);
      } else {
        setPossibleGroups(data.groups);
      }
      setTotalCount(data.count);
    }
  });

  const search = params => {
    return searchGroups({params: {
      name: query,
      count: pageCount,
      ...params
    }})
  }

  const textEntered = e => {
    setQuery(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      search({name: e.target.value});
    }, 500)
  }

  const openModal = () => {
    setShowModal(true);
    setQuery("");
    search();
  }

  const groupSelected = g => {
    if (group && group.slug === g.slug) {
      setGroup(null);
    } else {
      setGroup(g);
    }
    setShowModal(false);
  }

  const clear = () => {
    setGroup(null);
    setShowModal(false);
  }

  const loadMore = async () => {
    const nextPage = Math.ceil(possibleGroups.length / pageCount) + 1;
    setLoadingMore(true);
    await search({page: nextPage});
    setLoadingMore(false);
    if (listRef.current) {
      setTimeout(() => listRef.current.scrollBy({
        top: 100, left: 0, behavior: "smooth"
      }), 100);
    }
  }

  const hasMore = possibleGroups && totalCount && possibleGroups.length < totalCount;

  return (
    <div className={props.className || ""}>
      <div className={`${inputClass} cursor-pointer flex justify-center items-start flex-col gap-1`} onClick={openModal}>
        {group ? group.name : ""}
        {!group && <span className={placeholderClass}>{placeholder}</span>}
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal} className="w-full h-full flex flex-col px-4 py-2 max-w-6xl border border-[#E8E8E8] bg-[#FEFEFE]">
          <div className="flex flex-wrap items-center pt-1 pb-3 border-[#D3D3D3] justify-center gap-x-10 gap-y-2 text-sm text-[#595959] font-medium">
            <div
              onClick={clear}
              className={`${group ? "cursor-pointer opacity-60 hover:opacity-100" : "opacity-30 pointer-events-none"}  `}
            >
              Clear
            </div>
          </div>
          <input
            value={query} 
            className="bg-[#F3F3F3] flex-shrink-0 rounded mb-1.5 w-full h-9 text-[#3B59C3] font-medium px-3"
            onChange={textEntered}
            autoFocus
          />
          <div className="flex-grow overflow-auto" ref={listRef}>
            {possibleGroups && possibleGroups.length === 0 && !loading && (
              <div className="text-[#B5B5B5] text-lg font-light font-inter text-center py-3">
                No Matching Groups Found
              </div>
            )}
            {possibleGroups && (!loading || loadingMore) && (
              <div className="">
                {possibleGroups.map(g => {
                  const isCurrent = group?.slug === g.slug;
                  return (
                    <div
                      key={g.slug}
                      onClick={() => groupSelected(g)}
                      className={`group py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7] ${isCurrent && "bg-[#f7f7f7]"}`}
                    >
                      <div className={`text-sm font-medium group-hover:text-[#3B59C3] sm:text-base ${isCurrent ? "text-[#3B59C3]" : "text-[#595959]"}`}>
                        {g.name}
                      </div>
                      <div className="text-2xs flex gap-5 font-normal leading-4 text-[#595F73] whitespace-nowrap sm:text-xs">
                        <span>{g.slug}</span>
                      </div>
                    </div> 
                  )
                })}
                <div
                  className={`px-1 py-2 flex cursor-pointer justify-center text-primary-500 font-medium text-xs hover:bg-gray-100 rounded-md ${hasMore && !loading ? "" : "hidden"}`}
                  onClick={loadMore}
                >
                  Load More
                </div>
              </div>
            )}
            {loading && (
              <div className={`flex justify-center items-center ${loadingMore ? "h-10" : "h-20"}`}>
                <ClipLoader size={loadingMore ? 20 : 50} speedMultiplier={1.5} />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

GroupSelector2.propTypes = {
  group: PropTypes.object,
  setGroup: PropTypes.func.isRequired,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderClass: PropTypes.string,
};

export default GroupSelector2;