import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ReactComponent as TerminalIcon } from "../images/terminal.svg";
import { ReactComponent as BashIcon } from "../images/bash.svg";
import { ReactComponent as RightIcon } from "../images/triangle-right.svg";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import ExecutionStatus from "./ExecutionStatus";
import { useState } from "react";
import Terminal from "./Terminal";
import { Link } from "react-router-dom";
import { processName } from "../utils";

const ProcessExecution = props => {

  const { processExecution, execution, expanded, setExpanded, isGroupRepresentative } = props;

  const [showTerminal, setShowTerminal] = useState(false);
  const [showBash, setShowBash] = useState(false);

  const name = processName(processExecution.process_name);

  let duration = moment.duration(
    ((processExecution.finished * 1000) || moment()) - (processExecution.started * 1000)
  ).humanize();
  duration = `${processExecution.finished === null ? "Running for" : "Ran for"} ${duration}`

  const startTime = moment(processExecution.started * 1000).format("h:mm:ss a");
  const endTime = processExecution.finished ? moment(processExecution.finished * 1000).format("h:mm:ss a") : null;
  const time = endTime ? `${startTime} - ${endTime}` : startTime;

  const stdout = processExecution.stdout && processExecution.stdout !== "-" ? processExecution.stdout : "";
  const stderr = processExecution.stderr && processExecution.stderr !== "-" ? processExecution.stderr : "";
  const hasTerminal = Boolean(stdout) || Boolean(stderr);
  const hasBash = Boolean(processExecution.bash);
  const noInputs = processExecution.upstream_samples.length === 0 && processExecution.upstream_data.length === 0;
  const noOutputs = processExecution.downstream_data.length === 0;

  const iconClass = "w-5 sm:w-6";
  const dataHeadingClass = "font-medium mb-1";
  const linksClass = "flex flex-col gap-1 break-all";
  const noDataClass = "text-2xs italic text-[#858585]";

  return (
    <>
      <div className={`bg-[#F9F9F9] border border-[#DFDFDF] rounded px-4 py-3 w-full ${props.className || ""}`}>
        <div className="flex justify-between items-start gap-x-2 gap-y-1 flex-wrap">
          <div>
            <div className="text-sm font-medium sm:text-base">{name}</div>
            <div className="text-xs group">
              <span className="group-hover:hidden">{duration}</span>
              <span className="hidden group-hover:inline">{time}</span>
            </div>
          </div>
          <div className="flex items-center gap-1.5 sm:gap-2">
            <TerminalIcon
              className={`${iconClass} ${hasTerminal ? "cursor-pointer" : "opacity-50 pointer-events-none"}`} fill="#888888"
              onClick={() => setShowTerminal(true)}
              />
            <BashIcon
              className={`${iconClass} ${hasBash ? "cursor-pointer" : "opacity-50 pointer-events-none"}`} fill="#888888"
              onClick={() => setShowBash(true)}
              />
            <ExecutionStatus execution={processExecution} />
            <RightIcon
              fill="#888888" className={` cursor-pointer ${!expanded || "rotate-90 pl-px"}`}
              onClick={() => setExpanded(processExecution.identifier)}
              />
          </div>
          {expanded && (
            <div className="border-t pt-3 mt-2 text-xs w-full">
              <div className="text-xs mb-3 break-words flex gap-1 items-center">
                <span className="bg-slate-400 text-white font-normal font-mono text-2xs px-1.5 rounded">
                  {processExecution.identifier}
                </span>
                {" "}{processExecution.name}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className={dataHeadingClass}>Input data</div>
                  <div className={linksClass}>
                    {noInputs && <div className={noDataClass}>No data inputs</div>}
                    {processExecution.upstream_samples.map(sample => (
                      <Link to={`/samples/${sample.id}/`} className="w-fit" key={sample.id}>
                        <SampleIcon className="w-3 h-auto inline" alt="" fill="#3B59C3" /> {sample.name}
                      </Link>
                    ))}
                    {processExecution.upstream_data.map(data => (
                      <Link to={`/data/${data.id}/`}  className="w-fit" key={data.id}>{data.filename}</Link>
                    ))}
                  </div>
                </div>
                <div>
                  <div className={dataHeadingClass}>Output data</div>
                  <div className={linksClass}>
                    {processExecution.downstream_data.map(data => (
                      <Link to={`/data/${data.id}/`} className="w-fit" key={data.id}>{data.filename}</Link>
                    ))}
                    {noOutputs && !execution.finished && <div className={noDataClass}>No data outputs published yet</div>}
                    {noOutputs && execution.finished && <div className={noDataClass}>No data outputs published</div>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showTerminal && (
        <Terminal
          stdout={processExecution.stdout} stderr={processExecution.stderr}
          showTerminal={showTerminal} setShowTerminal={setShowTerminal}
          filename={`processexecution-${processExecution.id}-${name.toLowerCase()}`}
        />
      )}
      {showBash && (
        <Terminal
          stdout={processExecution.bash} stderr=""
          showTerminal={showBash} setShowTerminal={setShowBash}
          filename={`processexecution-${processExecution.id}-${name.toLowerCase()}`}
        />
      )}
    </>
  )
}

/* const ProcessExecution = props => {

  const { processExecution, execution } = props;

  const [showTerminal, setShowTerminal] = useState(false);
  const [showBash, setShowBash] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const name = processName(processExecution.processName)
  const duration = moment.duration(
    ((processExecution.finished * 1000) || moment()) - (processExecution.started * 1000)
  ).humanize();

  const hasTerminal = Boolean(processExecution.stdout) || Boolean(processExecution.stderr);
  const hasBash = Boolean(processExecution.bash);

  const iconClass = "w-5 sm:w-6";
  const dataHeadingClass = "font-medium mb-1";
  const linksClass = "flex flex-col gap-1 break-words";
  const noDataClass = "text-2xs italic text-[#858585]";

  const noInputs = processExecution.upstreamSamples.length === 0 && processExecution.upstreamData.length === 0;
  const noOutputs = processExecution.downstreamData.length === 0;

  return (
    <div className="bg-[#F9F9F9] border border-[#DFDFDF] rounded px-4 py-3">
      <div className="flex justify-between items-start gap-x-2 gap-y-1 flex-wrap">
        <div>
          <div className="text-sm font-medium sm:text-base">{name}</div>
          <div className="text-xs">
            {processExecution.exitCode === null ? "Running for" : "Ran for"} {duration}
          </div>
        </div>

        <div className="flex items-center gap-1.5 sm:gap-2">
          <TerminalIcon
            className={`${iconClass} ${hasTerminal ? "cursor-pointer" : "opacity-50"}`} fill="#888888"
            onClick={() => setShowTerminal(true)}
          />
          <BashIcon
            className={`${iconClass} ${hasBash ? "cursor-pointer" : "opacity-50"}`} fill="#888888"
            onClick={() => setShowBash(true)}
          />
          <ExecutionStatus execution={processExecution} />
          <RightIcon
            fill="#888888" className={` cursor-pointer ${!expanded || "rotate-90 pl-px"}`}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>

      {expanded && (
        <div className="border-t pt-2 mt-2 text-xs">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className={dataHeadingClass}>Input data</div>
              <div className={linksClass}>
                {noInputs && <div className={noDataClass}>No data inputs</div>}
                {processExecution.upstreamSamples.map(sample => (
                  <Link to={`/samples/${sample.id}/`} key={sample.id}>
                    <SampleIcon className="w-3 h-auto inline" alt="" fill="#3B59C3" /> {sample.name}
                  </Link>
                ))}
                {processExecution.upstreamData.map(data => (
                  <Link to={`/data/${data.id}/`} key={data.id}>{data.filename}</Link>
                ))}
              </div>
            </div>
            <div>
              <div className={dataHeadingClass}>Output data</div>
              <div className={linksClass}>
                {processExecution.downstreamData.map(data => (
                  <Link to={`/data/${data.id}/`} key={data.id}>{data.filename}</Link>
                ))}
                {noOutputs && !execution.finished && <div className={noDataClass}>No data outputs published yet</div>}
                {noOutputs && execution.finished && <div className={noDataClass}>No data outputs published</div>}
              </div>
            </div>
          </div>
        </div>
      )}

      {showTerminal && (
        <Terminal
          stdout={processExecution.stdout} stderr={processExecution.stderr}
          showTerminal={showTerminal} setShowTerminal={setShowTerminal}
          filename={`processexecution-${processExecution.id}-${name.toLowerCase()}`}
        />
      )}
      {showBash && (
        <Terminal
          stdout={processExecution.bash} stderr=""
          showTerminal={showBash} setShowTerminal={setShowBash}
          filename={`processexecution-${processExecution.id}-${name.toLowerCase()}`}
        />
      )}
    </div>
  );
}; */

ProcessExecution.propTypes = {
  processExecution: PropTypes.object.isRequired,
  execution: PropTypes.object.isRequired,
};

export default ProcessExecution;