import { useState } from "react";
import PropTypes from "prop-types";
import ProcessExecution from "./ProcessExecution";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import { Link } from "react-router-dom";

const ProcessExecutions = props => {

  const { processExecutions, execution, showLabels, expandedIdentifiers, setExpandedIdentifiers, sample } = props;

  const [expandedGroups, setExpandedGroups] = useState([]);

  const setExpanded = identifier => {
    if (expandedIdentifiers.includes(identifier)) {
      setExpandedIdentifiers(expandedIdentifiers.filter(id => id !== identifier));
    } else {
      setExpandedIdentifiers([...expandedIdentifiers, identifier]);
    }
  }

  const groups = [];
  for (const procEx of processExecutions) {
    const existingGroup = groups.find(group => group.name === procEx.process_name);
    if (existingGroup) {
      existingGroup.processExecutions.push(procEx);
      continue;
    }
    const group = {
      name: procEx.process_name,
      processExecutions: [procEx]
    };
    groups.push(group);
  }

  return (
    <div>
      {sample && (
        <Link className="font-medium text-sm flex items-center mb-2 w-fit" to={`/samples/${sample.id}/`}>
          <SampleIcon className="inline w-3.5 h-auto mr-1.5" />
          {sample.name}
        </Link>
      )}
      <div className="flex flex-col gap-4">
        {groups.map(group => {
          const hasMultiple = group.processExecutions.length > 1;
          const procEx = group.processExecutions[0];
          if (expandedGroups.includes(group.name)) {
            return (
              <div className="flex flex-col gap-1.5" key={group.name}>
                <div
                  className="text-xs -mb-0.5 text-[#3B59C3] cursor-pointer w-fit hover:underline"
                  onClick={() => setExpandedGroups(expandedGroups.filter(name => name !== group.name))}
                >
                  Re-combine group
                </div>
                {group.processExecutions.map(procEx => (
                  <ProcessExecution
                    key={procEx.id}
                    className="relative z-10"
                    processExecution={procEx}
                    execution={execution}
                    showLabels={showLabels}
                    expanded={expandedIdentifiers.includes(procEx.identifier)}
                    setExpanded={setExpanded}
                    isGroupRepresentative={hasMultiple}
                  />
                ))}
              </div>
            );
          }
          return (
            <div className={`relative ${hasMultiple ? "mb-2" : ""}`} key={group.name}>
              {hasMultiple && (
                <div
                  className="text-xs text-[#3B59C3] mb-0.5 cursor-pointer w-fit hover:underline"
                  onClick={() => setExpandedGroups([...expandedGroups, group.name])}
                >
                  See all {group.processExecutions.length} instances
                </div>
              )}
              {hasMultiple && (
                <ProcessExecution
                  className="absolute -bottom-1 left-1 z-10"
                  key={procEx.id}
                  processExecution={procEx}
                  execution={execution}
                  showLabels={showLabels}
                  expanded={expandedIdentifiers.includes(procEx.identifier)}
                  setExpanded={setExpanded}
                  isGroupRepresentative={hasMultiple}
                />
              )}
              {hasMultiple && group.processExecutions.length > 2 && (
                <ProcessExecution
                  className="absolute -bottom-2 left-2 z-0 opacity-50"
                  key={procEx.id}
                  processExecution={procEx}
                  execution={execution}
                  showLabels={showLabels}
                  expanded={expandedIdentifiers.includes(procEx.identifier)}
                  setExpanded={setExpanded}
                  isGroupRepresentative={hasMultiple}
                />
              )}
              <ProcessExecution
                key={procEx.id}
                className="relative z-10"
                processExecution={procEx}
                execution={execution}
                showLabels={showLabels}
                expanded={expandedIdentifiers.includes(procEx.identifier)}
                setExpanded={setExpanded}
                isGroupRepresentative={hasMultiple}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

/* const ProcessExecutions = props => {

  const { execution } = props;

  const sampleIds = [];
  for (const procEx of execution.processExecutions) {
    if (procEx.sample && !sampleIds.includes(procEx.sample.id)) {
      sampleIds.push(procEx.sample.id);
    }
  }
  
  const baseProcesses = sampleIds.length > 1 ? (
    execution.processExecutions.filter(procEx => !procEx.sample)
  ) : execution.processExecutions;

  return (
    <div className="w-full sm:max-w-lg">
      <div className="flex gap-2 items-center font-medium text-lg mb-3">
        <ProcessesIcon />Process Executions
      </div>
      <div className="w-full flex flex-col gap-3 sm:max-w-lg">
        {baseProcesses.map(procEx => (
          <ProcessExecution processExecution={procEx} execution={execution} key={procEx.id} />
        ))}
        {sampleIds.length > 1 && (
          <div className="flex flex-col gap-6 mt-4">
            {sampleIds.map(sampleId => {
              const sampleProcessExecutions = execution.processExecutions.filter(procEx => procEx.sample?.id === sampleId);
              const name = sampleProcessExecutions[0].sample.name;
              return (
                <div key={sampleId}>
                  <div className="font-medium text-sm flex items-center mb-2">
                    <SampleIcon className="inline w-4 h-4 mr-1.5" />
                    {name}
                  </div>
                  <div className="flex flex-col gap-3">
                    {sampleProcessExecutions.map(procEx => (
                      <ProcessExecution processExecution={procEx} execution={execution} key={procEx.id} />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {execution.processExecutions.length === 0 && !execution.taskFinished && (
          <div className="italic">No processes have executed yet.</div>
          )}
        {execution.processExecutions.length === 0 && execution.taskFinished && (
          <div className="italic">No processes executed.</div>
        )}
      </div>
    </div>
  );
}; */

ProcessExecutions.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ProcessExecutions;