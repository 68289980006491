import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { UserContext } from "../contexts";
import { useLazyFetch } from "../hooks";

const LeaveGroupModal = props => {

  const { settings, setSettings, group, setShowModal } = props;

  const [errors, setErrors] = useState(null);
  const [user, setUser] = useContext(UserContext);

  const [{ loading }, leaveGroup] = useLazyFetch(`/groups/${group.slug}/leave`, {
    method: "POST",
    onCompleted: () => {
      user.memberships = user.memberships.filter(membership => membership.slug !== group.slug);
      settings.groups = settings.groups.filter(g => g.slug !== group.slug);
      setUser({...user});
      setSettings({...settings});
      setShowModal(false);
    },
    onError: error => setErrors(error.error)
  })

  const validation = errors || {};

  return (
    <Modal
      setShowModal={setShowModal}
      title={<div>Are you sure you want to leave <span className="text-[#3B59C3]">{group.name}</span>?</div>}
      text="If you want to rejoin in future you will need to request access."
      className="w-96 max-w-full"
    >
      {validation && (
        <div className="text-red-700 text-sm mx-auto w-fit">{Object.values(validation)}</div>
      )}
      <div className="flex gap-3 flex-col w-full mx-auto mt-4 sm:w-64 sm:flex-row">
        <Button
          className="btn-primary py-1 w-full sm:w-1/2 sm:py-1.2"
          onClick={() => leaveGroup({params: {id: group.id}})}
          loading={loading}
        >
          Yes, leave
        </Button>
        <button className="btn-secondary py-1 w-full sm:w-1/2 sm:py-1.5" onClick={() => setShowModal(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

LeaveGroupModal.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default LeaveGroupModal;