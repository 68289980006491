import { useContext, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { UserContext } from "../contexts";
import Button from "./Button";
import { useLazyFetch } from "../hooks";

const GroupOwnerModal = props => {

  const { object, setObject, setShowModal } = props;

  const [selectedGroup, setSelectedGroup] = useState(null);

  const [user,] = useContext(UserContext);

  const activeGroup = selectedGroup || user.memberships[0];

  const objectType = object.filename ? "data" : object.pipeline_name ? "execution" : "project" in object ? "sample" : "project"; 
  const pluralType = objectType === "data" ? "data" : `${objectType}s`;


  const [{ loading }, transfer] = useLazyFetch(`/${pluralType}/${object.id}/transfer`, {
    method: "POST",
    params: { group: activeGroup.slug},
    onCompleted: data => {
      setObject(data);
      setShowModal(false);
    }
  });

  return (
    <Modal
      setShowModal={setShowModal}
      className="max-w-md"
      title={`Transfer ${objectType} to Group`}
    >
      {user.memberships.length === 0 && (
        <>
          <div className="text-center">
            You are not a member of any groups. You can only transfer ownership to a group if you are a member of that group.
          </div>
          <Button onClick={() => setShowModal(false)} className="mt-4 mx-auto btn-secondary">Close</Button>
        </>
      )}
      {user.memberships.length > 0 && (
        <div>
          <div className="text-sm mb-3">
            If you transfer this {objectType} to a group, all members of that group will have full permissions on it.
          </div>
          <select
            value={activeGroup.slug}
            onChange={e => setSelectedGroup(user.memberships.find(m => m.slug === e.target.value))}
            className="outline-none border border-[#e8e8e8] rounded-md px-2 py-1 w-full cursor-pointer"
          >
            {user.memberships.map((group, i) => (
              <option key={i} value={group.slug}>{group.name}</option>
            ))}
          </select>
          <div className="flex gap-2 mt-6">
            <Button className="btn-primary" loading={loading} onClick={transfer}>Transfer</Button>
            <Button className="btn-secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

GroupOwnerModal.propTypes = {
  object: PropTypes.object.isRequired,
  setObject: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default GroupOwnerModal;