import PropTypes from "prop-types";
import moment from "moment";

const AccountInfo = props => {

  const { settings, formClass, headingClass } = props;

  return (
    <div className={formClass}>
      <div className={headingClass}>Account Information</div>
      <div className="text-[#37474F] text-sm sm:text-base">
        Your account was created on <time className="text-[#3B59C3]">{moment(settings.created * 1000).format("Do MMMM YYYY")}</time>
      </div>
    </div>
  );
};

AccountInfo.propTypes = {
  settings: PropTypes.object.isRequired,
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
};

export default AccountInfo;