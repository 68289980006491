import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const Document = props => {

  const { markdown } = props;

  const CustomH2 = ({ children }) => <p className="font-medium mb-1">{children}</p>
  const CustomParagraph = ({ children }) => <p className="font-inter font-light mb-3">{children}</p>
  const CustomList = ({ children }) => <ul className="font-inter font-light mb-3 ml-6 list-disc">{children}</ul>

  return (
    <ReactMarkdown
      children={markdown}
      className="text-sm max-w-7xl -mb-3 sm:text-base"
      components={{
        h2: (props) => <CustomH2 {...props} />,
        p: (props) => <CustomParagraph {...props} />,
        ul: (props) => <CustomList {...props} />,
      }}
    />
  );
};

Document.propTypes = {
  markdown: PropTypes.string.isRequired
};

export default Document;