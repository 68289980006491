import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { useLazyFetch } from "../hooks";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts";

const DeleteGroup = props => {

  const { group } = props;

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);

  const [{loading}, deleteGroup] = useLazyFetch(`/groups/${group.slug}/delete`, {
    method: "POST",
    onCompleted: () => {
      setShowModal(false);
      user.memberships = user.memberships.filter(g => g.slug !== group.slug);
      setUser({...user});
      navigate("/");
    }
  });

  const canDelete = name.toLowerCase() === group.name.toLowerCase();

  const text = `This will permanently delete the group. All members will be notified.`

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";
  
  return (
    <div className={props.className || ""}>
      <button
        onClick={() => setShowModal(true)}
        className={`w-fit ${secondaryClass}`}
      >
        Delete Group
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={"Remove Group?"} text={text} className="max-w-lg">

          <div className="w-fit mx-auto mb-10">
            <div className="text-sm">Enter the name of the group to continue:</div>
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              className="border-b w-64 rounded-none  text-sm"
            />
          </div>

          <div className="flex gap-3 mx-auto w-fit">
            <Button
              className={`${primaryClass} ${canDelete ? "" : "pointer-events-none opacity-50"}`}
              loading={loading}
              onClick={deleteGroup}
            >
              Delete
            </Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteGroup.propTypes = {
  group: PropTypes.object.isRequired,
};

export default DeleteGroup;