import { useEffect, useState, useContext} from "react";
import { makeParamString } from "./utils";
import { TokenContext } from "./contexts";

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
}

export const useFetch = (path, options = {}) => {
  const [state, setState] = useState({loading: true, error: null, data: null, status: null});
  const method = options.method || "GET";
  const fullPath = method === "GET" ? makeParamString(path, options) : path;
  const tokenRef = useContext(TokenContext);
  useEffect(() => {
    if (!options.skip) {
      setState({...state, loading: true});
      fetch(`${process.env.REACT_APP_BACKEND}${fullPath}`, {
        method: method, headers: tokenRef?.current ? {Authorization: tokenRef.current} : undefined,
        body: method === "POST" ? JSON.stringify(options.params || {}) : undefined,
        credentials: "include"
      }).then(resp => {
        if (resp.status === 200) {
          resp.json().then(json => {
            if (options.onCompleted) options.onCompleted(json);
            setState({loading: false, error: null, data: json, status: resp.status});
          })
        } else {
          resp.json().then(json => {
            if (options.onError) options.onError(json);
            setState({loading: false, error: json, data: null, status: resp.status});
          })
        }
      })
    } else {
      setState({loading: false, error: null, data: null, status: null});
    }
  }, [fullPath, options.skip]);
  return state;
}

export const useLazyFetch = (path, options = {}) => {
  const [state, setState] = useState({loading: false, error: null, data: null, status: null});
  const tokenRef = useContext(TokenContext);
  const func = async (funcOptions = {}) => {
    const combinedOptions = {...options, ...funcOptions};
    const method = combinedOptions.method || "GET";
    const fullPath = method === "GET" ? makeParamString(path, combinedOptions) : path;
    setState({...state, loading: true});
    let body = undefined;
    if (method === "POST") {
      if (combinedOptions.form) {
        body = new FormData();
        if (combinedOptions.params) {
          for (const [key, value] of Object.entries(combinedOptions.params)) {
            if (value !== null) body.append(key, value);
          }
        }
      } else {
        body = JSON.stringify(combinedOptions.params || {});
      }
    }
    const resp = await fetch(`${process.env.REACT_APP_BACKEND}${fullPath}`, {
      method: method, headers: tokenRef ? {Authorization: tokenRef.current} : undefined, body,
      credentials: "include"
    })
    let json;
    try {
      json = await resp.json();
    } catch {
      const errorJson = {message: "Could not parse JSON"};
      if (combinedOptions.onError) combinedOptions.onError(errorJson);
      setState({loading: false, error: errorJson, data: null, status: resp.status});
      return { loading: false, error: errorJson, data: null, status: resp.status };
    }
    if (resp.status === 200) {
      if (combinedOptions.onCompleted) combinedOptions.onCompleted(json);
      setState({loading: false, error: null, data: json, status: resp.status});
      return { loading: false, error: null, data: json, status: resp.status };
    } else {
      if (combinedOptions.onError) combinedOptions.onError(json);
      setState({loading: false, error: json, data: null, status: resp.status});
      return { loading: false, error: json, data: null, status: resp.status };
    }
  }
  return [state, func];
}