const AnnotationSheetLinks = props => {

  const linkClass = "text-[#3B59C3] w-fit";

  const url = process.env.REACT_APP_DATA.replace("/data", "");

  const categories = [
    ["RNA-Seq", "RNA-Seq"], 
    ["scRNA-Seq", "scRNA-Seq"], 
    ["ChIP-Seq", "ChIP-Seq"], 
    ["CLIP", "CLIP"], 
    ["generic", "Generic"]
  ]

  return (
    <div className={props.className || ""}>
      <div className="text-sm font-medium mb-1">Annotation sheet templates:</div>
      <div className="grid text-xs gap-0.5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
        {categories.map(([name, displayName]) => (
          <a href={`${url}/annotation/${name}`} className={linkClass} key={name}>
            {displayName} Annotation Sheet
          </a>
        ))}
      </div>
    </div>
  );
};

AnnotationSheetLinks.propTypes = {
  
};

export default AnnotationSheetLinks;