import React from "react";
import PropTypes from "prop-types";

const Paper = props => {

  const { paper, lineClass } = props;

  const title = s => s.length < 100 ? s : s.slice(0, 95) + "...";

  const journal = j => {
    if (j.length < 30) return j
    const replacement = {
      Journal: "J.",
      European: "Eur.",
      Immunology: "Immunol.",
    }
    const shortened = [...Object.entries(replacement)].reduce(
      (p, c) => p.replace(c[0], c[1]), j
    )
    if (shortened.length < 50) return shortened
    return shortened.slice(0, 50) + "..."
  }

  const url = `https://www.ncbi.nlm.nih.gov/pubmed/${paper.id}`;

  return (
    <a
      href={url} key={paper.id} target="_blank" rel="noopener noreferrer"
      className={`rounded bg-[#F9F9F9] border border-[#EEEEEE] py-4 px-3 hover:bg-[#F3F3F3] ${props.className || ""}`}
    >
      <div className={`font-medium flex justify-between border-b pb-2 ${lineClass}`}>
        <div className="text-base text-[#3B59C3]">{paper.year}</div>
        <div className="italic">{journal(paper.journal)}</div>
      </div>
      <div className="text-xs mt-2">{title(paper.title)}</div>
    </a>
  );
};

Paper.propTypes = {
  paper: PropTypes.object.isRequired,
  lineClass: PropTypes.string
};

export default Paper;