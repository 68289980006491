import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useFetch, useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";

const UserOwnerModal = props => {

  const { object, setObject, setShowModal } = props;

  const [selectedUser, setSelectedUser] = useState(null);
  
  const { loading: groupLoading, data } = useFetch(`/groups/${object.group_owner.slug}`);
  
  const activeUser = selectedUser || (data ? data.members[0] : null);

  const objectType = object.filename ? "data" : object.pipeline_name ? "execution" : "project" in object ? "sample" : "project"; 
  const pluralType = objectType === "data" ? "data" : `${objectType}s`;

  const [{ loading: transferLoading }, transfer] = useLazyFetch(`/${pluralType}/${object.id}/transfer`, {
    method: "POST",
    params: { user: activeUser?.username },
    onCompleted: data => {
      setObject(data);
      setShowModal(false);
    }
  });

  return (
    <Modal
      setShowModal={setShowModal}
      title={`Transfer ${objectType} to User`}
      className="max-w-md"
    >
      {groupLoading && <ClipLoader />}

      {!groupLoading && (
        <div>
          <div className="text-sm mb-3">
            If you transfer this {objectType} to a specific user, only that user will have full permissions on it. You may immediately lose access to it if it has not been shared with you.
          </div>
          <select
            value={activeUser?.username}
            className="outline-none border border-[#e8e8e8] rounded-md px-2 py-1 w-full cursor-pointer"
            onChange={e => setSelectedUser(data.members.find(m => m.username === e.target.value))}
          >
            {data.members.map((member, i) => (
              <option key={i} value={member.username}>{member.name}</option>
            ))}
          </select>
          <div className="flex gap-2 mt-6">
            <Button className="btn-primary" loading={transferLoading} onClick={transfer}>Transfer</Button>
            <Button className="btn-secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

UserOwnerModal.propTypes = {
  object: PropTypes.object.isRequired,
  setObject: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default UserOwnerModal;