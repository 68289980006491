import React from "react";
import PropTypes from "prop-types";
import { formatTimestamp } from "../utils";
import { Link } from "react-router-dom";
import PrivacyIcon from "./PrivacyIcon";
import Checkbox from "./Checkbox";

const SampleRow2 = props => {

  const { sample, index, checked, onCheck, showSource, showPurificationTarget, showScientist } = props;

  const Cell = props => {
    const link = props.link !== false;
    const Element = link ? Link : "div";
    return (
      <td className={`whitespace-nowrap text-xs ${props.className || ""}`} onClick={props.onClick}>
        <Element to={`/samples/${sample.id}/`} className={`block py-3 ${props.noPx ? "px-0" : "px-4"}`}>
          {props.children}
        </Element>
      </td>
    )
  }

  return (
    <tr className={`bg-opacity-80 hover:bg-opacity-100 ${index % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`}>
      <Cell onClick={onCheck} link={false}>
        <Checkbox checked={checked} onChange={() => {}} />
      </Cell>
      {"private" in sample && (
        <Cell className="px-0" noPx={true}>
          <PrivacyIcon object={sample} className={sample.private ? "w-3 opacity-60" : "w-3.5"} />
        </Cell>
      )}
      <Cell>{sample.name}</Cell>
      <Cell>{sample.category}</Cell>
      <Cell>{formatTimestamp(sample.created)}</Cell>
      {showScientist && <Cell>{sample.scientist}</Cell>}
      {sample.owner_name && <Cell>{sample.owner_name}</Cell>}
      <Cell>{sample.organism_name}</Cell>
      {showSource && (
        <Cell>
          {sample.source}
          {sample.source && sample.source_text ? `:${sample.source_text}` : ""}
        </Cell>
      )}
      {showPurificationTarget && (
        <Cell>
          {sample.purification_target}
          {sample.purification_target && sample.purification_target_text ? `:${sample.purification_target_text}` : ""}
        </Cell>
      )}
      <Cell className="w-full">{sample.project_name}</Cell>
    </tr>
  );
};

SampleRow2.propTypes = {
  sample: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  showSource: PropTypes.bool,
  showPurificationTarget: PropTypes.bool,
  showScientist: PropTypes.bool,
};

export default SampleRow2;