import React from "react";
import Div100vh from "react-div-100vh";
import AuthForm from "../components/AuthForm";
import { useDocumentTitle } from "../hooks";

const PasswordResetPage = () => {

  useDocumentTitle("Reset Password - Flow");

  return (
    <Div100vh className="flex">
      <AuthForm isReset={true} isLogin={false} />
    </Div100vh>
  );
};

PasswordResetPage.propTypes = {
  
};

export default PasswordResetPage;