import React from "react";
import PropTypes from "prop-types";
import UserImage from "./UserImage";

const SelectableUser = props => {

  const { user, onClick } = props;

  return (
    <div
      onClick={() => onClick(user)}
      className="group flex items-center gap-2 py-0.5 px-2 cursor-pointer rounded-md hover:bg-[#f7f7f7]"
    >
      <UserImage user={user} className="w-8 h-8" />
      <div>
        <div className="text-base font-medium text-[#595959] group-hover:text-[#3B59C3]">{user.name}</div>
        <div className="text-sm">{user.username}</div>
      </div>
    </div>
  );
};

SelectableUser.propTypes = {
  user: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectableUser;