import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import GenomeForm from "./GenomeForm";
import DeleteGenome from "./DeleteGenome";

const GenomeAdmin = props => {

  const { genome, organismId } = props;

  const [editing, setEditing] = useState(false);

  if (editing) return <GenomeForm genome={genome} setShow={setEditing} organismId={organismId} />;

  const linkClass = "block text-[#3B59C3] text-xs break-all";

  return (
    <div className="ml-6 mt-4 relative w-fit pr-6">
      <div className="flex">
        {genome.name} ({moment(genome.created * 1000).format("D MMM YYYY")})
        <div className="relative bottom-1 left-0.5 text-xs flex items-baseline gap-1">
          <div
            className="link font-normal inline-block text-[#3B59C3] hover:underline"
            onClick={() => setEditing(true)}
          >
            Edit
          </div>
          {genome.executionCount === 0 && <DeleteGenome genome={genome} />}
        </div>
      </div>
      <a href={genome.url} className={`${linkClass} mb-2`}>{genome.url}</a>
      <Link className={linkClass} to={`/data/${genome.fasta.id}/`}>{genome.fasta.filename}</Link>
      <Link className={linkClass} to={`/data/${genome.gtf.id}/`}>{genome.gtf.filename}</Link>
      {genome.data.map(d => (
        <Link key={d.id} className={linkClass} to={`/data/${d.id}/`}>{d.filename}</Link>
      ))}
    </div>
  );
};

GenomeAdmin.propTypes = {
  genome: PropTypes.object.isRequired,
  organismId: PropTypes.string.isRequired
};

export default GenomeAdmin;