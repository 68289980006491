import React from "react";
import PropTypes from "prop-types";

const ProgressBar = props => {

  const { progress, error } = props;

  const showRed = error && progress !== 1;

  return (
    <div className={`w-full h-1 bg-gray-200 rounded-full overflow-hidden ${props.className || ""}`}>
      <div
        className={`h-full text-right flex items-center justify-end transition-all duration-200 ${showRed ? "bg-red-500" : "bg-[#3B59C3]"}`}
        style={{
          width: `${progress * 100}%`,
          animation: progress === 1 ? undefined : "progress-bar-stripes 0.8s linear infinite",
          backgroundImage: progress === 1 ? undefined : "linear-gradient(45deg, hsla(0,0%,100%,.03) 25%, transparent 0, transparent 50%, hsla(0,0%,100%,.03) 0, hsla(0,0%,100%,.03) 75%, transparent 0,transparent)",
          backgroundSize: "1rem 1rem"
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  error: PropTypes.string
};

export default ProgressBar;