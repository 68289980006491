import { useState } from "react";
import PropTypes from "prop-types";
import PipelineSubcategoryAdmin from "./PipelineSubcategoryAdmin";
import PipelineCategoryForm from "./PipelineCategoryForm";
import DeletePipelineCategory from "./DeletePipelineCategory";
import PipelineSubcategoryForm from "./PipelineSubcategoryForm";
import { useMutation } from "@apollo/client";
import { MOVE_CATEGORY_DOWN, MOVE_CATEGORY_UP } from "../mutations";
import { PIPELINE_ADMIN } from "../queries";
import { ReactComponent as DownIcon } from "../images/down.svg";

const PipelineCategoryAdmin = props => {

  const { category, isFirst, isLast } = props;

  const [editing, setEditing] = useState(false);
  const [showNewSubcategory, setShowNewSubcategory] = useState(false);

  const [moveUp, moveUpMutation] = useMutation(MOVE_CATEGORY_UP, {
    variables: { id: category.id },
    refetchQueries: [{ query: PIPELINE_ADMIN }],
  });

  const [moveDown, moveDownMutation] = useMutation(MOVE_CATEGORY_DOWN, {
    variables: { id: category.id },
    refetchQueries: [{ query: PIPELINE_ADMIN }],
  });

  const loading = moveUpMutation.loading || moveDownMutation.loading;

  if (editing) return <PipelineCategoryForm category={category} setShow={setEditing} />;

  return (
    <div className={`border-b pb-8 ${props.className || ""}`}>
      <div className="text-lg">
        {category.name}
        <button className="text-xs relative bottom-2 mx-1 text-[#3B59C3]" onClick={() => setEditing(true)}>
          Edit
        </button>
        {category.pipelineCount === 0 && <DeletePipelineCategory category={category} />}
        {!isLast && (
          <DownIcon className="cursor-pointer relative bottom-2 h-3 pt-px w-auto fill-[#3B59C3] inline" onClick={moveDown} />
        )}
        {!isFirst && (
          <DownIcon className="cursor-pointer relative bottom-2 h-3 pb-px w-auto rotate-180 fill-[#3B59C3] inline" onClick={moveUp} />
        )}
      </div>
      <div className="text-sm mb-2 max-w-xl">{category.description}</div>
      <div className="flex flex-col gap-2 ml-8">
        {category.subcategories.map((subcategory, index) => (
          <PipelineSubcategoryAdmin
            key={subcategory.id}
            subcategory={subcategory}
            categoryId={category.id}
            isFirst={index === 0}
            isLast={index === category.subcategories.length - 1}
          />
        ))}
      </div>
      <div className="">
        {showNewSubcategory && <PipelineSubcategoryForm setShow={setShowNewSubcategory} categoryId={category.id} />}
        {!showNewSubcategory && (
          <button className="btn-primary text-xs ml-8 py-1 px-2 mt-2 w-fit" onClick={() => setShowNewSubcategory(true)}>
            Add New Subcategory
          </button>
        )}
      </div>
    </div>
  );
};

PipelineCategoryAdmin.propTypes = {
  category: PropTypes.object.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default PipelineCategoryAdmin;