import gql from "graphql-tag";
import { USER_FIELDS } from "./queries";

export const SIGNUP = gql`mutation login(
  $username: String! $password: String! $name: String! $email: String!
) { signup(
  username: $username password: $password name: $name email: $email
) { accessToken user { ...UserFields } }
} ${USER_FIELDS}`;

export const LOGIN = gql`mutation login($username: String! $password: String!) {
  login(username: $username password: $password) {
    accessToken user { ...UserFields }
  }
} ${USER_FIELDS}`;

export const LOGOUT = gql`mutation { logout { success } }`;

export const REQUEST_PASSWORD_RESET = gql`mutation requestReset($email: String!) {
  requestPasswordReset(email: $email) { success }
}`;

export const RESET_PASSWORD = gql`mutation resetPassword(
  $token: String! $password: String!
) { resetPassword(token: $token password: $password) { success } }`;

export const UPDATE_USER = gql`mutation updateUser(
  $username: String! $email: String! $name: String!
) { updateUser(username: $username email: $email name: $name) {
  user { ...UserFields }
} } ${USER_FIELDS}`;

export const UPDATE_PASSWORD = gql`mutation updateUser(
  $new: String! $current: String!
) { updatePassword(new: $new current: $current) { success } }`;

export const UPDATE_USER_IMAGE = gql`mutation updateImage($image: Upload!) {
  updateUserImage(image: $image) { user { ...UserFields } }
} ${USER_FIELDS}`;

export const READ_NOTIFICATIONS = gql`mutation readNotifications($ids: [ID]) {
  readNotifications(ids: $ids) { notifications { id hasRead } }
}`;

export const CREATE_GROUP = gql`mutation createGroup(
  $name: String! $slug: String! $description: String!
) { createGroup(name: $name slug: $slug description: $description) {
  group { id name slug description }
  user { ...UserFields } 
} } ${USER_FIELDS}`;

export const UPDATE_GROUP = gql`mutation updateGroup(
  $id: ID! $name: String! $slug: String! $description: String!
) { updateGroup(id: $id name: $name slug: $slug description: $description) {
  group { id name slug description }
  user { ...UserFields } 
} } ${USER_FIELDS}`;

export const LEAVE_GROUP = gql`mutation leaveGroup($id: ID!) {
  leaveGroup(id: $id) { user { ...UserFields } }
} ${USER_FIELDS}`;

export const CREATE_PROJECT = gql`mutation createProject(
  $name: String! $description: String!
) { createProject(name: $name description: $description) {
  project { id name description } 
} }`;


export const UPLOAD_DATA = gql`mutation uploadData(
  $blob: Upload! $isLast: Boolean! $expectedFileSize: Float! $data: ID
  $filename: String!
) { uploadData(
  blob: $blob isLast: $isLast expectedFileSize: $expectedFileSize data: $data
  filename: $filename
) { dataId } }`;

export const MULTIPLEXED_DATA = gql`mutation uploadMultiplexedData(
  $blob: Upload! $isLast: Boolean! $expectedFileSize: Float! $data: ID
  $filename: String! $reads1: ID
) { uploadMultiplexedData(
  blob: $blob isLast: $isLast expectedFileSize: $expectedFileSize data: $data
  filename: $filename reads1: $reads1
) { dataId } }`;

export const UPLOAD_DEMULTIPLEXED_DATA = gql`mutation uploadDemultiplexedData(
  $blob: Upload! $isLastData: Boolean! $isLastSample: Boolean! $previousData: [ID]
  $expectedFileSize: Float! $data: ID $filename: String! $sample: ID $sampleName: String
  $category: ID $organism: String $source: String $purificationTarget: String
  $scientist: String $pi: String $organisation: String $purificationAgent: String
  $experimentalMethod: String $condition: String $sequencer: String $comments: String
  $fivePrimeBarcodeSequence: String $threePrimeBarcodeSequence: String $threePrimeAdapterName: String
  $threePrimeAdapterSequence: String $read1Primer: String
  $read2Primer: String $rtPrimer: String $umiBarcodeSequence: String
  $umiSeparator: String $strandedness: String $rnaSelectionMethod: String
  $project: String $sourceText: String $purificationTargetText: String
  $geo: String $ena: String $pubmed: String
) { uploadDemultiplexedData(
  blob: $blob isLastData: $isLastData isLastSample: $isLastSample
  expectedFileSize: $expectedFileSize data: $data previousData: $previousData
  filename: $filename sampleName: $sampleName category: $category sample: $sample
  organism: $organism source: $source purificationTarget: $purificationTarget
  scientist: $scientist pi: $pi organisation: $organisation project: $project
  purificationAgent: $purificationAgent experimentalMethod: $experimentalMethod
  condition: $condition sequencer: $sequencer comments: $comments
  fivePrimeBarcodeSequence: $fivePrimeBarcodeSequence threePrimeBarcodeSequence: $threePrimeBarcodeSequence
  threePrimeAdapterName: $threePrimeAdapterName threePrimeAdapterSequence: $threePrimeAdapterSequence
  read1Primer: $read1Primer read2Primer: $read2Primer
  rtPrimer: $rtPrimer umiBarcodeSequence: $umiBarcodeSequence umiSeparator: $umiSeparator
  strandedness: $strandedness rnaSelectionMethod: $rnaSelectionMethod
  sourceText: $sourceText purificationTargetText: $purificationTargetText
  geo: $geo ena: $ena pubmed: $pubmed
) { dataId sampleId } }`;


export const UPLOAD_ANNOTATION_DATA = gql`mutation uploadAnnotationData(
  $blob: Upload! $isLast: Boolean! $expectedFileSize: Float! $data: ID
  $filename: String! $ignoreWarnings: Boolean
) { uploadAnnotationData(
  blob: $blob isLast: $isLast expectedFileSize: $expectedFileSize data: $data
  filename: $filename ignoreWarnings: $ignoreWarnings
) { dataId } }`;


export const RUN_PIPELINE = gql`mutation runPipeline(
  $id: ID! $params: JSONString! $dataParams: JSONString!
  $sampleParams: JSONString! $nextflowVersion: String! $genome: ID
) { runPipeline(
  id: $id params: $params dataParams: $dataParams sampleParams: $sampleParams
  nextflowVersion: $nextflowVersion genome: $genome
) { execution { id } } }`;


export const CANCEL_EXECUTION = gql`mutation cancelExecution($id: ID!) {
  cancelExecution(id: $id) { execution { id } }
}`;


export const SUBMIT_BULK_DOWNLOAD = gql`mutation submitBulkDownload(
  $dataIds: [ID] $executionIds: [ID] $sampleIds: [ID]
) { submitBulkDownload(
  dataIds: $dataIds executionIds: $executionIds sampleIds: $sampleIds
) { bulkDownloadJob { id } } }`;


export const UPDATE_PROJECT = gql`mutation updateProject(
  $id: ID! $private: Boolean $name: String $description: String $papers: [PaperInput]
) { updateProject(id: $id private: $private name: $name description: $description papers: $papers) {
  project { id private name description }
} }`;

export const UPDATE_PROJECT_PERMISSION = gql`mutation updateProjectPermission(
  $id: ID! $user: ID $group: ID $permission: Int!
) { updateProjectPermission(
  id: $id user: $user group: $group permission: $permission
) { project { id } } }`;

export const UPDATE_SAMPLE = gql`mutation updateSample(
  $id: ID! $private: Boolean $name: String $project: String
  $category: ID $organism: String $source: String $purificationTarget: String
  $scientist: String $pi: String $organisation: String $purificationAgent: String
  $experimentalMethod: String $condition: String $sequencer: String $comments: String
  $fivePrimeBarcodeSequence: String $threePrimeBarcodeSequence: String $threePrimeAdapterName: String
  $threePrimeAdapterSequence: String $read1Primer: String
  $read2Primer: String $rtPrimer: String $umiBarcodeSequence: String
  $umiSeparator: String $strandedness: String $rnaSelectionMethod: String
  $sourceText: String $purificationTargetText: String
  $geo: String $ena: String $pubmed: String
) { updateSample(
  id: $id private: $private name: $name category: $category project: $project
  organism: $organism source: $source purificationTarget: $purificationTarget
  scientist: $scientist pi: $pi organisation: $organisation 
  purificationAgent: $purificationAgent experimentalMethod: $experimentalMethod
  condition: $condition sequencer: $sequencer comments: $comments
  fivePrimeBarcodeSequence: $fivePrimeBarcodeSequence threePrimeBarcodeSequence: $threePrimeBarcodeSequence
  threePrimeAdapterName: $threePrimeAdapterName threePrimeAdapterSequence: $threePrimeAdapterSequence
  read1Primer: $read1Primer read2Primer: $read2Primer
  rtPrimer: $rtPrimer umiBarcodeSequence: $umiBarcodeSequence umiSeparator: $umiSeparator
  strandedness: $strandedness rnaSelectionMethod: $rnaSelectionMethod
  sourceText: $sourceText purificationTargetText: $purificationTargetText
  geo: $geo ena: $ena pubmed: $pubmed
) { sample { id private } } }`;

export const UPDATE_SAMPLE_PERMISSION = gql`mutation updateSamplePermission(
  $id: ID! $user: ID $group: ID $permission: Int!
) { updateSamplePermission(
  id: $id user: $user group: $group permission: $permission
) { sample { id } } }`;

export const UPDATE_EXECUTION = gql`mutation updateExecution($id: ID! $private: Boolean $dependent: Boolean) {
  updateExecution(id: $id private: $private dependent: $dependent) { execution { id private dependent } }
}`;

export const UPDATE_EXECUTION_PERMISSION = gql`mutation updateExecutionPermission(
  $id: ID! $user: ID $group: ID $permission: Int!
) { updateExecutionPermission(
  id: $id user: $user group: $group permission: $permission
) { execution { id } } }`;

export const UPDATE_DATA = gql`mutation updateData($id: ID! $private: Boolean) {
  updateData(id: $id private: $private) { data { id private } }
}`;

export const UPDATE_DATA_PERMISSION = gql`mutation updateDataPermission(
  $id: ID! $user: ID $group: ID $permission: Int!
) { updateDataPermission(
  id: $id user: $user group: $group permission: $permission
) { data { id } } }`;

export const DELETE_DATA = gql`mutation deleteData($id: ID!) {
  deleteData(id: $id) { success }
}`;

export const DELETE_EXECUTION = gql`mutation deleteExecution($id: ID!) {
  deleteExecution(id: $id) { success }
}`;

export const DELETE_SAMPLE = gql`mutation deleteSample($id: ID!) {
  deleteSample(id: $id) { success }
}`;

export const DELETE_PROJECT = gql`mutation deleteProject($id: ID!) {
  deleteProject(id: $id) { success }
}`;

export const BULK_DELETE_DATA = gql`mutation bulkDeleteData($ids: [ID]!) {
  bulkDeleteData(ids: $ids) { success }
}`;

export const BULK_DELETE_EXECUTIONS = gql`mutation bulkDeleteExecutions($ids: [ID]!) {
  bulkDeleteExecutions(ids: $ids) { success }
}`;

export const BULK_DELETE_SAMPLES = gql`mutation bulkDeleteSamples($ids: [ID]!) {
  bulkDeleteSamples(ids: $ids) { success }
}`;

export const PULL_REPO = gql`mutation pullPipelineRepo($id: ID!) {
  pullPipelineRepo(id: $id) { output }
}`;

export const CHECKOUT_REPO = gql`mutation checkoutPipelineRepo($id: ID! $branch: String!) {
  checkoutPipelineRepo(id: $id branch: $branch) { output }
}`;

export const CREATE_REPO = gql`mutation createPipelineRepo(
  $path: String! $url: String! $originalUrl: String
) { createPipelineRepo(path: $path url: $url originalUrl: $originalUrl) {
  pipelineRepo { id path url originalUrl }
} }`;

export const UPDATE_REPO = gql`mutation updatePipelineRepo(
  $id: ID! $originalUrl: String!
) { updatePipelineRepo(id: $id originalUrl: $originalUrl) {
  pipelineRepo { id path url originalUrl }
} }`;

export const DELETE_REPO = gql`mutation deletePipelineRepo($id: ID!) {
  deletePipelineRepo(id: $id) { success }
}`;

export const CREATE_PIPELINE = gql`mutation createPipeline(
  $name: String! $subcategory: ID! $repo: ID! $isNfcore: Boolean!
  $isDemultiplex: Boolean! $preparesGenome: Boolean! $importsSamples: Boolean!
) { createPipeline(
  name: $name subcategory: $subcategory repo: $repo isNfcore: $isNfcore
  isDemultiplex: $isDemultiplex preparesGenome: $preparesGenome importsSamples: $importsSamples
) {
  pipeline { id name isNfcore isDemultiplex preparesGenome }
} }`;

export const UPDATE_PIPELINE = gql`mutation updatePipeline(
  $id: ID! $name: String! $subcategory: ID! $isNfcore: Boolean!
  $isDemultiplex: Boolean! $preparesGenome: Boolean! $importsSamples: Boolean!
) { updatePipeline(
  id: $id name: $name subcategory: $subcategory  isNfcore: $isNfcore
  isDemultiplex: $isDemultiplex preparesGenome: $preparesGenome importsSamples: $importsSamples
) {
  pipeline { id name isNfcore isDemultiplex preparesGenome }
} }`;

export const MOVE_PIPELINE_UP = gql`mutation movePipelineUp($id: ID!) {
  movePipelineUp(id: $id) { success }
}`;

export const MOVE_PIPELINE_DOWN = gql`mutation movePipelineDown($id: ID!) {
  movePipelineDown(id: $id) { success }
}`;

export const DELETE_PIPELINE = gql`mutation deletePipeline($id: ID!) {
  deletePipeline(id: $id) { success }
}`;

export const CREATE_PIPELINE_VERSION = gql`mutation createPipelineVersion(
  $name: String! $git: String! $private: Boolean! $description: String!
  $longDescription: String! $active: Boolean! $path: String!
  $schemaPath: String! $configPaths: String $genomePipelineVersions: [ID]
  $pipeline: ID!
) { createPipelineVersion(
  name: $name git: $git private: $private description: $description
  longDescription: $longDescription active: $active path: $path
  schemaPath: $schemaPath configPaths: $configPaths
  genomePipelineVersions: $genomePipelineVersions pipeline: $pipeline
) { pipelineVersion { id name } } }`;

export const UPDATE_PIPELINE_VERSION = gql`mutation updatePipelineVersion(
  $id: ID! $name: String! $git: String! $private: Boolean! $description: String!
  $longDescription: String! $active: Boolean! $path: String!
  $schemaPath: String! $configPaths: String $genomePipelineVersions: [ID]
) { updatePipelineVersion(
  id: $id name: $name git: $git private: $private description: $description
  longDescription: $longDescription active: $active path: $path
  schemaPath: $schemaPath configPaths: $configPaths
  genomePipelineVersions: $genomePipelineVersions
) { pipelineVersion { id name } } }`;

export const DELETE_PIPELINE_VERSION = gql`mutation deletePipelineVersion($id: ID!) {
  deletePipelineVersion(id: $id) { success }
}`;

export const CREATE_PIPELINE_CONFIG = gql`mutation createPipelineConfig(
  $filename: String! $contents: String!
) { createPipelineConfig(filename: $filename contents: $contents) {
  pipelineConfig { filename }
} }`;

export const DELETE_PIPELINE_CONFIG = gql`mutation deletePipelineConfig($filename: String!) {
  deletePipelineConfig(filename: $filename) { success }
}`;

export const CREATE_PIPELINE_CATEGORY = gql`mutation createPipelineCategory(
  $name: String! $description: String!
) { createPipelineCategory(name: $name description: $description) {
  pipelineCategory { id name }
} }`;

export const UPDATE_PIPELINE_CATEGORY = gql`mutation updatePipelineCategory(
  $id: ID! $name: String! $description: String!
) { updatePipelineCategory(id: $id name: $name description: $description) {
  pipelineCategory { id name }
} }`;

export const MOVE_CATEGORY_UP = gql`mutation movePipelineCategoryUp($id: ID!) {
  movePipelineCategoryUp(id: $id) { success }
}`;

export const MOVE_CATEGORY_DOWN = gql`mutation movePipelineCategoryDown($id: ID!) {
  movePipelineCategoryDown(id: $id) { success }
}`;

export const DELETE_PIPELINE_CATEGORY = gql`mutation deletePipelineCategory($id: ID!) {
  deletePipelineCategory(id: $id) { success }
}`;

export const CREATE_PIPELINE_SUBCATEGORY = gql`mutation createPipelineSubcategory(
  $name: String! $description: String! $category: ID!
) { createPipelineSubcategory(name: $name description: $description category: $category) {
  pipelineSubcategory { id name }
} }`;

export const UPDATE_PIPELINE_SUBCATEGORY = gql`mutation updatePipelineSubcategory(
  $id: ID! $name: String! $description: String! $category: ID!
) { updatePipelineSubcategory(id: $id name: $name description: $description category: $category) {
  pipelineSubcategory { id name }
} }`;

export const MOVE_SUBCATEGORY_UP = gql`mutation movePipelineSubcategoryUp($id: ID!) {
  movePipelineSubcategoryUp(id: $id) { success }
}`;

export const MOVE_SUBCATEGORY_DOWN = gql`mutation movePipelineSubcategoryDown($id: ID!) {
  movePipelineSubcategoryDown(id: $id) { success }
}`;

export const DELETE_PIPELINE_SUBCATEGORY = gql`mutation deletePipelineSubcategory($id: ID!) {
  deletePipelineSubcategory(id: $id) { success }
}`;

export const CREATE_ORGANISM = gql`mutation createOrganism(
  $id: String! $name: String! $latinName: String!
) { createOrganism(id: $id name: $name latinName: $latinName) {
  organism { id name latinName }
} }`;

export const UPDATE_ORGANISM = gql`mutation updateOrganism(
  $id: String! $name: String! $latinName: String!
) { updateOrganism(id: $id name: $name latinName: $latinName) {
  organism { id name latinName }
} }`;

export const DELETE_ORGANISM = gql`mutation deleteOrganism($id: String!) {
  deleteOrganism(id: $id) { success }
}`;

export const CREATE_GENOME = gql`mutation createGenome(
  $name: String! $longName: String $url: String! $created: String $organism: String!
  $fasta: ID! $gtf: ID! $data: [ID]
) { createGenome(
  name: $name longName: $longName  url: $url created: $created organism: $organism
  fasta: $fasta gtf: $gtf data: $data
) { genome { id } } }`;

export const UPDATE_GENOME = gql`mutation updateGenome(
  $id: ID! $name: String! $longName: String $url: String! $created: String $organism: String!
  $fasta: ID! $gtf: ID! $data: [ID]
) { updateGenome(
  id: $id name: $name longName: $longName  url: $url created: $created
  organism: $organism fasta: $fasta gtf: $gtf data: $data
) { genome { id } } }`;

export const DELETE_GENOME = gql`mutation deleteGenome($id: ID!) {
  deleteGenome(id: $id) { success }
}`;

export const UPDATE_NEWS = gql`mutation updateNews($news: [NewsInput]!) {
  updateNews(news: $news) { news { id } }
}`;

export const MAKE_ADMIN = gql`mutation promote($user: ID! $password: String!) {
  promote(user: $user password: $password) { success }
}`;

export const REMOVE_ADMIN = gql`mutation demote($user: ID! $password: String!) {
  demote(user: $user password: $password) { success }
}`;

export const MAKE_RUNNER = gql`mutation makeRunner($user: ID! $password: String!) {
  makeRunner(user: $user password: $password) { success }
}`;

export const REMOVE_RUNNER = gql`mutation removeRunner($user: ID! $password: String!) {
  removeRunner(user: $user password: $password) { success }
}`;