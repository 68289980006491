import React, { useState } from  "react";
import PropTypes from  "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useLazyFetch } from "../hooks";

const ResetPasswordModal = props => {

  const { setShowModal } = props;

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const [{ loading }, requestReset] = useLazyFetch("/password-reset/request", {
    method: "POST",
    params: { email },
    onCompleted: () => setSuccess(true)
  }) 

  const onSubmit = e => {
    e.preventDefault();
    requestReset();
  }

  return (
    <Modal
      setShowModal={setShowModal}
      title="Reset Password"
      text="Enter the email associated with your account and we'll send an email with instructions to reset your password."
      className="max-w-lg"
    >
      <form onSubmit={onSubmit}>
        <input
          type="text"
          className="bg-gray-100 w-full py-1.5 px-2 mb-4"
          value={email}
          disabled={success}
          onChange={e => setEmail(e.target.value)}
          autoComplete="email"
        />
        <Button
          className={`btn-primary w-full ${!success || "pointer-events-none opacity-50"}`}
          loading={loading}
          disabled={success}
        >
          {success ? "Request sent" : "Request password reset"}
        </Button>
      </form>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  setShowModal: PropTypes.func.isRequired
};

export default ResetPasswordModal;