import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fileSize } from "../utils";

const GenomeFiles = props => {

  const { genome } = props;

  const linkClass = "block text-[#3B59C3] font-medium break-all";
  const sizeClass = "text-[#37474F]";

  return (
    <div className={`bg-[#F9F9F9] rounded-md text-sm w-fit p-3 flex flex-col gap-x-3 gap-y-1.5 sm:p-4 sm:text-base ${props.className || ""}`}>
      <Link to={`/data/${genome.fasta.id}/`} className={linkClass}>
        {genome.fasta.filename} <span className={sizeClass}>{fileSize(genome.fasta.size)}</span>
      </Link>
      <Link to={`/data/${genome.gtf.id}/`} className={linkClass}>
        {genome.gtf.filename} <span className={sizeClass}>{fileSize(genome.gtf.size)}</span>
      </Link>
      {genome.data.map(d => (
        <Link to={`/data/${d.id}/`} className={linkClass} key={d.id}>
          {d.filename} <span className={sizeClass}>{fileSize(d.size)}</span>
        </Link>
      ))}
    </div>
  );
};

GenomeFiles.propTypes = {
  genome: PropTypes.object.isRequired
};

export default GenomeFiles;