import PropTypes from "prop-types";
import Paginator from "./Paginator2";
import { ClipLoader } from "react-spinners";
import TableFilter from "./TableFilter";
import ProjectCard2 from "./ProjectCard2";

const ProjectGrid2 = props => {

  const { projects, page, setPage, pageSize, totalCount, onFilter, loading, noMessage } = props;

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="relative" style={{minHeight: 250}}>
      <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
        <div className="flex flex-wrap gap-2 items-center">
          <TableFilter filter={onFilter} />
          {totalPages > 1 && (
            <Paginator
              currentPage={page} totalPages={totalPages} onChange={setPage}
            />
          )}
        </div>
      </div>
      {projects.length === 0 && !loading && (
        <div className="mt-8 italic text-sm">
          <span className="info border-l-red-200">{noMessage}</span>
        </div>
      )}
      {loading && (
        <div className="absolute z-40 h-48 w-full flex justify-center items-center">
          <ClipLoader size={100} speedMultiplier={2} />
        </div> 
      )}
      <div className="flex w-full flex-wrap gap-x-6 gap-y-5">
        {projects.map(p => (
          <ProjectCard2 project={p} key={p.id} />
        ))}
      </div>
    </div>
  );
};

ProjectGrid2.propTypes = {
  projects: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
};

export default ProjectGrid2;