import React from "react";
import PropTypes from "prop-types";

const DataType = props => {

  const { data } = props;

  const className = "text-white text-xs px-3 flex h-6 whitespace-nowrap items-center rounded lg:px-4 lg:text-sm lg:h-7"

  if (data.category === 1) return <div />
  if (data.category === 2) return <div className={`${className} bg-[#37474F]`}>Annotation Sheet</div>
  if (data.category === 3) return <div className={`${className} bg-[#125F85]`}>Multiplexed FASTQ</div>
  if (data.category === 4) return <div className={`${className} bg-[#099AE3]`}>Demultiplexed FASTQ</div>

};

DataType.propTypes = {
  data: PropTypes.object.isRequired
};

export default DataType;