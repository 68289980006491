import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";
import Genome from "../components/Genome";

const GenomesPage = () => {

  useDocumentTitle("Genomes - Flow");

  const { data: organisms, loading } = useFetch("/organisms");

  if (loading) return <Base loading={true} />

  return (
    <Base>
      <h1>Genomes</h1>
      <div className="gap-4 flex flex-wrap md:gap-8">
        {organisms.filter(o => o.latest_genome).map(organism => (
          <Genome
            genome={organism.latest_genome} organism={organism} key={organism.id}
            className="w-full max-w-lg"
          />
        ))}
      </div>
    </Base>
  );
};

GenomesPage.propTypes = {
  
};

export default GenomesPage;