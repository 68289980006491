import { useState } from "react";
import PropTypes from "prop-types";
import PipelineCategoryAdmin from "./PipelineCategoryAdmin";
import PipelineCategoryForm from "./PipelineCategoryForm";

const PipelineCategoriesAdmin = props => {

  const { categories } = props;

  const [showNewCategory, setShowNewCategory] = useState(false);

  return (
    <div className="flex flex-col gap-6">
      {categories.map((category, index) => (
        <PipelineCategoryAdmin
          key={category.id}
          category={category}
          isFirst={index===0}
          isLast={index===categories.length - 1}
        />
      ))}
      <div className="">
        {showNewCategory && <PipelineCategoryForm setShow={setShowNewCategory} />}
        {!showNewCategory && (
          <button className="btn-primary text-base py-1 px-3 mt-2 w-fit" onClick={() => setShowNewCategory(true)}>
            Add New Category
          </button>
        )}
      </div>
    </div>
  );
};

PipelineCategoriesAdmin.propTypes = {
  categories: PropTypes.array.isRequired
};

export default PipelineCategoriesAdmin;