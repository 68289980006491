import React from "react";
import { Link } from "react-router-dom";
import notFound from "../images/not-found.svg";

const NotFound = () => {
  return (
    <div className="m-auto w-fit text-center font-medium px-8">
      <img src={notFound} alt="" className="mb-3 w-full max-w-sm" />
      <div className="text-2xl mb-1 text-[#455A64]">Page Not Found</div>
      <Link to="/" className="text-[#3B59C3] text-sm link">Back to Homepage</Link>
    </div>
  );
};

NotFound.propTypes = {
  
};

export default NotFound;