import React, { useState } from "react";
import { NEWS_ADMIN } from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import Button from "./Button";
import { UPDATE_NEWS } from "../mutations";
import moment from "moment";

const NewsAdminTab = () => {

  const [news, setNews] = useState(null)

  useQuery(NEWS_ADMIN, {
    onCompleted: data => setNews([
      ...data.news.edges.map(edge => ({
        id: edge.node.id,
        headline: edge.node.headline,
        date: edge.node.date,
        category: edge.node.category
      })),
      {headline: "", date: moment().format("YYYY-MM-DD"), category: "1"}
    ].sort((a, b) => b.date.localeCompare(a.date))),
    refetchQueries: [{query: NEWS_ADMIN}],
    awaitRefetchQueries: true,
  });

  const [updateNews, updateNewsMutation] = useMutation(UPDATE_NEWS, {
    variables: {news: news?.filter(n => n.headline && n.date && n.category).map(n => ({
      headline: n.headline, date: n.date, category: n.category
    }))},
  })

  const updateNewsState = (index, field, value) => {
    const newNews = [...news];
    newNews[index][field] = value;
    if (field === "headline" && !value && newNews[index].id) newNews.splice(index, 1);
    newNews.sort((a, b) => b.date.localeCompare(a.date));
    setNews(newNews);
  }

  const inputClass = "bg-[#F3F3F3] rounded mb-1.5 w-full h-8 text-[#3B59C3] font-medium px-2";

  return (
    <div className="flex flex-wrap gap-8 items-start">
      <div className="flex flex-col gap-6 w-full max-w-sm  flex-shrink-0">
        {news && news.map((newsItem, index) => (
          <div className="w-full text-sm" key={index}>
            <input
              type="text"
              placeholder="Headline"
              value={newsItem.headline}
              onChange={e => updateNewsState(index, "headline", e.target.value)}
              className={`${inputClass}`}
            />
            <div className="grid gap-x-1.5 grid-cols-2">
              <input
                type="date"
                placeholder="Date"
                value={newsItem.date}
                onChange={e => updateNewsState(index, "date", e.target.value)}
                className={`${inputClass}`}
              />
              <select
                className={`${inputClass} cursor-pointer outline-none`}
                value={newsItem.category}
                onChange={e => updateNewsState(index, "category", e.target.value)}
              >
                <option value="1">Pipelines</option>
                <option value="2">Features</option>
                <option value="3">News</option>
              </select>
            </div>
            
          </div>
        ))}
      </div>
      <Button
        className="btn-primary px-8"
        onClick={updateNews}
        loading={updateNewsMutation.loading}
      >
        Save
      </Button>
    </div>
  );
};

NewsAdminTab.propTypes = {
  
};

export default NewsAdminTab;