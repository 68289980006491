import React from "react";
import { useDocumentTitle } from "../hooks";
import Div100vh from "react-div-100vh";
import AuthForm from "../components/AuthForm";

const LoginPage = () => {

  useDocumentTitle("Sign In - Flow");

  return (
    <Div100vh className="flex">
      <AuthForm isLogin={true} />
    </Div100vh>
  );
};

LoginPage.propTypes = {
  
};

export default LoginPage;