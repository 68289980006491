import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

const Select = props => {

  const { options, value, setValue, style } = props;

  const customStyle = style || {};

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      ...(customStyle.container || {})
    }),
      control: (provided, state) => ({
        ...provided,
        height: 0,
        background: "#f7f7f7",
        border: "none",
        borderWidth: 0,
        cursor: "pointer",
        minHeight: 30,
        outline: "none",
        boxShadow: "none",
        borderBottomLeftRadius: state.menuIsOpen ? 0 : 4,
        borderBottomRightRadius: state.menuIsOpen ? 0 : 4,
        ...(customStyle.control || {})
      }),
        valueContainer: (provided, state) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
          ...(customStyle.valueContainer || {})
        }),
          singleValue: (provided, state) => ({
            ...provided,
            color: "#565656",
            fontWeight: "500",
            ...(customStyle.singleValue || {})
          }),
          input: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0,
            color: "#565656",
            fontWeight: "500",
            ...(customStyle.input || {})
          }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          ...(customStyle.indicatorsContainer || {})
        }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
            ...(customStyle.indicatorSeparator || {})
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            opacity: 1,
            paddingTop: 0,
            paddingBottom: 0,
            color: "#585858",
            "&:hover": {
              color: "#585858",
            },
            ...(customStyle.dropdownIndicator || {})
          }),
      menu: (provided, state) => ({
        ...provided,
        margin: 0,
        overflow: "hidden",
        boxShadow: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        ...(customStyle.menu || {})
      }),
        menuList: (provided, state) => ({
          ...provided,
          paddingTop: 4,
          paddingBottom: 4,
          display: "flex",
          flexDirection: "column",
          gap: 4,
          background: "#f7f7f7",
          boxShadow: "none",
          border: "none",
          ...(customStyle.menuList || {})
        }),
          option: (provided, state) => ({
            ...provided,
            paddingTop: 3,
            paddingBottom: 3,
            width: "calc(100% - 8px)",
            cursor: "pointer",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 4,
            color: state.isSelected ? "white" : "#565656",
            background: state.isSelected || state.active ? "#3B59C3" : state.isFocused ? "#EBEBEB" : undefined,
            "&:hover": {
              background: state.isSelected ? undefined : "#EBEBEB"
            },
            "&:active": {
              background: state.isSelected || state.active ? "#3B59C3" : state.isFocused ? "#EBEBEB" : undefined,
            },
            ...(customStyle.option || {})
          }),
  }

  return (
    <ReactSelect
      options={options}
      value={options ? options.filter(o => o.value === value)[0] : ""}
      onChange={({value}) => setValue(value)}
      styles={customStyles}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default Select;