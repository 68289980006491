const noSpaces = s => s.replace(/ /g, "_");

export const upload = async (files, chunkSize, setProgress, setDataIds, canceledRef, sendChunk, onNetworkError, onError, extraParams) => {
  /**
   * Takes a list of files and uploads them to the server in chunks.
   */

  const filesList = files.reduce((acc, file) => [...acc, file.file1, file.file2].filter(f => f), []);
  let progress = files.reduce((acc, file) => {
    const data = {[file.file1.name]: 0};
    if (file.file2) data[file.file2.name] = 0;
    return {...acc, ...data};
  }, {});
  setProgress(progress);
  let dataIds = {};
  for (const file of filesList) {
    const chunkCount = Math.ceil(file.size / chunkSize);
    const chunkNums = [...Array(chunkCount).keys()];
    const isDirectory = files.find(f => f.file1.name === file.name || f.file2?.name).isDirectory;
    let resp;
    const maxRetries = 5;
    for (let c = 0; c < chunkNums.length; c++) {
      const chunkNum = chunkNums[c];
      const start = chunkSize * chunkNum;
      const isLast = chunkNum === chunkNums.length - 1;
      const expectedFileSize = chunkNum * chunkSize;
      const chunk = file.slice(start, start + chunkSize);
      const filename = noSpaces(isDirectory ? file.name.replace(/.zip$/g, "") : file.name);
      for (let attempt = 0; attempt < maxRetries; attempt++) {
        const returnedResp = await sendChunkWithRetries(
          extraParams, isDirectory, sendChunk, filename, chunk, expectedFileSize,
          isLast, resp, dataIds, onError, onNetworkError, attempt, maxRetries
        );
        if (returnedResp === false) return;
        if (returnedResp === null) continue;
        if (returnedResp) {
          resp = returnedResp;
          break;
        }
      }
      if (canceledRef.current === true) {
        canceledRef.current = false;
        return
      }
      const percentUploaded = Math.min(1, (expectedFileSize + chunkSize) / file.size);
      progress = {...progress, [file.name]: percentUploaded};
      setProgress(progress);
      if (isLast) {
        dataIds = {...dataIds, [file.name]: resp.data.id || resp.data.data_id};
        setDataIds(dataIds);
      }
    }
  }
}

const sendChunkWithRetries = async (extraParams, isDirectory, sendChunk, filename, chunk, expectedFileSize, isLast, resp, dataIds, onError, onNetworkError, attempt, maxRetries) => {
  /**
   * Sends a chunk to the server, retrying if necessary.
   */

  try {
    const extra = {...(extraParams || {}), ...(isDirectory ? {is_directory: true} : {})};
    resp = await sendChunk(filename, chunk, expectedFileSize, isLast, resp, extra, dataIds);
    if (resp.error) {
      onError(resp.error);
      return false;
    }
    return resp; //break;
  } catch (error) {
    if (attempt < maxRetries - 1) {
      await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)));
      return null; // continue;
    } else {
      onNetworkError();
      return false;
    }
  }
}