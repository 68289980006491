import React from "react";
import Div100vh from "react-div-100vh";
import NotFound from "../components/NotFound";
import { useDocumentTitle } from "../hooks";


const PageNotFound = () => {

  useDocumentTitle("Page Not Found - Flow");

  return (
    <Div100vh className="flex">
      <NotFound />
    </Div100vh>
  );
};

PageNotFound.propTypes = {
  
};

export default PageNotFound;