import { useContext, useState } from "react";
import Base from "./Base";
import { useParams } from "react-router-dom";
import { useFetch, useDocumentTitle } from "../hooks";
import PrivacyToggle from "../components/PrivacyToggle";
import DependencyToggle from "../components/DependencyToggle";
import FinishedEditingLink from "../components/FinishedEditingLink";
import { UserContext } from "../contexts";
import DeleteExection from "../components/DeleteExecution";
import Permissions from "../components/Permissions";

const EditExecutionPage = () => {

  const { id } = useParams();

  const [execution, setExecution] = useState(null);

  const [user,] = useContext(UserContext);

  const { loading, status } = useFetch(`/executions/${id}/edit`, {
    onCompleted: execution => setExecution(execution)
  });

  useDocumentTitle(execution ? `Edit ${execution.pipeline_name} execution - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const isOwned = execution.owner ? user.username === execution.owner.username : user.memberships.map(m => m.slug).includes(execution.group_owner.slug);

  return (
    <Base>
      <div className="flex flex-wrap items-start break-all justify-between gap-x-4 gap-y-1 mb-4 md:mb-10">
        <h1 className="mb-0">{execution.pipeline_name}</h1>
        <FinishedEditingLink />
      </div>
      <PrivacyToggle object={execution} setObject={setExecution} />
      <DependencyToggle execution={execution} setExecution={setExecution} className="mt-12 pt-10 border-t border-[#e8e8e8]"/>
      {execution.can_share && (
        <Permissions object={execution} setObject={setExecution} className="mt-12 pt-10 border-t border-[#e8e8e8]" />
      )}
      {isOwned && <DeleteExection execution={execution} />}
    </Base>
  );
};

EditExecutionPage.propTypes = {
  
};

export default EditExecutionPage;