import React from "react";
import Base from "./Base";
import { Link, useParams } from "react-router-dom";
import { GROUP } from "../queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useDocumentTitle, useFetch } from "../hooks";
import UserGrid from "../components/UserGrid";
import ObjectTitle from "../components/ObjectTitle";
import ObjectTitle2 from "../components/ObjectTitle2";
import ObjectAttributes from "../components/ObjectAttributes";

const GroupPage = () => {

  const { slug } = useParams();

  const { loading, status, data: group } = useFetch(`/groups/${slug}`);

  useDocumentTitle(group ? `${group.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />
  
  if (status === 404) return <Base notFound={true} />

  const members = group.members.filter(u => !group.admins.map(a => a.username).includes(u.username));

  return (
    <Base>
      <ObjectTitle2
        title={group.name}
        identifier={`@${group.slug}`}
        type="group"
        editUrl={group.is_admin && `/groups/@${slug}/edit`}
      />
      <ObjectAttributes object={group} className="mb-4" />
      {group.description && (
        <div className="mb-4 max-w-xl text-xs sm:text-sm">
          {group.description}
        </div>
      )}
      
      <UserGrid users={group.admins} label="Group Admins" />
      {members.length > 0 && <UserGrid users={members} label="Current Members" />}
    </Base>
  );
};

GroupPage.propTypes = {
  
};

export default GroupPage;