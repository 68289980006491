import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as Logo } from "../images/logo.svg";
import { Link } from "react-router-dom";
import AuthLinks from "./AuthLinks";
import MobileMenuToggle from "./MobileMenuToggle";
import MobileMenu from "./MobileMenu";
import { UserContext } from "../contexts";
import UserMenu from "./UserMenu";
import QuickSearcher from "./QuickSearcher";
import Notifications from "./Notifications";

const Nav = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [user,] = useContext(UserContext);
  const menuRef = useRef();
  const toggleRef = useRef();

  useEffect(() => {
    const onResize = () => setIsOpen(false);
    const onClick = e => {
      if (menuRef.current && menuRef.current.contains(e.target)) return;
      if (toggleRef.current && toggleRef.current.contains(e.target)) return;
      setIsOpen(false);
    }
    window.addEventListener("resize", onResize);
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("click", onClick);
    }
  }, [])

  return (
    <nav className={`h-16 w-full flex gap-4 justify-between items-center pr-4 xl:pr-6 ${!isOpen|| "bg-white"}`}>
      <div className="pl-4 h-16 flex justify-start items-center sm:pl-2 sm:w-32">
        <Link to="/"><Logo className="w-20 sm:w-32 sm:px-4" /></Link>
      </div>
      <div className="flex gap-4 flex-grow justify-end items-center">
        <QuickSearcher className="hidden sm:flex" />
        {user && <Notifications />}
        {!user && <AuthLinks className="hidden sm:flex" />}
        {user && <UserMenu />}
        <MobileMenuToggle isOpen={isOpen} setIsOpen={setIsOpen} toggleRef={toggleRef} />
        {isOpen && <MobileMenu menuRef={menuRef} />}
      </div>
    </nav>
  );
};

Nav.propTypes = {
  
};

export default Nav;