import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const Welcome = props => {

  const { headingClass } = props;

  const boldClass = "font-medium text-[#1BB8D9]"

  return (
    <div className="text-[#505B60] text-sm border-b border-[#E0E0E0] pb-8">
      <h1 className={headingClass}>Welcome to Flow</h1>
      <div className="flex flex-col gap-3 xl:gap-5">
        <div className="font-medium">Flow is a free, open academic database and analysis platform for sequencing data.</div>
        <div>Flow lets you run Nextflow DSL2 bioinformatics pipelines using <span className={boldClass}>our intuitive GUI</span>, and stores the resultant files with your analysis in an <span className={boldClass}>open database</span> custom-built for bioinformatics. You can also keep files and data private or share them publicly.</div>
        <ReactMarkdown
          children={process.env.REACT_APP_HOME_TEXT}
          components={{
            b: (props) => <span className={boldClass} {...props} />,
            strong: (props) => <span className={boldClass} {...props} />,
            i: (props) => <span className={boldClass} {...props} />,
            em: (props) => <span className={boldClass} {...props} />,
          }}
        />
      </div>
    </div>
  );
};

Welcome.propTypes = {
  headingClass: PropTypes.string.isRequired
};

export default Welcome;