import React from "react";
import PropTypes from "prop-types";

const PrivacyIcon = props => {

  const { object } = props;

  const forcedPublic = Boolean(
    object.forcedPublicProjectId || object.forcedPublicSampleId || object.forcedPublicExecutionId
  )

  if (object.private && !forcedPublic) {
    return (
      <svg viewBox="0 0 19 25" fill="none" className={props.className || ""}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4667 7.99463V6.36162C12.4667 4.73506 11.1266 3.39487 9.5 3.39487C7.87344 3.39487 6.53325 4.73506 6.53325 6.36162V7.99463H3.91963V6.36162C3.91963 3.29243 6.43076 0.78125 9.5 0.78125C12.5692 0.78125 15.0804 3.29243 15.0804 6.36162V7.99463H12.4667ZM2.83657 9.1665H16.1635C17.3573 9.1665 18.334 10.1433 18.334 11.3371V22.0482C18.334 23.242 17.3573 24.2187 16.1635 24.2187H2.83657C1.64272 24.2187 0.666016 23.242 0.666016 22.0482V11.3371C0.665967 10.1433 1.64268 9.1665 2.83657 9.1665ZM9.5 13.1106C8.46064 13.1106 7.61802 13.9532 7.61802 14.9926C7.61802 15.6479 7.95298 16.2248 8.46094 16.5618L7.65601 19.9744C7.63828 20.0495 7.6543 20.1215 7.7022 20.182C7.7501 20.2425 7.81645 20.2746 7.8936 20.2746H11.1063C11.1834 20.2746 11.2499 20.2425 11.2977 20.182C11.3456 20.1215 11.3616 20.0495 11.3439 19.9744L10.539 16.5618C11.0469 16.2248 11.3819 15.6479 11.3819 14.9926C11.382 13.9532 10.5394 13.1106 9.5 13.1106Z" fill="#595959"/>
      </svg>
    )
  } else {
    return (
      <svg viewBox="0 0 23 25" className={props.className || ""}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.83657 9.1665H16.1635C17.3573 9.1665 18.334 10.1433 18.334 11.3371V22.0482C18.334 23.242 17.3573 24.2187 16.1635 24.2187H2.83657C1.64272 24.2187 0.666016 23.242 0.666016 22.0482V11.3371C0.665967 10.1433 1.64268 9.1665 2.83657 9.1665ZM9.5 13.1106C8.46064 13.1106 7.61802 13.9532 7.61802 14.9926C7.61802 15.6479 7.95298 16.2248 8.46094 16.5618L7.65601 19.9744C7.63828 20.0495 7.6543 20.1215 7.7022 20.182C7.7501 20.2425 7.81645 20.2746 7.8936 20.2746H11.1063C11.1834 20.2746 11.2499 20.2425 11.2977 20.182C11.3456 20.1215 11.3616 20.0495 11.3439 19.9744L10.539 16.5618C11.0469 16.2248 11.3819 15.6479 11.3819 14.9926C11.382 13.9532 10.5394 13.1106 9.5 13.1106Z" fill="#595959"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1136 8.07593V6.44292C15.1136 4.81636 16.4538 3.47617 18.0803 3.47617C19.8077 3.47617 20.4804 4.20361 21.0063 4.64686L22.4332 2.9611C22.4332 2.9611 21.1496 0.862549 18.0803 0.862549C15.0111 0.862549 12.5 3.37373 12.5 6.44292V8.07593H15.1136Z" fill="#595959"/>
      </svg>
    )
  }
};

PrivacyIcon.propTypes = {
  object: PropTypes.object.isRequired
};

export default PrivacyIcon;