import React, { useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { ReactComponent as BottomIcon } from "../images/bottom.svg";
import Modal from "./Modal";

const Terminal = props => {

  const { stdout, stderr, filename, showTerminal, setShowTerminal } = props;
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const scrollRef = useRef();

  const download = () => {
    const element = document.createElement("a");
    element.setAttribute(
      "href", "data:text/plain;charset=utf-8," + encodeURIComponent(
        stdout + "\n\n" + stderr
      )
    );
    element.setAttribute("download", `${filename}.txt`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
      setIsAtTop(true);
    }
  }

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      setIsAtBottom(true);
    }
  }

  const onScoll = e => {
    setIsAtTop(e.target.scrollTop === 0);
    setIsAtBottom(e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight);
  }

  useEffect(() => {
    if (scrollRef.current) {
      onScoll({target: scrollRef.current})
    }
  }, [showTerminal])

  const strippedStdout = stdout?.replace(/\x1b\[[0-9;]*m/g, "").replace(/\n/g, "   \n");
  const strippedStderr = stderr?.replace(/\x1b\[[0-9;]*m/g, "").replace(/\n/g, "   \n");

  return (
    <Modal
      showModal={Boolean(showTerminal)} setShowModal={setShowTerminal}
      className="text-white font-mono whitespace-pre text-xs leading-5 relative w-5/6 max-w-7xl py-0 px-0 overflow-hidden"
    >
      <div className="h-9 absolute top-0 left-0 w-full flex justify-end items-center gap-3 pr-3 bg-[#6A81D2] bg-opacity-50">
        <BottomIcon
          className={`transform rotate-180 w-5 px-px cursor-pointer hover:opacity-100 ${isAtTop ? "pointer-events-none opacity-50" : "opacity-90"}`}
          fill="white" onClick={scrollToTop}
        />
        <BottomIcon
          className={`w-5 px-px cursor-pointer hover:opacity-100 ${isAtBottom ? "pointer-events-none opacity-50" : "opacity-90"}`}
          fill="white" onClick={scrollToBottom}
        />
        <DownloadIcon 
          className="h-1/2 w-auto cursor-pointer opacity-90 hover:opacity-100"
          fill="white" onClick={download}
        />
        <div
          className="text-3xl cursor-pointer opacity-90 hover:opacity-100"
          onClick={e => setShowTerminal(false)}>×</div>
      </div>
      <div className=" w-full bg-gray-800 h-96 pt-10 overflow-auto pb-5 px-7" ref={scrollRef} onScroll={onScoll}>
        {strippedStdout && strippedStdout !== "-" && <div>{strippedStdout}</div>}
        {strippedStderr && strippedStderr !== "-" && <div className="text-red-100">{strippedStderr}</div>}
      </div>
    </Modal>
  );
};

Terminal.propTypes = {
  stdout: PropTypes.string,
  stderr: PropTypes.string,
  filename: PropTypes.string.isRequired,
  showTerminal: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.object.isRequired]),
  setShowTerminal: PropTypes.func.isRequired,
};

export default Terminal;