import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../contexts";
import Button from "./Button";
import Input from "./Input";
import { useLazyFetch } from "../hooks";

const UserDetailsForm = props => {

  const { settings, setSettings, formClass, headingClass, rowClass, cellClass, labelClass, inputClass, buttonClass } = props;

  const [user, setUser] = useContext(UserContext);

  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [errors, setErrors] = useState(null);

  const displayUsername = username === null ? settings.username : username;
  const displayEmail = email === null ? settings.email : email;
  const displayName = name === null ? settings.name : name;

  const [{ loading }, updateUser] = useLazyFetch("/settings/user", {
    method: "POST",
    onCompleted: settings => {
      const newUser = {...user};
      newUser.username = settings.username;
      newUser.name = settings.name;
      setUser(newUser);
      setSettings(settings);
    },
    onError: error => setErrors(error.error)
  })

  const onSubmit = e => {
    e.preventDefault();
    setErrors(null);
    updateUser({params: {
      username: displayUsername, email: displayEmail, name: displayName
    }});
  }

  const validation = errors || {};

  return (
    <form onSubmit={onSubmit} className={formClass}>
      <div className={headingClass}>Details</div>

      <div className={rowClass}>
        <div className={cellClass}>
          <label htmlFor="username" className={labelClass}>Username</label>
          <Input
            type="text"
            id="username"
            value={displayUsername}
            onChange={e => setUsername(e.target.value)}
            className={inputClass}
            required
            errors={validation.username}
          />
        </div>
        <div className={cellClass}>
          <label htmlFor="email" className={labelClass}>Email</label>
          <Input
            type="email"
            id="email"
            value={displayEmail}
            onChange={e => setEmail(e.target.value)}
            className={inputClass}
            required
            errors={validation.email}
          />
        </div>
        <div className={cellClass}>
          <label htmlFor="name" className={labelClass}>Name</label>
          <Input
            type="text"
            id="name"
            value={displayName}
            onChange={e => setName(e.target.value)}
            className={inputClass}
            required
            errors={validation.name}
          />
        </div>
      </div>

      <Button className={buttonClass} type="submit" loading={loading}>
        Save Details
      </Button>
    </form>
  );
};

UserDetailsForm.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  formClass: PropTypes.string.isRequired,
  headingClass: PropTypes.string.isRequired,
  rowClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  labelClass: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

export default UserDetailsForm;