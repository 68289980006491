import { useState } from "react";
import PropTypes from "prop-types";
import MetadataForm from "./MetadataForm";
import { useLazyFetch } from "../hooks";
import Button from "./Button";

const ProjectMetadataEditor = props => {

  const { project, setProject, organisms, initialSources, initialTargets } = props;

  const initialMetadata = project.samples.map(sample => ({
    ...sample,
    organism: sample.organism?.id || null
  }));

  const [metadata, setMetadata] = useState(null);
  const [errors, setErrors] = useState([]);

  const [{ loading }, updateProject] = useLazyFetch(`/projects/${project.id}/metadata`, {
    method: "POST",
    params: {metadata},
    onCompleted: project => {
      setProject(project)
      setMetadata(null);
      setErrors([]);
    },
    onError: error => setErrors(error.error.metadata)
  });

  const save = () => {
    updateProject();
  }

  return (
    <div className="edit-section">
      <div className="edit-heading">Sample Metadata</div>
      <MetadataForm
        metadata={metadata || initialMetadata}
        excludeColumns={["project"]}
        showFiles={false}
        setMetadata={setMetadata}
        organisms={organisms}
        projects={[]}
        errors={errors}
        initialSources={initialSources}
        initialTargets={initialTargets}
      />
      <Button className={`btn-primary mt-4 ${metadata ? "" : "opacity-40 pointer-events-none"}`} loading={loading} onClick={save}>
        Save Metadata
      </Button>
    </div>
  );
};

ProjectMetadataEditor.propTypes = {
  project: PropTypes.object.isRequired,
  setProject: PropTypes.func.isRequired,
  organisms: PropTypes.array.isRequired,
  initialSources: PropTypes.array.isRequired,
  initialTargets: PropTypes.array.isRequired
};

export default ProjectMetadataEditor;