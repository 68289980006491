import React, { useState } from "react";
import PropTypes from "prop-types";
import Selector from "./Selector2";
import NonStandardWarningModal from "./NonStandardWarningModal";
import Modal from "./Modal";

const MetadataForm = props => {

  const { metadata, setMetadata, organisms, projects, title, errors, disabled, showFiles, excludeColumns, initialSources, initialTargets } = props;

  const [nonStandard, setNonStandard] = useState(null);
  const [help, setHelp] = useState(null);

  const multi = metadata.length > 1;

  const sampleCategories = ["RNA-Seq", "scRNA-Seq", "ChIP-Seq", "CLIP"];

  const organismToUse = metadata.organism || organisms[0]?.id;

  const onSourceChange = ([sourceId, sourceName], sample) => {
    if (sourceId === null && sourceName) {
      setNonStandard(["cell/tissue type", sourceName, s => {
        sample.source = s;
        setMetadata([...metadata]);
      }]);
      sample.source = "";
    } else {
      sample.source = sourceName;
    }
    setMetadata([...metadata]);
  }

  const onPurificationTargetChange = ([purificationTargetId, purificationTargetName], sample) => {
    if (purificationTargetId === null && purificationTargetName) {
      setNonStandard(["purification target", purificationTargetName, p => {
        sample.purification_target = p;
        setMetadata([...metadata]);
      }]);
      sample.purification_target = "";
    } else {
      sample.purification_target = purificationTargetName;
    }
    setMetadata([...metadata]);
  }

  const fieldBlocks = [
    [
      {
        label: "Name",
        key: "name",
        helpText: "The name of the sample.",
        required: true,
      },
      {
        label: "Category",
        key: "category",
        helpText: "The sample type. Some fields here are only relevant for certain sample types.",
        options: sampleCategories,
        defaultOptionText: "Generic",
      },
      {
        label: "Organism",
        key: "organism",
        helpText: "The organism the sample contents are from.",
        options: organisms,
        value: organismToUse,
        defaultOptionText: "",
      },
      {
        label: "Project",
        key: "project",
        helpText: "A project of yours the sample should belong to. Useful for organisation and data sharing.",
        options: projects,
        defaultOptionText: "",
      }
    ],
    [
      {
        label: "Cell/Tissue",
        key: "source",
        helpText: "The cell or tissue type the sample contents are from. Flow has a list of approved terms, or you can enter your own.",
        onChange: onSourceChange,
        options: {
          query: "/samples/sources",
          textParam: "name",
          displayProp: "name",
          initial: initialSources
        },
      },
      {
        label: "Cell/Tissue Annotation",
        key: "source_text",
        helpText: "Any additional information about the cell or tissue type - this will be displayed after the cell/tissue with a colon.",
      },
      {
        label: "Purification Target",
        key: "purification_target",
        helpText: "The target of the purification process. Flow has a list of approved terms, or you can enter your own.",
        onChange: onPurificationTargetChange,
        sampleTypes: ["CLIP", "ChIP-Seq"],
        options: {
          query: "/samples/purification_targets",
          textParam: "name",
          displayProp: "name",
          initial: initialTargets
        },
      },
      {
        label: "Purification Target Annotation",
        key: "purification_target_text",
        helpText: "Any additional information about the cell or tissue type - this will be displayed after the protein name with a colon.",
        sampleTypes: ["CLIP", "ChIP-Seq"],
      }
    ],
    [
      {
        label: "Scientist",
        key: "scientist",
        helpText: "The name of the scientist who performed the experiment.",
      },
      {
        label: "PI",
        key: "pi",
        helpText: "The name of the principal investigator who oversaw the experiment.",
      },
      {
        label: "Organisation",
        key: "organisation",
        helpText: "The organisation the experiment was performed at.",
      },
      {
        label: "Purification Method",
        key: "purification_agent",
        helpText: "The purification antibody used.",
        sampleTypes: ["CLIP", "ChIP-Seq"],
      },
      {
        label: "Experimental Method",
        key: "experimental_method",
        helpText: "The experimental method used - each sample type typically has a number of specific variants.",
      },
      {
        label: "Condition",
        key: "condition",
        helpText: "The experimental condition.",
      },
      {
        label: "Sequencer",
        key: "sequencer",
        helpText: "The sequencer used to generate the data.",
      },
      {
        label: "Comments",
        key: "comments",
        helpText: "Any additional comments about the sample.",
      }
    ],
    [
      {
        label: "5' Barcode Sequence",
        key: "five_prime_barcode_sequence",
        helpText: "The 5' barcode sequence.",
        required: ["CLIP"],
      },
      {
        label: "3' Barcode Sequence",
        key: "three_prime_barcode_sequence",
        helpText: "The 3' barcode sequence.",
      },
      {
        label: "3' Adapter",
        key: "three_prime_adapter_name",
        helpText: "The 3' adapter name.",
      },
      {
        label: "3' Adapter Sequence",
        key: "three_prime_adapter_sequence",
        helpText: "The 3' adapter sequence.",
      },
      {
        label: "Read 1 Primer",
        key: "read1_primer",
        helpText: "The Read 1 Primer.",
      },
      {
        label: "Read 2 Primer",
        key: "read2_primer",
        helpText: "The Read 2 Primer.",
      },
      {
        label: "RT Primer",
        key: "rt_primer",
        helpText: "The reverse transcriptase primer.",
      }
    ],
    [
      {
        label: "UMI Sequence",
        key: "umi_barcode_sequence",
        helpText: "The UMI barcode sequence.",
        sampleTypes: ["RNA-Seq", "ChIP-Seq"],
      },
      {
        label: "UMI Separator",
        key: "umi_separator",
        helpText: "The UMI separator used in the barcode.",
      },
      {
        label: "Strandedness",
        key: "strandedness",
        helpText: "The strandedness of the sample.",
        required: ["RNA-Seq"],
        sampleTypes: ["RNA-Seq"],
        options: ["unstranded", "forward", "reverse", "auto"],
        defaultOptionText: null,
      },
      {
        label: "RNA Selection Method",
        key: "rna_selection_method",
        helpText: "The RNA selection method used.",
        options: ["polyA", "ribominus", "targeted"],
        sampleTypes: ["RNA-Seq"],
        defaultOptionText: null,
      }
    ],
    [
      {
        label: "GEO ID",
        key: "geo",
        helpText: "The accession of this sample on the Gene Expression Omnibus if present there.",
      },
      {
        label: "ENA ID",
        key: "ena",
        helpText: "The accession of this sample on the European Nucleotide Archive if present there.",
      },
      {
        label: "PubMed ID",
        key: "pubmed",
        helpText: "The PMID for the paper associated with this sample.",
      }
    ]
  ].map(block => block.filter(field => !excludeColumns || !excludeColumns.includes(field.key)));

  const sampleCategoriesPresent = metadata.map(sample => sample.category);

  // Move required fields to first block
  for (let block of fieldBlocks.slice(1)) {
    const indices = [];
    for (let field of block) {
      if (Array.isArray(field.required)) {
        if (field.required.some(t => sampleCategoriesPresent.includes(t))) {
          fieldBlocks[0].push(field);
          indices.push(block.indexOf(field));
        }
      }
    }
    for (let index of indices.reverse()) block.splice(index, 1);
  }

  const inputClass = "bg-[#F3F3F3] block text-xs outline-none px-2 border-b py-1.5 h-7 font-medium";
  const multiInputClass = `${inputClass} rounded-none `;
  const singleInputClass = `${inputClass} rounded`;
  const selectorClass = "bg-[#F3F3F3] block outline-none w-full font-medium";
  const multiSelectorClass = `${selectorClass} rounded-none`;
  const singleSelectorClass = `${selectorClass} rounded`;
  const labelClass = "font-medium text-xs block mb-0.5";
  const optionsClass = "bg-[#F3F3F3] rounded-b-md";
  const optionClass = "py-1 px-2 text-xs cursor-pointer";

  return (
    <div className={props.className || ""}>
      {title && metadata.length > 0 && <div className="font-medium text-lg mb-3">{title}</div>}
      
      <div className="flex">
        {multi && (
          <div className="table">
            <div className="table-row h-4 text-sm font-medium">
              <div className="table-cell whitespace-nowrap">
                {fieldBlocks[0][0].label}
                <span> (required)</span>
                <span className="ml-px w-4 h-4 scale-75 text-2xs cursor-pointer rounded-full inline-flex bg-[#37474F] text-white justify-center items-center relative bottom-1" onClick={() => setHelp(fieldBlocks[0][0])}>?</span>
              </div>
            </div>
            {metadata.map((sample, i) => (
              <div className="table-row h-7" key={i}>
                <div className="table-cell">
                  <input
                    className={`${multiInputClass} ${false ? "border border-red-500 bg-red-100 text-red-600" : ""} w-56`}
                    value={sample[fieldBlocks[0][0].key] || ""}
                    onChange={e => {
                      sample[fieldBlocks[0][0].key] = e.target.value;
                      setMetadata([...metadata]);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={`${disabled ? "pointer-events-none opacity-50" : ""} ${multi ? "overflow-auto" : ""}`}>
          <div className={multi ? "table" : ""}>
            {multi && (
              <div className="table-row text-sm font-medium">
                {showFiles && <div className="table-cell">File(s)</div>}
                {fieldBlocks.map((block, j) => (
                  block.filter(b => !b.sampleTypes || b.sampleTypes.some(t => sampleCategoriesPresent.includes(t))).map((field, k) => {
                    const isRequired = Array.isArray(field.required) ? field.required.some(t => sampleCategoriesPresent.includes(t)) : field.required;
                    if (k === 0 && j == 0) return null;
                    return (
                      <div className="table-cell whitespace-nowrap" key={k}>
                        {field.label}
                        {isRequired && <span> (required)</span>}
                        <span className="ml-px w-4 h-4 scale-75 text-2xs cursor-pointer rounded-full inline-flex bg-[#37474F] text-white justify-center items-center relative bottom-1" onClick={() => setHelp(field)}>?</span>
                      </div>
                    )
                  })
                ))}
              </div>
            )}
            {metadata.map((sample, i) => (
              <div className={multi ? "table-row h-7" : "flex flex-wrap gap-x-4 gap-y-2.5"} key={i}>
                {multi && showFiles && (
                  <div className="table-cell text-sm whitespace-nowrap font-medium mb-1 pr-1.5">
                    {sample.file1.name}
                    {sample.file2 && (
                      <span className=""> / {sample.file2.name}</span>
                    )}
                  </div>
                )}
                {fieldBlocks.map((block, j) => (
                  block.filter(b => !b.sampleTypes || b.sampleTypes.some(t => sampleCategoriesPresent.includes(t))).map((field, k) => {
                    const errorMessages = errors[i] ? (errors[i][field.key] || []) : [];
                    const hasError = errorMessages.length > 0;
                    const isRequired = Array.isArray(field.required) ? field.required.some(t => sampleCategoriesPresent.includes(t)) : field.required;
                    if (multi && k === 0 && j == 0) return null;
                    return (
                      <React.Fragment key={k}>
                        <div className={`${multi ? "table-cell" : ""} ${k === 0 && j === 0 ? "relativ bottom-p" : ""}`}>
                          {!multi && (
                            <div className={labelClass}>
                              {field.label} {isRequired && " (required)"}
                              <span className="ml-px w-4 h-4 scale-75 text-2xs cursor-pointer rounded-full inline-flex bg-[#37474F] text-white justify-center items-center relative bottom-1" onClick={() => setHelp(field)}>?</span>
                            </div>
                          )}
                          {!multi && (
                            <div>
                              {errorMessages.map((message, index) => (
                                <div className="text-red-500 text-xs mb-1" key={index}>{message}</div>
                              ))}
                            </div>
                          )}
                          {!field.options && (
                            <input
                              className={`${multi ? multiInputClass : singleInputClass} ${hasError ? "border border-red-500 bg-red-100 text-red-600" : multi ? "" : "border-[#F3F3F3]"} ${multi ? "w-56" : "w-56"} ${multi ? (k === 0 && j !== 0 ? "border-l-4" : "border-l") : ""}`}
                              value={sample[field.key] || ""}
                              onChange={e => {
                                sample[field.key] = e.target.value;
                                setMetadata([...metadata]);
                              }}
                            />
                          )}
                          {field.options && !Array.isArray(field.options) && (
                            <Selector
                              value={sample[field.key] || ""}
                              onChange={value => field.onChange(value, sample)}
                              text={sample[field.key] || ""}
                              returnText={true}
                              fixed={multi}
                              initialData={field.options.initial}
                              className={`${multi ? multiSelectorClass : singleSelectorClass} ${multi ? "w-36" : "w-56"} ${multi ? (k === 0 && j !== 0 ? "border-l-4" : "border-l") : ""}`}
                              inputClassName={`${multi ? multiInputClass : singleInputClass} w-full ${hasError ? "border border-red-500 bg-red-100 text-red-600" : ""}`}
                              optionsClassName={optionsClass}
                              optionClassName={optionClass}
                              query={field.options.query}
                              textParam={field.options.textParam}
                              pageLength={5}
                              displayProp={field.options.displayProp}
                            /> 
                          )}
                          {field.options && Array.isArray(field.options) && (
                            <select
                              className={`${multi ? multiInputClass : singleInputClass} ${hasError ? "border border-red-500 bg-red-100 text-red-600" : multi ? "" : "border-[#F3F3F3]"} relative top-p -bottom-px ${multi ? "w-40" : "w-56"} ${k === 0 && j !== 0 ? "border-l-4" : "border-l"}`}
                              value={sample[field.key] === "polya" ? "polyA" : (field.key in sample ? (sample[field.key] || "") : field.value)}
                              onChange={e => {
                                sample[field.key] = e.target.value === "polyA" && field.key === "rna_selection_method" ? "polya" : e.target.value;
                                setMetadata([...metadata]);
                              }}
                            >
                              <option value=""></option>
                              {field.options.map(option => (
                                <option value={option?.id || option} key={option?.id || option}>
                                  {option?.name || option}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        {k === block.filter(b => !b.sampleTypes || b.sampleTypes.some(t => sampleCategoriesPresent.includes(t))).length - 1 && !multi && <div className="w-full mb-4" />}
                      </React.Fragment>
                    )
                  })
                ))}
              </div>
            ))}
          </div>
          {nonStandard && (
            <NonStandardWarningModal
              setShowModal={() => setNonStandard(null)}
              attribute={nonStandard[0]}
              value={nonStandard[1]}
              setValue={nonStandard[2]}
            />
          )}
          {help && (
            <Modal setShowModal={setHelp} title={help.label} className="max-w-sm text-center" closable={true}>
              <div>{help.helpText}</div>
            </Modal>
          )}
        </div>

      </div>
    </div>
  );
};

MetadataForm.propTypes = {
  metadata: PropTypes.array.isRequired,
  setMetadata: PropTypes.func.isRequired,
  organisms: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  title: PropTypes.string,
  errors: PropTypes.array,
  disabled: PropTypes.bool,
  showFiles: PropTypes.bool,
  excludeColumns: PropTypes.array,
  initialSources: PropTypes.object,
  initialTargets: PropTypes.object,
};

export default MetadataForm;