import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useLazyFetch } from "../hooks";

const DeleteData = props => {

  const { data } = props;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [{ loading }, deleteData] = useLazyFetch(`/data/${data.id}/delete`, {
    method: "POST",
    onCompleted: () => {
      setShowModal(false);
      navigate("/data/");
    }
  })

  let text = `This will permanently delete the data ${data.filename}. If it has been used in any analysis a record will be retained of its metadata, but the contents will be erased.`
  if (process.env.REACT_APP_USE_SYSTEM_GROUPS === "true") {
    text += "If the data was imported from outside Flow, the file on disk will not be deleted."
  }

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <div className="edit-section">
      <div className="edit-heading text-red-900">Deletion</div>
      <div className="text-sm mb-6 text-red-900">Permanently remove this data - this will remove the file from Flow.</div>
      <button
        onClick={() => setShowModal(true)}
        className={`w-fit ${secondaryClass}`}
      >
        Remove Data
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={"Remove Data?"} text={text} className="max-w-lg">
          <div className="flex gap-3 mx-auto w-fit">
            <Button className={primaryClass} loading={loading} onClick={deleteData}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteData.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DeleteData;