import { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as TerminalIcon } from "../images/terminal.svg";
import { ReactComponent as NfIcon } from "../images/nf.svg";
import Terminal from "./Terminal";

const ExecutionTerminals = props => {

  const { execution } = props;

  const [showTerminal, setShowTerminal] = useState(false);
  const [showLog, setShowLog] = useState(false);

  const hasTerminal = Boolean(execution.stdout) || Boolean(execution.stderr);
  const hasLog = Boolean(execution.log);

  const rowClass = "flex items-center gap-2 text-xs font-medium group sm:text-sm";
  const iconClass = "w-7";
  const spanClass = "group-hover:underline"

  return (
    <div className="flex flex-col gap-x-8 gap-y-4 sm:flex-row sm:items-center">
      <div className={`${rowClass} ${hasTerminal ? "cursor-pointer" : "pointer-events-none opacity-50"}`} onClick={() => setShowTerminal(true)}>
        <TerminalIcon className={iconClass} fill="#37474F" />
        <span className={spanClass}>Show Terminal</span>
      </div>
      <div className={`${rowClass} ${hasLog ? "cursor-pointer" : "pointer-events-none opacity-50"}`} onClick={() => setShowLog(true)}>
        <NfIcon className={iconClass} />
        <span className={spanClass}>Show Nextflow Log</span>
      </div>
      {showTerminal && (
        <Terminal
          stdout={execution.stdout} stderr={execution.stderr}
          showTerminal={showTerminal} setShowTerminal={setShowTerminal}
          filename={`execution-${execution.id}-${execution.pipeline.name.replace(/ /g, '_').toLowerCase()}`}
        />
      )}
      {showLog && (
        <Terminal
          stdout={execution.log}
          showTerminal={showLog} setShowTerminal={setShowLog}
          filename={`execution-${execution.id}-${execution.pipeline.name.replace(/ /g, '_').toLowerCase()}-log`}
        />
      )}
    </div>
  );
};

ExecutionTerminals.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionTerminals;