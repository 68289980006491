import { useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { CREATE_PIPELINE_CONFIG } from "../mutations";
import { useMutation } from "@apollo/client";
import { PIPELINE_CONFIG } from "../queries";
import DeletePipelineConfig from "./DeletePipelineConfig";

const PipelineConfig = props => {

  const { config } = props;

  const ref = useRef();

  const [canSave, setCanSave] = useState(false);

  const [createConf, createConfMutation] = useMutation(CREATE_PIPELINE_CONFIG, {
    refetchQueries: [{ query: PIPELINE_CONFIG }],
    awaitRefetchQueries: true,
    onCompleted: () => setCanSave(false)
  });

  const textChanged = () => {
    setCanSave(ref.current.innerText !== config.contents);
  }

  return (
    <div>
      <div className="mb-1">{config.filename}</div>
      <div
        className="py-2 px-4 rounded w-full bg-slate-800 text-white font-mono text-sm whitespace-pre overflow-auto outline-none"
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={textChanged}
        ref={ref}
      >
        {config.contents}
      </div>
      <div className="flex gap-2 mt-2">
        <Button
          className={`btn-primary px-2 py-1 text-sm ${canSave ? "" : "pointer-events-none opacity-50"}`}
          onClick={() => createConf({variables: { filename: config.filename, contents: ref.current.innerText }})}        loading={createConfMutation.loading}
        >
          Update
        </Button>
        <DeletePipelineConfig config={config} />
      </div>
    </div>
  );
};

PipelineConfig.propTypes = {
  config: PropTypes.object.isRequired
};

export default PipelineConfig;