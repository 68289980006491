import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { processName } from "../utils";
import { ReactComponent as BashIcon } from "../images/external.svg";
import Terminal from "./Terminal";

const DataSource = props => {

  const { data } = props;
  const [showCommand, setShowCommand] = useState(false);

  return (
    <div className={`text-[#777777] text-xs sm:text-sm ${props.className || ""}`}>
      {data.absolute_path && (
        <span>This file was <span className="font-medium">imported</span> from elsewhere on the server.</span>
      )}
      {!data.execution && !data.absolute_path && (
        <span>This file was <span className="font-medium">uploaded</span>.</span>
      )}
      {data.execution && !data.absolute_path && (
        <span>
          This file was created during a{" "}
          <Link
            className="font-medium text-[#37474F]"
            to={`/executions/${data.execution.id}/`}
          >
            {data.execution.pipeline_name}
          </Link>
          {" "}pipeline execution by the{" "}
          <span className="font-medium text-[#37474F] cursor-pointer" onClick={() => setShowCommand(true)}>
            {processName(data.execution.process_name)}
            <BashIcon className="w-3 h-auto inline-block ml-0.5 pl-px mb-4" fill="#37474F" />
          </span>
          {" "}process.
        </span>
      )}
      {showCommand && (
        <Terminal
          showTerminal={showCommand} setShowTerminal={setShowCommand}
          stdout={data.execution.process_command}
        />
      )}
    </div>
  );
};

DataSource.propTypes = {
  data: PropTypes.object.isRequired
};

export default DataSource;