import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { UserContext } from "../contexts";
import { useNavigate } from "react-router-dom";
import Input from "./Input";
import { useLazyFetch } from "../hooks";

const GroupForm = props => {

  const { group } = props;

  const [name, setName] = useState(null);
  const [slug, setSlug] = useState(null);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState(null);

  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const displayName = name === null ? group.name : name;
  const displaySlug = slug === null ? group.slug : slug;
  const displayDescription = description === null ? group.description : description;

  const [{ loading }, updateGroup] = useLazyFetch(`/groups/${group.slug}/edit`, {
    method: "POST",
    onCompleted: data => {
      const newSlug = slug || group.slug;
      for (let userGroup of user.memberships) {
        if (userGroup.slug === group.slug) {
          userGroup.slug = newSlug;
          break;
        }
      }
      setUser({...user});
      navigate(`/groups/@${newSlug}/`);
    },
    onError: error => setErrors(error.error)
  });

  const onSubmit = e => {
    e.preventDefault();
    updateGroup({params: {
      id: group.id, name: displayName, slug: displaySlug,
      description: displayDescription
    }})
  }

  const inputClass = "bg-[#EDEDED] rounded block";
  const validation = errors || {};

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col items-start gap-2 font-medium w-full max-w-xl mb-2 md:flex-row">
        <Input
          value={displayName}
          onChange={e => setName(e.target.value)}
          className={`${inputClass} w-full`}
          containerClassName="w-full md:flex-grow"
          required
          errors={validation.name}
        />
        <div className="relative w-full flex-shrink-0 md:w-1/3">
          <Input
            value={displaySlug}
            onChange={e => setSlug(e.target.value)}
            className={`${inputClass} text-[#3B59C3] pl-6 w-full`}
            required
            errors={validation.slug}
          />
          <div className="absolute bottom-1 -mb-px -ml-px left-2.5 text-[#3B59C3] font-medium">@</div>
        </div>
      </div>
      <Input
        value={displayDescription}
        onChange={e => setDescription(e.target.value)}
        className={`${inputClass} block w-full max-w-xl h-32 text-sm resize-none mb-4`}
        required
        textarea={true}
        errors={validation.description}
      />
      <Button type="submit" className="btn-primary" loading={loading}>
        Save Changes
      </Button>
    </form>
  );
};

GroupForm.propTypes = {
  group: PropTypes.object.isRequired
};

export default GroupForm;