import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { BULK_DELETE_DATA, BULK_DELETE_EXECUTIONS, BULK_DELETE_SAMPLES } from "../mutations";
import { useMutation } from "@apollo/client";
import { parseError } from "../errors";

const BulkDeleteModal = props => {

  const { setShowModal, isExecutions, isSamples, selectedIds, postDelete, refetch } = props;

  const [error, setError] = useState(null);

  let objects = isExecutions ? "executions" : isSamples ? "samples" : "data objects";
  if (selectedIds.length === 1) {
    objects = objects.slice(0, -1);
  }

  const handleSuccess = () => {
    setShowModal(false);
    postDelete(selectedIds);
  }

  const handleError = error => {
    const parsedError = parseError(error);
    const message = (parsedError?.validation?.ids || [])[0];
    if (message && message.includes("not owned")) {
      if (message.includes("contains")) {
        setError("One or more of the selected items contains data you do not own.")
      } else {
        setError("You do not own everything you have selected.")
      }
    } else {
      setError("There was a problem deleting these items.");
    }
  }

  const [bulkDeleteData, bulkDeleteDataMutation] = useMutation(BULK_DELETE_DATA, {
    onCompleted: handleSuccess,
    onError: handleError,
    refetchQueries: refetch,
    awaitRefetchQueries: true,
  });

  const [bulkDeleteExecutions, bulkDeleteExecutionsMutation] = useMutation(BULK_DELETE_EXECUTIONS, {
    onCompleted: handleSuccess,
    onError: handleError,
    refetchQueries: refetch,
    awaitRefetchQueries: true,
  });

  const [bulkDeleteSamples, bulkDeleteSamplesMutation] = useMutation(BULK_DELETE_SAMPLES, {
    onCompleted: handleSuccess,
    onError: handleError,
    refetchQueries: refetch,
    awaitRefetchQueries: true,
  });

  const onSubmit = async () => {
    if (isExecutions) {
      await bulkDeleteExecutions({variables: {ids: selectedIds}});
    } else if (isSamples) {
      await bulkDeleteSamples({variables: {ids: selectedIds}});
    } else {
      await bulkDeleteData({variables: {ids: selectedIds}});
    }
  }

  return (
    <Modal
      className="w-full max-w-sm"
      setShowModal={setShowModal}
      title={`Delete ${selectedIds.length} ${objects}`}
      text={`Are you sure you want to delete ${selectedIds.length === 1 ? "this" : "these"} ${selectedIds.length} ${objects}? This is irreversible.`}
    >
      {error && (
        <div className="text-red-700 text-sm mx-auto w-fit">{error}</div>
      )}
      <div className="flex gap-3 flex-col w-full mx-auto mt-4 sm:w-64 sm:flex-row">
        <Button
          className="btn-primary py-1 w-full bg-red-700 border-red-700 hover:border-red-800 hover:bg-red-800 sm:w-1/2 sm:py-1.2"
          loading={bulkDeleteDataMutation.loading || bulkDeleteExecutionsMutation.loading || bulkDeleteSamplesMutation.loading}
          onClick={onSubmit}
        >
          Yes, delete
        </Button>
        <button className="btn-secondary py-1 w-full sm:w-1/2 sm:py-1.5" onClick={() => setShowModal(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

BulkDeleteModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  isExecutions: PropTypes.bool.isRequired,
  isSamples: PropTypes.bool.isRequired,
  selectedIds: PropTypes.array.isRequired,
  refetch: PropTypes.array.isRequired,
};

export default BulkDeleteModal;