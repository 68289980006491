import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { SUBMIT_BULK_DOWNLOAD } from "../mutations";
import { useState } from "react";
import { BULK_DOWNLOAD_JOB, TOKEN } from "../queries";

const BulkDownloadButton = props => {

  const { selectedIds, isExecutions, isSamples } = props;
  const [jobId, setJobId] = useState(null);
  const client = useApolloClient();
  
  const [submitBulkDownload, submitBulkDownloadMutation] = useMutation(SUBMIT_BULK_DOWNLOAD, {
    onCompleted: data => setJobId(data.submitBulkDownload.bulkDownloadJob.id)
  })

  useQuery(BULK_DOWNLOAD_JOB, {
    variables: {id: jobId},
    pollInterval: 1000,
    skip: !jobId,
    onCompleted: data => {
      if (data.bulkDownloadJob.finished !== null) {
        download(jobId);
        setJobId(null);
      }
    }
  })

  const download = jobId => {
    let token;
    try {
      const cacheValue = client.cache.readQuery({query: TOKEN});
      token = `?token=${cacheValue.accessToken}`;
    } catch {
      token = "";
    }
    const url = `${process.env.REACT_APP_DOWNLOADS}/${jobId}${token}`;
    const link = document.createElement("a");
    link.download = `${jobId}.zip`;
    link.href = url;
    link.click();
  }

  const submit = () => {
    const key = isExecutions ? "executionIds": isSamples ? "sampleIds" : "dataIds";
    submitBulkDownload({variables: { [key]: selectedIds }});
  }

  return (
    <Button
      className={`btn-primary gap-2 py-0.5 text-sm px-2 ${selectedIds.length !== 0 || "opacity-20 pointer-events-none"} ${props.className || ""}`}
      onClick={submit}
      loading={submitBulkDownloadMutation.loading || Boolean(jobId)}
    >
      <DownloadIcon className="w-4 h-auto" />
      Download
    </Button>
  );
};

BulkDownloadButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  isExecutions: PropTypes.bool,
  isSamples: PropTypes.bool,
};

export default BulkDownloadButton;