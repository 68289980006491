import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as CuratedIcon } from "../images/curated.svg";
import { fileSize } from "../utils";

const CuratedOutputs = props => {

  const { execution } = props;

  const hasSchema = execution.pipeline.schema !== null;
  const schema = execution.pipeline.schema || {"outputs":[]};

  const dataProduced = execution.process_executions.reduce((p, c) => [
    ...p, ...c.downstream_data.map(d => ({...d, process: c}))
  ], []);

  for (let output of schema.outputs) {
    output.data = dataProduced.filter(d => {
      const processNames = d.process.process_name.split(":");
      const matchNames = output.process.split(":");
      const relevantProcessNames = processNames.slice(-(matchNames.length));
      const nameMatches = JSON.stringify(matchNames) === JSON.stringify(relevantProcessNames);
      let filenameMatches = false;
      if (output.filetype) {
        const pattern = output.filetype.endsWith("$") ? output.filetype : output.filetype + "$";
        filenameMatches = d.filename.match(pattern);
      } else {
        filenameMatches = d.filetype === "";
      }
      return nameMatches && filenameMatches;
    })
  }

  const outputs = schema.outputs.filter(o => o.data.length);

  return (
    <div className="max-w-full">
      <div className="flex gap-2 items-center font-medium text-lg mb-3 md:text-xl">
        <CuratedIcon />Curated Data
      </div>
      <div className="flex flex-col gap-5 max-w-xl">
        {!hasSchema && (
          <div className="italic">Curated data is not available for this pipeline.</div>
        )}
        {outputs.map((output, index) => (
          <div key={index}>
            <div className=" text-base font-medium mb-0.5">{output.name}</div>
            <div className="text-sm mb-1">{output.description}</div>
            <div className="flex flex-col gap-1">
              {output.data.map(data => (
                <span key={data.id}>
                  <Link to={`/data/${data.id}/`} className="text-[#3B59C3] break-words font-medium text-sm">
                    {data.filename}
                  </Link>
                  <span className="text-xs ml-2">{fileSize(data.size)}</span>
                </span>
              ))}
            </div>
          </div>
        ))}
        {outputs.length === 0 && !execution.taskFinished && hasSchema && (
          <div className="italic">Curated data not produced yet.</div>
          )}
        {outputs.length === 0 && execution.taskFinished && hasSchema && (
          <div className="italic">No curated data for this execution.</div>
        )}
      </div>
    </div>
  );
};

CuratedOutputs.propTypes = {
  execution: PropTypes.object.isRequired
};

export default CuratedOutputs;