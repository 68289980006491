import { useState } from "react";
import PropTypes from "prop-types";
import ExecutionRow2 from "./ExecutionRow2";
import Paginator from "./Paginator2";
import { ClipLoader } from "react-spinners";
import TableFilter from "./TableFilter";
import BulkDeleteButton from "./BulkDeleteButton2";
import BulkDownloadButton2 from "./BulkDownloadButton2";

const ExecutionTable2 = props => {

  const { executions, page, setPage, pageSize, totalCount, onFilter, loading, noMessage, onBulkDelete } = props;

  const [selectedIds, setSelectedIds] = useState([]);

  const executionsChecked = d => {
    if (selectedIds.includes(d.id)) {
      setSelectedIds(selectedIds.filter(id => id !== d.id))
    } else {
      setSelectedIds([...selectedIds, d.id])
    }
  }

  const selectedOnPage = executions.filter(d => selectedIds.includes(d.id));
  const anySelected = selectedOnPage.length > 0;

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="relative" style={{minHeight: 250}}>
      <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
        <div className="flex flex-wrap gap-2 items-center">
          <TableFilter filter={onFilter} />
          {totalPages > 1 && (
            <Paginator
              currentPage={page} totalPages={totalPages} onChange={setPage}
            />
          )}
        </div>
        {totalCount > 0 && (
          <div className="flex flex-wrap gap-2 items-center">
            {executions.length > 0 && (
              <div
                className="text-xs link w-fit"
                onClick={() => setSelectedIds(anySelected ? [] : [...selectedIds, ...executions.map(d => d.id)])}
              >
                {anySelected ? "Deselect All" : "Select All"}
              </div>
            )}
            <div className="flex flex-wrap gap-2 items-center">
              <BulkDownloadButton2 selectedIds={selectedIds} isExecutions={true} />
              {onBulkDelete && (
                <BulkDeleteButton
                  selectedIds={selectedIds}
                  onDelete={onBulkDelete}
                  setSelectedIds={setSelectedIds}
                  isExecutions={true}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {executions.length === 0 && !loading && (
        <div className="mt-8 italic text-sm">
          <span className="info border-l-red-200">{noMessage}</span>
        </div>
      )}
      {loading && (
        <div className="absolute z-40 h-48 w-full flex justify-center items-center">
          <ClipLoader size={100} speedMultiplier={2} />
        </div> 
      )}
      <div className="overflow-y-auto rounded shadow w-full relative">
        <table className={`border-collapse w-full border-0 overflow-hidden ${loading ? "opacity-50 pointer-events-none" : ""}`}>
          <tbody>
            {executions.map((ex, index) => (
              <ExecutionRow2
                execution={ex} key={ex.id} index={index}
                checked={selectedIds.includes(ex.id)}
                onCheck={() => executionsChecked(ex)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ExecutionTable2.propTypes = {
  executions: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  onBulkDelete: PropTypes.func,
};

export default ExecutionTable2;