import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const TableFilter = props => {

  const { filter } = props;

  const [query, setQuery] = useState("");

  const timeout = useRef(null);

  const textEntered = e => {
    setQuery(e.target.value);
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      filter(e.target.value)
    }, 500)
  }

  useEffect(() => {
    return () => filter("");
  }, [])

  return (
    <input
      className="bg-[#F3F3F3] rounded text-xs px-3 py-1.5 md:px-4 md:py-2"
      value={query}
      placeholder="Filter..."
      onChange={textEntered}
    />
  );
};

TableFilter.propTypes = {
  filter: PropTypes.func.isRequired,
};

export default TableFilter;