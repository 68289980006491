import React, { useEffect, useState } from "react";
import Base from "./Base";
import privacy from "../documents/privacy.md";
import Document from "../components/Document";
import { useDocumentTitle } from "../hooks";

const PrivacyPage = () => {

  useDocumentTitle("Privacy Policy - Flow");

  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    fetch(privacy).then(res => res.text()).then(text => setMarkdown(text));
  }, []);

  if (!markdown) return <Base loading={true} />

  return (
    <Base>
      <Document markdown={markdown} />
    </Base>
  );
};

PrivacyPage.propTypes = {
  
};

export default PrivacyPage;