import { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ReadIcon } from "../images/read.svg";
import { ReactComponent as EditIcon } from "../images/pencil.svg";
import { ReactComponent as ShareIcon } from "../images/share.svg";
import { Link } from "react-router-dom";
import UserSelector2 from "./UserSelector2";
import GroupSelector2 from "./GroupSelector2";
import { useLazyFetch } from "../hooks";

const PermissionEditor2 = props => {

  const { object, setObject, permission } = props;

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const matchingLinks = object.links.filter(link => link.permission === permission);
  const direct = matchingLinks.filter(link => !link.execution && !link.sample && !link.project);
  const indirect = matchingLinks.filter(link => link.execution || link.sample || link.project);
  const directUsers = direct.filter(link => link.user);
  const directGroups = direct.filter(link => link.group);
  const indirectUsers = indirect.filter(link => link.user);
  const indirectGroups = indirect.filter(link => link.group);

  const PermissionIcon = {
    1: ReadIcon,
    2: EditIcon,
    3: ShareIcon,
  }[permission];

  const permissionName = ["read", "edit", "share"][permission - 1];

  const objectType = object.filename ? "data" : object.pipeline_name ? "execution" : "project" in object ? "sample" : "project";
  const pluralType = objectType === "data" ? "data" : `${objectType}s`;


  const [{loading}, updatePermission] = useLazyFetch(`/${pluralType}/${object.id}/share`, {
    method: "POST",
    onCompleted: object => setObject(object)
  });

  const removeUser = user => {
    updatePermission({
      params: {
        permission: 0,
        user: user.username,
        id: object.id,
      }
    })
  }

  const removeGroup = group => {
    updatePermission({
      params: {
        permission: 0,
        group: group.slug,
        id: object.id,
      }
    })
  }

  const addUser = () => {
    updatePermission({
      params: {
        permission,
        user: selectedUser.username,
        id: object.id,
      }
    })
    setSelectedUser(null);
  }

  const addGroup = () => {
    updatePermission({
      params: {
        permission,
        group: selectedGroup.slug,
        id: object.id,
      }
    })
    setSelectedGroup(null);
  }

  const text = {
    read: `This determines who can access the ${objectType} when it is private - they will be able to view, download and use it, but not modify it or share it.`,
    edit: `This determines who can edit the ${objectType} - they will be able to read and modify its attributes, though not share it.`,
    share: `This determines who can share the ${objectType} - they will be able to read, modify and alter its permissions.`,
  }

  const columnClass = "";
  const linkClass = "text-[#3B59C3] hover:underline";
  const headingClass = "font-medium text-sm";
  const nameClass = "inline-block text-sm text-[#3B59C3] w-fit";
  const removeClass = "text-xs text-red-500 cursor-pointer opacity-60 w-fit hover:opacity-100";
  const inputClass = "rounded-none text-sm h-7";
  const placeholderClass = "text-gray-500";
  const addRowClass = "flex gap-2 w-full";
  const addButtonClass = "btn-primary py-0 px-2 text-xs opacity-60 hover:opacity-100";

  return (
    <div className={`w-full max-w-md 2xl:max-w-lg ${!loading || "opacity-50 pointer-events-none"} ${props.className || ""}`}>
      <div className="font-medium mb-1 flex items-center gap-1 text-[#3B59C3]">
        <PermissionIcon className="inline-block w-4 h-auto mr-1" fill="#3B59C3" />
        {permissionName[0].toUpperCase()}{permissionName.slice(1)} Access
      </div>
      <div className="text-xs mb-2 sm:text-sm">{text[permissionName]}</div>
      <div className="max-w-md gap-x-4 sm:grid sm:grid-cols-2">

        <div className={columnClass}>
          <div className={headingClass}>Users</div>
          <div className="flex flex-col gap-2">
            {directUsers.map(link => (
              <div key={link.user.username}>
                <Link to={`/users/${link.user.username}/`} className={nameClass}>{link.user.name}</Link>
                <div onClick={() => removeUser(link.user)} className={removeClass}>
                  Remove
                </div>
              </div>
            ))}
            <div className={addRowClass}>
              <UserSelector2
                user={selectedUser}
                setUser={setSelectedUser}
                className="flex-grow"
                placeholder="Search for a user..."
                placeholderClass={placeholderClass}
                inputClass={inputClass}
              />
              {selectedUser && (
                <button onClick={addUser} className={addButtonClass}>Add</button>
              )}
            </div>
          </div>
        </div>

        <div className={columnClass}>
          <div className={headingClass}>Groups</div>
          <div className="flex flex-col gap-2">
            {directGroups.map(link => (
              <div key={link.group.slug}>
                <Link to={`/groups/@${link.group.slug}`} className={nameClass}>{link.group.name}</Link>
                <div onClick={() => removeGroup(link.group)} className={removeClass}>
                  Remove
                </div>
              </div>
            ))}
            <div className={addRowClass}>
              <GroupSelector2
                group={selectedGroup}
                setGroup={setSelectedGroup}
                className="flex-grow"
                placeholder="Search for a group..."
                placeholderClass={placeholderClass}
                inputClass={inputClass}
              />
              {selectedGroup && (
                <button onClick={addGroup} className={addButtonClass}>Add</button>
              )}
            </div>
          </div>
        </div>

      </div>


      <div  className="text-xs text-gray-600 mt-5 flex flex-col gap-1 2xl:text-sm">
        {indirectUsers.concat(indirectGroups).map((link, index) => (
          <div key={index}>
            {link.user && (
              <Link to={`/users/${link.user.username}`} className={linkClass}>{link.user.name}</Link>
            )}
            {link.group && (
              <Link to={`/groups/@${link.group.slug}`} className={linkClass}>{link.group.name}</Link>
            )}
            {" "}has {permissionName} access to this {objectType} via {" "}
            {link.execution && (
              <Link to={`/executions/${link.execution.id}/`} className={linkClass}>a {link.execution.pipeline} execution</Link>
            )}
            {link.sample && (
              <Link to={`/samples/${link.sample.id}/`} className={linkClass}>sample {link.sample.name}</Link>
            )}
            {link.project && (
              <Link to={`/projects/${link.project.id}/`} className={linkClass}>project {link.project.name}</Link>
            )}.
          </div>
        ))}
      </div>
    </div>
  );
};

PermissionEditor2.propTypes = {
  object: PropTypes.object.isRequired,
  setObject: PropTypes.func.isRequired,
  permission: PropTypes.number.isRequired,
};

export default PermissionEditor2;