import { useState } from "react";
import { PIPELINE_CONFIG } from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import PipelineConfig from "./PipelineConfig";
import { CREATE_PIPELINE_CONFIG } from "../mutations";
import Button from "./Button";

const PipelineConfigTab = () => {

  const { data, loading } = useQuery(PIPELINE_CONFIG);

  const [filename, setFilename] = useState("");
  const [contents, setContents] = useState("");

  const canCreate = filename && contents && !data.pipelineConfigs.find(c => c.filename === filename);

  const [createConf, createConfMutation] = useMutation(CREATE_PIPELINE_CONFIG, {
    variables: { filename, contents },
    refetchQueries: [{ query: PIPELINE_CONFIG }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setFilename("");
      setContents("");
    }
  });

  if (loading) return <ClipLoader />;

  return (
    <div className="flex flex-col gap-6">
      {data.pipelineConfigs.map(config => <PipelineConfig key={config.filename} config={config} />)}
      <div>
        <input
          type="text"
          value={filename}
          onChange={e => setFilename(e.target.value)}
          placeholder="Filename"
          className="px-0"
        />
        <textarea
          value={contents}
          onChange={e => setContents(e.target.value)}
          className="bg-slate-800 text-white font-mono text-sm whitespace-pre w-full h-48 py-2 px-4"
        />
        <Button className={`btn-primary ${canCreate ? "" : "opacity-50 pointer-events-none"}`} loading={createConfMutation.loading} onClick={createConf}>
          Create
        </Button>
      </div>
    </div>
  );
};

PipelineConfigTab.propTypes = {
  
};

export default PipelineConfigTab;