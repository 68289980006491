import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { CREATE_PIPELINE_CATEGORY, UPDATE_PIPELINE_CATEGORY } from "../mutations";
import { PIPELINE_ADMIN } from "../queries";
import { parseError } from "../errors";

const PipelineCategoryForm = props => {

  const { category, setShow } = props;

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? category ? category.name : "" : (name || "");
  const descriptionText = description === null ? category ? category.description : "" : (description || "");

  const [createCategory, createCategoryMutation] = useMutation(CREATE_PIPELINE_CATEGORY, {
    variables: {name: nameText, description: descriptionText},
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const [updateCategory, updateCategoryMutation] = useMutation(UPDATE_PIPELINE_CATEGORY, {
    variables: {id: category?.id, name: nameText, description: descriptionText},
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    if (category) updateCategory();
    if (!category) createCategory();
  };

  const loading = createCategoryMutation.loading || updateCategoryMutation.loading;

  const blockClass = "w-full max-w-sm";
  const labelClass = "text-[#37474F] font-medium text-sm block mb-1.5";
  const inputClass = "block bg-[#F3F3F3] text-[#3B59C3] rounded text-xs px-3 py-1.5 w-full sm:text-sm";

  return (
    <form className="mb-4" onSubmit={onSubmit}>
      <div className="flex flex-col gap-2">
        <div className={blockClass}>
          <label className={labelClass} htmlFor="name">Name</label>
          {errors && errors.validation?.name && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.name[0]}</div>
          )}
          <input
            id="name"
            type="text"
            value={nameText}
            onChange={e => setName(e.target.value)}
            className={inputClass}
            required
          />
        </div>
        <div className={blockClass}>
          <label className={labelClass} htmlFor="description">Description</label>
          {errors && errors.validation?.description && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.description[0]}</div>
          )}
          <input
            id="description"
            type="text"
            value={descriptionText}
            onChange={e => setDescription(e.target.value)}
            className={inputClass}
            required
          />
        </div>
      </div>
      <div className="flex gap-2 mt-2 text-sm">
        <Button type="submit" className="btn-primary text-white" loading={loading}>Save</Button>
        <Button type="button" className="btn-secondary text-[#54618D]" onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </form>
  );
};

PipelineCategoryForm.propTypes = {
  category: PropTypes.object,
  setShow: PropTypes.func.isRequired,
};

export default PipelineCategoryForm;