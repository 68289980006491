import React, { useContext } from "react";
import AuthLinks from "./AuthLinks";
import SiteLinks from "./SiteLinks";
import PropTypes from "prop-types";
import { UserContext } from "../contexts";
import UserMenu from "./UserMenu";
import QuickSearcher from "./QuickSearcher";

const MobileMenu = props => {

  const { menuRef } = props;
  const [user,] = useContext(UserContext);

  return (
    <div className="menu-gradient fixed left-0 right-0 z-40 top-16 w-full text-white pt-4 pb-2" ref={menuRef}>
      <QuickSearcher className="mb-2" />
      <SiteLinks />
      {!user && <AuthLinks mobile={true} />}
      {user && <UserMenu mobile={true} />}
    </div>
  );
};

MobileMenu.propTypes = {
  menuRef: PropTypes.object.isRequired
};

export default MobileMenu;