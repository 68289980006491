import { useState } from "react";
import SampleTable2 from "../components/SamplesTable2";
import { ClipLoader } from "react-spinners";
import { useFetch, useDocumentTitle } from "../hooks";
import Base from "./Base";

const PublicSamplesPage = () => {

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [samples, setSamples] = useState(null);
  const [filter, setFilter] = useState(null);
  const [hasSamples, setHasSamples] = useState(null);

  const pageSize = 25;

  useDocumentTitle("Public Samples - Flow");

  const { loading } = useFetch("/samples/public", {
    params: {page, count: pageSize, filter},
    onCompleted: data => {
      setCount(data.count);
      setSamples(data.samples);
      if (hasSamples === null) setHasSamples(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    }
  })

  const textClass = "mb-8 info sm:mb-10";

  const label = (hasSamples === false) ? "There are currently no public samples." : "These are the Flow Samples which have been set to be publicly available by their owners.";
  const showTable = (hasSamples === true);

  const showSource = samples && samples.some(s => s.source);
  const showPurificationTarget = samples && samples.some(s => s.purification_target);
  const showScientist = samples && samples.some(s => s.scientist !== s.owner_name);

  return (
    <Base>
      <h1>Public Samples</h1>
      <div className={textClass}>{label}</div>
      {loading && !showTable && (
        <div className="w-full h-48 flex justify-center items-center">
          <ClipLoader size={100} />
        </div>
      )}
      {showTable && (
        <SampleTable2
          samples={samples}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          totalCount={count}
          loading={loading}
          showSource={showSource}
          showPurificationTarget={showPurificationTarget}
          showScientist={showScientist}
          onFilter={s => setFilter(s || null)}
          noMessage="No public samples match that term." 
          showHeader={true}
        />
      )}
    </Base>
  );
};

PublicSamplesPage.propTypes = {
  
};

export default PublicSamplesPage;