import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useLazyFetch } from "../hooks";

const DeleteExecution = props => {

  const { execution } = props;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [{ loading }, deleteExecution] = useLazyFetch(`/executions/${execution.id}/delete`, {
    method: "POST",
    onCompleted: () => {
      setShowModal(false);
      navigate("/executions/");
    }
  })

  const text = `This will permanently delete the execution.`

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <div className="edit-section">
      <div className="edit-heading text-red-900">Deletion</div>
      <div className="text-sm mb-6 text-red-900">Permanently remove this execution - this will remove all of its data and history from Flow.</div>
      <button
        onClick={() => setShowModal(true)}
        className={`w-fit ${secondaryClass}`}
      >
        Remove Execution
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={"Remove Execution?"} text={text} className="max-w-lg">
          <div className="flex gap-3 mx-auto w-fit">
            <Button className={primaryClass} loading={loading} onClick={deleteExecution}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteExecution.propTypes = {
  execution: PropTypes.object.isRequired,
};

export default DeleteExecution;