import React from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { TOKEN } from "../queries";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import Button from "../components/Button";
import { fileSize } from "../utils";

const DownloadButton = props => {

  const { data } = props;

  const client = useApolloClient();

  const download = () => {
    let token;
    try {
      const cacheValue = client.cache.readQuery({query: TOKEN});
      token = `?token=${cacheValue.accessToken}`;
    } catch {
      token = "";
    }
    const url = `${process.env.REACT_APP_DOWNLOADS}/${data.id}/${data.filename}${token}`;
    const link = document.createElement("a");
    link.download = data.filename;
    link.href = url;
    link.click();
  }

  return (
    <Button className={`btn-primary gap-2 text-xs py-1 sm:text-sm ${props.className || ""}`} onClick={download}>
      <DownloadIcon className="w-4 h-auto" />
      Download ({fileSize(data.size)})
    </Button>
  );
};

DownloadButton.propTypes = {
  data: PropTypes.object.isRequired
};

export default DownloadButton;