import React from "react";
import Base from "./Base";
import { useDocumentTitle, useFetch } from "../hooks";
import { useParams } from "react-router-dom";
import Tabs from "../components/Tabs";
import { useState } from "react";
import Paper from "../components/Paper";
import ObjectTitle2 from "../components/ObjectTitle2";
import ObjectAttributes from "../components/ObjectAttributes";
import { ClipLoader } from "react-spinners";
import SampleTable2 from "../components/SamplesTable2";
import ExecutionTable2 from "../components/ExecutionsTable2";
import DataTable2 from "../components/DataTable2";

const ProjectPage = () => {
  const { id } = useParams();

  const [project, setProject] = useState(null);
  const [tab, setTab] = useState("Samples");

  const [samplesPage, setSamplesPage] = useState(1);
  const [samplesCount, setSamplesCount] = useState(null);
  const [samples, setSamples] = useState(null);
  const [samplesFilter, setSamplesFilter] = useState(null);
  const [hasSamples, setHasSamples] = useState(null);
  const samplesPageSize = 20;

  const [executionsPage, setExecutionsPage] = useState(1);
  const [executionsCount, setExecutionsCount] = useState(null);
  const [executions, setExecutions] = useState(null);
  const [executionsFilter, setExecutionsFilter] = useState(null);
  const [hasExecutions, setHasExecutions] = useState(null);
  const executionsPageSize = 20;

  const [dataPage, setDataPage] = useState(1);
  const [dataCount, setDataCount] = useState(null);
  const [data, setData] = useState(null);
  const [dataFilter, setDataFilter] = useState(null);
  const [hasData, setHasData] = useState(null);
  const dataPageSize = 25;

  const { loading, status } = useFetch(`/projects/${id}`, {
    onCompleted: data => setProject(data)
  });

  const { loading: samplesLoading } = useFetch(`/projects/${id}/samples`, {
    params: {page: samplesPage, count: samplesPageSize, filter: samplesFilter},
    onCompleted: data => {
      setSamplesCount(data.count);
      setSamples(data.samples);
      if (hasSamples === null) setHasSamples(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / samplesPageSize);
      if (samplesPage > maxPossiblePage) setSamplesPage(maxPossiblePage || 1);
    }
  })

  const { loading: executionsLoading } = useFetch(`/projects/${id}/executions`, {
    params: {page: executionsPage, count: executionsPageSize, filter: executionsFilter},
    onCompleted: data => {
      setExecutionsCount(data.count);
      setExecutions(data.executions);
      if (hasExecutions === null) setHasExecutions(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / executionsPageSize);
      if (executionsPage > maxPossiblePage) setExecutionsPage(maxPossiblePage || 1);
    }
  })

  const { loading: dataLoading } = useFetch(`/projects/${id}/data`, {
    params: {page: dataPage, count: dataPageSize, filter: dataFilter},
    onCompleted: data => {
      setDataCount(data.count);
      setData(data.data);
      if (hasData === null) setHasData(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / dataPageSize);
      if (dataPage > maxPossiblePage) setDataPage(maxPossiblePage || 1);
    }
  })

  useDocumentTitle(project ? `${project.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const longDescription = project.description.length > 400;

  return (
    <Base>
      <ObjectTitle2
        isPrivate={project.private}
        title={project.name} 
        type="project"
        editUrl={project.can_edit && `/projects/${id}/edit`}
      />
      <ObjectAttributes object={project} className="mb-2 md:mb-4" />
      <div className={`text-[#595959] text-xs lg:text-base 2xl:text-lg ${longDescription ? "max-w-2xl mb-6 md:text-sm md:mb-8 lg:max-w-4xl" : "max-w-xl mb-4 sm:text-sm md:mb-6 lg:max-w-2xl"}`}>
        {project.description}
      </div>
      <Tabs selected={tab} labels={["Samples", "Analysis History", "Data", "Papers"]} setSelected={setTab} />
      {tab === "Samples" && (
        <>
          {!samplesLoading && !hasSamples && (
            <div className="text-sm mb-6 info sm:mb-8">
              This project has no samples yet.
            </div>
          )}
          {samplesLoading && !hasSamples && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasSamples && (
            <SampleTable2
              samples={samples}
              page={samplesPage}
              pageSize={samplesPageSize}
              setPage={setSamplesPage}
              totalCount={samplesCount}
              loading={samplesLoading}
              onFilter={s => setSamplesFilter(s || null)}
              noMessage="No samples match that term." 
            />
          )}
        </>
      )}
      {tab === "Analysis History" && (
        <>
          {!executionsLoading && !hasExecutions && (
            <div className="text-sm mb-6 info sm:mb-8">
              This project has no analyis yet.
            </div>
          )}
          {executionsLoading && !hasExecutions && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasExecutions && (
            <ExecutionTable2
              executions={executions}
              page={executionsPage}
              pageSize={executionsPageSize}
              setPage={setExecutionsPage}
              totalCount={executionsCount}
              loading={executionsLoading}
              onFilter={e => setExecutionsFilter(e || null)}
              noMessage="No executions match that term." 
            />
          )}
        </>
      )}
      {tab === "Data" && (
        <>
          {!dataLoading && !hasData && (
            <div className="text-sm mb-6 info sm:mb-8">
              This project has no data.
            </div>
          )}
          {dataLoading && !hasData && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasData && (
            <DataTable2
              data={data}
              page={dataPage}
              pageSize={dataPageSize}
              setPage={setDataPage}
              totalCount={dataCount}
              loading={dataLoading}
              onFilter={d => setDataFilter(d || null)}
              noMessage="No data matches that term." 
            />
          )}
        </>
      )}
      {tab === "Papers" && project.papers.length > 0 && (
        <div className="flex flex-wrap gap-4 md:gap-6">
          {project.papers.map(paper => (
            <Paper paper={paper} key={paper.id} className="w-full md:max-w-xs"  />
          ))}
        </div>
      )}
      {tab === "Papers" && project.papers.length === 0 && (
        <div className="info text-sm">There are no papers associated with this project.</div>
      )}
    </Base>
  )

}

ProjectPage.propTypes = {
  
};

export default ProjectPage;