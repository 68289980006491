import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as PipelineIcon } from "../images/pipeline.svg";
import { ReactComponent as SampleIcon } from "../images/sample.svg";
import { ReactComponent as ProjectIcon } from "../images/project.svg";
import { ReactComponent as DataIcon } from "../images/data.svg";

const Quickstart = props => {

  const { headingClass } = props;

  const linkClass = "bg-[#F5F5F5] border border-[#DCDCDC] rounded px-4 py-3 font-medium w-full flex items-center gap-2 h-14 hover:bg-[#F0F0F0] md:w-60 lg:w-full xl:w-60";
  const iconClass = "w-8 h-auto";

  return (
    <div className="text-[#505B60] text-sm border-b border-[#E0E0E0] pb-8 pt-6">
      <h2 className={headingClass}>Quickstart</h2>
      <div className="flex flex-wrap gap-4 sm:gap-3 2xl:gap-6">
        <Link to="/pipelines/" className={linkClass}>
          <PipelineIcon className={iconClass.replace(/w-\d+/, "w-9")} />
          Run a Nextflow Pipeline
        </Link>
        <Link to="/projects/public/" className={linkClass}>
          <SampleIcon className={iconClass} />
          Browse existing datasets
        </Link>
        <Link to="/upload/" className={linkClass}>
          <ProjectIcon className={iconClass} />
          Upload your data
        </Link>
        <a href="https://docs.flow.bio" className={linkClass}>
          <DataIcon className={iconClass} />
          Read our documentation
        </a>
      </div>
    </div>
  );
};

Quickstart.propTypes = {
  headingClass: PropTypes.string.isRequired
};

export default Quickstart;