import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { useMutation } from "@apollo/client";
import { CREATE_REPO, UPDATE_REPO } from "../mutations";
import { PIPELINE_ADMIN } from "../queries";
import { parseError } from "../errors";

const PipelineRepoForm = props => {

  const { repo, setShow } = props;

  const [url, setUrl] = useState(null);
  const [originalUrl, setOriginalUrl] = useState(null);
  const [path, setPath] = useState(null);
  const [errors, setErrors] = useState(null);


  const urlText = url === null ? repo ? repo.url : "" : (url || "");
  const originalUrlText = originalUrl === null ? repo ? repo.originalUrl : "" : (originalUrl || "");
  const pathText = path === null ? repo ? repo.path : "" : (path || "");

  const [createRepo, createRepoMutation] = useMutation(CREATE_REPO, {
    variables: {url: urlText, originalUrl: originalUrlText, path: pathText},
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const [updateRepo, updateRepoMutation] = useMutation(UPDATE_REPO, {
    variables: {id: repo?.id, originalUrl: originalUrlText},
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const onUrlBlur = () => {
    const urlParts = url.split("/");
    const numSlashes = urlParts.length - 1;
    if (!path && url && numSlashes === 4) {
      const path = url.split("/").pop().replace(".git", "");
      setPath(path);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (repo) updateRepo();
    if (!repo) createRepo();
  };

  const loading = createRepoMutation.loading || updateRepoMutation.loading;

  const blockClass = "w-full max-w-sm";
  const labelClass = "text-[#37474F] font-medium text-sm block mb-1.5";
  const inputClass = "block bg-[#F3F3F3] text-[#3B59C3] rounded font-mono text-xs px-3 py-1.5 w-full sm:text-sm";

  return (
    <form className="py-6" onSubmit={onSubmit}>
      <div className="flex flex-wrap gap-x-8 gap-y-4 mb-6">
        <div className={`${blockClass} ${repo ? "pointer-events-none opacity-50" : ""}`}>
          <label className={labelClass} htmlFor="url">URL</label>
          {errors && errors.validation?.url && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.url[0]}</div>
          )}
          <input
            id="url"
            type="text"
            value={urlText}
            onChange={e => setUrl(e.target.value)}
            onBlur={onUrlBlur}
            className={inputClass}
            autoComplete="url"
            autoCapitalize="none"
            required
          />
        </div>
        <div className={blockClass}>
          <label className={labelClass} htmlFor="originalUrl">Original URL (Optional)</label>
          {errors && errors.validation?.originalUrl && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.originalUrl[0]}</div>
          )}
          <input
            id="originalUrl"
            type="text"
            value={originalUrlText}
            onChange={e => setOriginalUrl(e.target.value)}
            className={inputClass}
            autoComplete="url"
            autoCapitalize="none"
          />
        </div>
        <div className={`${blockClass} ${repo ? "pointer-events-none opacity-50" : ""}`}>
          <label className={labelClass} htmlFor="path">Path</label>
          {errors && errors.validation?.path && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.path[0]}</div>
          )}
          <input
            id="path"
            type="text"
            value={pathText}
            onChange={e => setPath(e.target.value)}
            className={inputClass}
            autoComplete="path"
            autoCapitalize="none"
            required
          />
        </div>
      </div>
      <div className="flex gap-2">
        <Button type="submit" className="btn-primary text-white" loading={loading}>Save</Button>
        <Button type="button" className="btn-secondary text-[#54618D]" onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </form>
  );
};

PipelineRepoForm.propTypes = {
  repo: PropTypes.object,
  setShow: PropTypes.func.isRequired,
};

export default PipelineRepoForm;