import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

const FileModal = props => {

  const { url, setShowModal } = props;

  return (
    <Modal setShowModal={setShowModal} className="px-0 py-0 w-full h-full">
      <iframe title="htmlView" src={url} className="w-full h-full" />
    </Modal>
  );
};

FileModal.propTypes = {
  url: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default FileModal;