import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

const QuickSearchResult = props => {

  const { object, query } = props;

  const type = object.type;

  const splitText = (text, query, truncate) => {
    const truncateLength = 100;
    if (!text) return ["", "", ""];
    const loc = text.toLowerCase().search(query.toLowerCase());
    if (loc === -1) return [text, "", ""];
    let pre = text.substring(0, loc);
    const within = text.substring(loc, loc + query.length);
    let post = text.substring(loc + query.length);
    if (truncate && pre.length > truncateLength)
      pre = "..." + pre.slice(pre.length - (truncateLength - 3)).trimStart();
    if (truncate && post.length > truncateLength)
      post = post.slice(0, truncateLength - 3).trimEnd() + "...";
    return [pre, within, post]
  }

  const name = splitText(
    object.name || object.filename || object.pipeline_name,
    query, false
  );
  const id = splitText(
    object.username || object.slug || object.identifier,
    query, false
  );
  const description = splitText(object.description, query, true);

  return (
    <Link to={`/${type}/${object.slug ? "@" : ""}${object.username || object.slug || object.id}/`} className="px-4 py-2.5 relative z-40 border-t border-[#F3F3F3] block hover:no-underline hover:bg-[#F6F6F6]">
      <div className="flex items-baseline gap-1 text-2xs mb-1 text-[#8B8B8B]">
        <div className="bg-[#3B59C3] text-white w-fit px-1 rounded opacity-60">{type}</div>
        <div>{moment(object.created * 1000).format("D MMM YYYY")}</div>
      </div>
      <div className="flex gap-1 text-xs items-baseline text-[#37474F]">
        <div className="font-medium">
          <span>{name[0]}</span><span className="text-[#3B59C3] font-semibold">{name[1]}</span><span>{name[2]}</span>
        </div>
        <div className="font-light font-inter text-[#8B8B8B]">
          {id[0]}<span className="text-[#3B59C3] font-medium">{id[1]}</span>{id[2]}
        </div>
      </div>
      <div className="text-2xs text-[#37474F]">
        {description[0]}<span className="text-[#3B59C3] font-semibold">{description[1]}</span>{description[2]}
      </div>
    </Link>
  );
};

QuickSearchResult.propTypes = {
  object: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
};

export default QuickSearchResult;