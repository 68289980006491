import React from "react";
import PropTypes from "prop-types";
import Itemiser from "./Itemiser";
import Table from "./Table";
import DataRow from "./DataRow";
import { processName } from "../utils";

const DataTable = props => {

  const { data, pageLength, noMessage, loading, currentPage, totalItems, pageChange, refetch } = props;

  const filter = (data, query) => data.filter(d => {
    const process = processName(d.upstreamProcessExecution?.processName || "");
    return (
      d.filename.toLowerCase().includes(query.toLowerCase()) ||
      (process && process.toLowerCase().includes(query.toLowerCase()))
    )
  })

  return (
    <Itemiser
      items={data} pageLength={pageLength} keepHeight={true} noMessage={noMessage} showDownload={true}
      loading={loading} currentPage={currentPage} totalItems={totalItems} pageChange={pageChange}
      filter={filter} refetch={refetch}
    >
      {
        ({items, selectedIds, setSelectedIds}) => (
          <Table>
            {items.map(d => (
              <DataRow data={d} key={d.id} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
            ))}
          </Table>
        )
      }
    </Itemiser>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  pageLength: PropTypes.number.isRequired,
  keepHeight: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  pageChange: PropTypes.func,
  refetch: PropTypes.array,
};

export default DataTable;