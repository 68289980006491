import React from "react";
import PropTypes from "prop-types";
import DataSelector2 from "./DataSelector2";
import Toggle from "./Toggle";

const MultiplexedDataSelector = props => {

  const { data, setData, placeholderClass, inputClass } = props;

  const togglePaired = paired => {
    if (data) {
      setData({paired: !paired, data: []});
    } else {
      setData({paired: !paired, data: []});
    }
  }

  const dataUpdated = newData => {
    if (data) {
      setData({paired: data.paired, data: newData})
    } else {
      setData({paired: false, data: newData})
    }
  }

  return (
    <div>
      <Toggle
        small={true}
        className="mb-2"
        labelClass="text-xs"
        falseLabel="Paired-end"
        trueLabel="Single-end"
        value={data? !data.paired : true}
        onChange={togglePaired}
      />
      <DataSelector2
        inputClass={inputClass}
        category={3}
        data={data ? data.data : undefined}
        paired={data ? data.paired : false}
        setData={dataUpdated}
        multiple={true}
        placeholderClass={placeholderClass}
        placeholder="Select multiplexed reads files"
      />
    </div>
  );
};

MultiplexedDataSelector.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  placeholderClass: PropTypes.string.isRequired,
};

export default MultiplexedDataSelector;