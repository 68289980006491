import React from "react";
import PropTypes from "prop-types";
import { useRef, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { TOKEN } from "../queries";
import { useFetch, useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";

const Selector = props => {

  const {
    value, onChange, returnText, text, fixed,
    query, textParam, displayProp, pageLength, initialData,
    options, inputClassName, optionsClassName, optionClassName
  } = props;

  // Ref for the whole component so we know if a click is on it or elsewhere
  const ref = useRef();

  // Ref to store timeout func
  const timeout = useRef();

  // Should the dropdown be shown?
  const [active, setActive] = useState(false);

  // What is the text that the user has typed?
  const [typedText, setTypedText] = useState("");

  // Track the page number for network requests
  const [page, setPage] = useState(1);

  // What downloaded options are there?
  const [downloadedOptions, setDownloadedOptions] = useState(null);

  // How many total options are there on the server for a given term?
  const [count, setCount] = useState(null);

  // Dismiss the dropdown if user clicks elsewhere
  useEffect(() => {
    const clickOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (active && !returnText) onChange(null);
        setActive(false);
        setTypedText("");
      }
    }
    window.addEventListener("click", clickOutside);
    return () => window.removeEventListener("click", clickOutside);
  }, [returnText])

  // Get location of dropdown
  const [location, setLocation] = useState(null);
  useEffect(() => {
    if (!fixed) return;
    const updateLocation = () => {
      if (ref.current) {
        const { top, bottom, left, right } = ref.current.getBoundingClientRect();
        setLocation({ top, bottom, left, right });
      }
    }
    updateLocation();
    const interval = setInterval(() => {
      if (active) updateLocation();
    }, 10);
    window.addEventListener("resize", updateLocation);
    return () => {
      window.removeEventListener("resize", updateLocation);
      clearInterval(interval);
    }
  }, [active, fixed])

  // Load initial options if there is a query
  const { loading: initialLoading } = useFetch(query, {
    skip: !query || initialData,
    params: {
      [textParam]: "", page: 1, count: pageLength
    },
    onCompleted: data => {
      setDownloadedOptions(data[Object.keys(data).find(key => !["count", "page"].includes(key))]);
      setCount(data.count);
    }
  })
  const downloadedOptionsToUse = downloadedOptions || (initialData ? initialData[Object.keys(initialData).find(key => !["count", "page"].includes(key))] : null);
  const countToUse = count || initialData?.count || null;

  // Function which is called when user types text
  const [{ loading: lazyLoading }, searchOptions] = useLazyFetch(query, {
    onCompleted: data => {
      setDownloadedOptions(data[Object.keys(data).find(key => !["count", "page"].includes(key))]);
      setCount(data.count);
    }
  });

  // Function which is called when user requests more options
  const [{ loading: extraLoading }, searchExtraOptions] = useLazyFetch(query, {
    onCompleted: data => {
      const currentIds = downloadedOptionsToUse.map(d => d.id);
      const newOptions = data[Object.keys(data).find(key => !["count", "page"].includes(key))].filter(
        o => !currentIds.includes(o.id)
      );
      setDownloadedOptions([...downloadedOptionsToUse, ...newOptions]);
      setCount(data.count);
    }
  });

  // Function for when user types text - need to send new query if there is one
  const textEntered = e => {
    setTypedText(e.target.value);
    if (query) {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        searchOptions({params: {
          [textParam]: e.target.value, page: 1, count: pageLength
        }})
      }, 500)
    }
  }

  // When a user clicks an option, pass the selected option up to the parent
  // component, and dismiss the dropdown.
  const optionClicked = option => {
    onChange(returnText ? [option.id, option.label] : option.id);
    setActive(false);
  }

  // Function for when a user indicates they want to make a new option
  const newOptionClicked = () => {
    onChange([null, typedText]);
    setActive(false);
  };

  const loadMoreClicked = () => {
    searchExtraOptions({params: {
      [textParam]: typedText,
      page: page + 1,
      count: pageLength,
    }})
    setPage(page + 1);
  }

  let filteredOptions = [];
  if (query) {
    // Create filteredOptions from whatever the current downloadedOptions are
    filteredOptions = downloadedOptionsToUse ? downloadedOptionsToUse.map(o => (
      {id: o.id, label: o[displayProp]}
    )) : []
  } else {
    // Create filteredOptions from whatever user has currently typed in
    filteredOptions = options.filter(o => (
      o.label.toLowerCase().includes(typedText.toLowerCase())
    ))
  }

  // Are there additional options available to download?
  const isMoreOptions = query && countToUse !== null && downloadedOptionsToUse && countToUse > downloadedOptionsToUse.length;

  // Which option is currently selected?
  const selectedOption = value ? filteredOptions.filter(o => o.id === value)[0] : null;

  // What should be displayed in the input?
  const textToDisplay = active ? typedText : selectedOption ? selectedOption.label : text || "";

  // Is data currently loading?
  const isLoading = initialLoading || lazyLoading || extraLoading;

  // What are all the labels?
  const allLabels = filteredOptions.map(o => o.label);

  return (
    <div ref={ref} className={`relative ${active ? "z-50" : "z-10"} ${props.className || ""}`}>
      <input
        className={`${!active || "rounded-b-none"} ${inputClassName || ""}`}
        onClick={() => setActive(true)}
        value={textToDisplay}
        onChange={textEntered}
      />
      <div
        className={`w-full ${fixed ? "fixed" : "absolute left-0 right-0"} z-40 overflow-hidden ${active || "hidden"} ${optionsClassName || ""}`}
        style={fixed ? {top: location?.bottom, left: location?.left, right: location?.right, width: (location?.right - location?.left) || 0} : {}}
      >
        {returnText && !allLabels.includes(typedText) && typedText.length > 0 && (
          <div onClick={newOptionClicked} className={optionClassName}>
            + Create '{typedText}'
          </div>
        )}
        {filteredOptions.map(option => (
          <div key={option.id} onClick={() => optionClicked(option)} className={optionClassName}>
            {option.label}
          </div>
        ))}
        {isLoading && (
          <div className={`mx-auto w-fit ${extraLoading ? "py-1" : "py-3"}`}>
            <ClipLoader color="#3C59C3" size={extraLoading ? 20 : 30} />
          </div>
        )}
        <div
          className={`${optionClassName} ${isMoreOptions && !isLoading ? "py-1 italic font-normal" : "hidden"}`}
          onClick={loadMoreClicked}
        >Load More</div>
      </div>
    </div>
  );
};

Selector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  returnText: PropTypes.bool,
  text: PropTypes.string,
  fixed: PropTypes.bool,
  query: PropTypes.string,
  textParam: PropTypes.string,
  displayProp: PropTypes.string,
  pageLength: PropTypes.number,
  options: PropTypes.array,
  inputClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  initialData: PropTypes.object,
};

export default Selector;