import React, { useState, useEffect } from "react";
import Base from "./Base";
import { useLocation, useNavigate } from "react-router-dom";
import PekaHeatmap from "../components/PekaHeatmap";
import PekaRbp from "../components/PekaRbp";
import PekaMotif from "../components/PekaMotif";
import PekaMotifDistribution from "../components/PekaMotifDistribution";
import Select from "../components/Select";
import Selector from "../components/Selector";
import PekaSearcher from "../components/PekaSearcher";

const PekaPage = () => {

  // What arguments are there?
  const params = new URLSearchParams(useLocation().search);
  const rbp = params.get("rbp");
  const motif = params.get("motif");
  const distribution = params.get("distribution");
  const [data, setData] = useState(null);

  const downloadData = (json, name) =>{
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",  dataStr);
    downloadAnchorNode.setAttribute("download", `${name}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND}/graphql`.replace("graphql", `peka/entities/`)
    ).then(resp => resp.json()).then(json => {
      setData(json);
    })
  }, [])

  

  return (
    <Base>
      <div className="flex justify-between gap-4">
        <h1>PEKA</h1>
        <PekaSearcher proteins={data?.proteins || []} motifs={data?.motifs || []} />
      </div>
      {!rbp && !motif && (
        <div className="max-w-4xl mb-3">
          To visualise RBP- or motif-centric comparisons of positional enrichments,
          type an RBP gene symbol or a sequence of RNA motif (using U, not T) into
          the search box. Alternatively, click onto any RBP or motif at the labels
          of the large heatmap below. You can zoom in/out of the heatmap, and
          hover over the boxes to show which protein and motif each belongs to.
        </div>
      )}
      {motif && (
        <div className="text-sm max-w-4xl mb-12">
          This page compares the enrichment of a specific motif-group across eCLIP
          datasets. The coverage of a given motif-group is visualised around high-confidence
          crosslink sites (tXn) across multiple transcriptomic regions, with or without
          repeating elements. Analysed regions were introns, 3’ UTRs, other exonic
          regions of protein-coding genes (5’ UTRs and the coding sequences), and the
          "whole gene", combining all previously listed regions. The code for
          plotting the metaprofiles of average motif coverage around crosslinks
          is available from <a href="https://github.com/ulelab/imaps/blob/master/src/imaps/sandbox/kmers.py" className="text-[#3B59C3]">Ulelab github</a>.
        </div>
      )}
      {!motif && (
        <div className="text-sm max-w-4xl mb-12">
          Positionally-enriched k-mer analysis (PEKA) is a software package for identifying
          enriched protein-RNA binding motifs from CLIP datasets. PEKA compares k-mer enrichment
          in proximity of high-confidence crosslink sites (tXn - thresholded crosslinks),
          located within crosslinking peaks and having a high cDNA count, relative to low-count
          crosslink sites located outside of peaks (oXn - outside crosslinks). This approach
          reduces the effects of technical biases, such as uridine-preference of UV crosslinking.
          Each k-mer is assigned a PEKA score, which is used to rank the k-mers from the most
          to the least enriched. Additionally, PEKA provides comprehensive visualisations of
          motif enrichment profiles around the high-confidence crosslink sites and clusters
          the motifs that display similar profiles. PEKA also enables motif discovery within 
          specific transcriptomic regions, including or excluding repetitive elements. PEKA
          code is available from <a href="https://github.com/ulelab/imaps/blob/master/src/imaps/sandbox/kmers.py" className="text-[#3B59C3]">Ulelab github</a>.
        </div>
      )}
      {rbp ? (
        <PekaRbp rbp={rbp} download={downloadData} />
      ) : distribution ? (
        <PekaMotifDistribution motif={motif} download={downloadData} />
      ) : motif ? (
        <PekaMotif motif={motif} download={downloadData}/>
      ) : (
        <PekaHeatmap download={downloadData} />
      )}
    </Base>
  );
};

PekaPage.propTypes = {
  
};

export default PekaPage;