import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as DeleteIcon } from "../images/delete.svg";
import { useState } from "react";
import BulkDeleteModal from "./BulkDeleteModal";

const BulkDeleteButton = props => {

  const { selectedIds, isExecutions, isSamples, refetch, postDelete } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        className={`btn-primary gap-2 py-0.5 text-sm px-2 bg-red-700 border-red-700 hover:border-red-800 hover:bg-red-800 ${selectedIds.length !== 0 || "opacity-20 pointer-events-none"} ${props.className || ""}`}
        onClick={() => setShowModal(true)}
      >
        <DeleteIcon className="w-4 h-auto fill-white" />
        Delete
      </Button>
      {showModal && (
        <BulkDeleteModal
          setShowModal={setShowModal}
          isExecutions={isExecutions}
          isSamples={isSamples}
          selectedIds={selectedIds}
          refetch={refetch}
          postDelete={postDelete}
        />
      )}
    </>
  );
};

BulkDeleteButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  isExecutions: PropTypes.bool,
  isSamples: PropTypes.bool,
};

export default BulkDeleteButton;