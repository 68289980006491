export const parseError = error => {
  /**
   * Takes a Javascript error object and returns one with the following fields:
   * 
   * networkError: Boolean (is caused by difficulty reaching the server?)
   * message: String (the raw error message sent)
   * validation: Object (if the message is stringified JSON, this is it parsed)
   */

  const isNetwork = Boolean(error.networkError);
  let validation;
  let message;
  try {
    validation = JSON.parse(error.message);
    message = "";
  } catch {
    validation = null;
    message = error.message;
  }
  return {
    networkError: isNetwork && error.networkError.name === "TypeError",
    message, validation
  }
}