import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import GroupForm from "../components/GroupForm";
import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";
import GroupAdminUserGrid from "../components/GroupAdminUserGrid";
import DeleteGroup from "../components/DeleteGroup";

const EditGroupPage = () => {

  const { slug } = useParams();

  const [group, setGroup] = useState(null);

  const { loading, status } = useFetch(`/groups/${slug}`, {
    onCompleted: data => setGroup(data)
  });

  useDocumentTitle(group ? `${group.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  if (!group.is_admin) return <Base notFound={true} />

  return (
    <Base>
      <div className="flex flex-col justify-between gap-y-3 items-center border-b border-[#F2F2F2] pb-5 mb-8 sm:flex-row sm:pb-4">
        <h1 className="mb-2 text-center border-b w-full border-[#F2F2F2] pb-3 sm:mb-0 sm:text-left sm:border-0 sm:w-auto sm:pb-0">Edit {group.name}</h1>
        <Link to={`/groups/@${group.slug}/`} className="btn-secondary py-1 px-4 text-sm">Finished Editing</Link>
      </div>
      <GroupForm group={group} />


      <GroupAdminUserGrid group={group} setGroup={setGroup} className="mt-12 pb-6" />

      <DeleteGroup group={group} />
    </Base>
  );
};

EditGroupPage.propTypes = {
  
};

export default EditGroupPage;