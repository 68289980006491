import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { CREATE_PIPELINE, UPDATE_PIPELINE } from "../mutations";
import { useMutation } from "@apollo/client";
import { PIPELINE_ADMIN } from "../queries";
import { parseError } from "../errors";

const PipelineForm = props => {

  const { pipeline, repoId, setShow, subcategories } = props;

  const [name, setName] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [isNfcore, setIsNfcore] = useState(null);
  const [isDemultiplex, setIsDemultiplex] = useState(null);
  const [preparesGenome, setPreparesGenome] = useState(null);
  const [importsSamples, setImportsSamples] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? pipeline ? pipeline.name : "" : (name || "");
  const subcategoryText = subcategory === null ? pipeline ? pipeline.subcategory.id : (subcategories[0].id || null) : (subcategory || "");
  const isNfcoreText = isNfcore === null ? pipeline ? pipeline.isNfcore : false : (isNfcore || false);
  const isDemultiplexText = isDemultiplex === null ? pipeline ? pipeline.isDemultiplex : false : (isDemultiplex || false);
  const preparesGenomeText = preparesGenome === null ? pipeline ? pipeline.preparesGenome : false : (preparesGenome || false);
  const importsSamplesText = importsSamples === null ? pipeline ? pipeline.importsSamples : false : (importsSamples || false);

  const [createPipeline, createPipelineMutation] = useMutation(CREATE_PIPELINE, {
    variables: {
      name: nameText, subcategory: subcategoryText, isNfcore: isNfcoreText,
      isDemultiplex: isDemultiplexText, preparesGenome: preparesGenomeText,
      importsSamples: importsSamplesText, repo: repoId
    },
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const [updatePipeline, updatePipelineMutation] = useMutation(UPDATE_PIPELINE, {
    variables: {
      id: pipeline?.id, name: nameText, subcategory: subcategoryText,
      isNfcore: isNfcoreText, isDemultiplex: isDemultiplexText,
      preparesGenome: preparesGenomeText, importsSamples: importsSamplesText
    },
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    if (pipeline) updatePipeline();
    if (!pipeline) createPipeline();
  };

  const loading = createPipelineMutation.loading || updatePipelineMutation.loading;

  const blockClass = "w-full max-w-xs mb-2";
  const checkBlockClass = "flex gap-1 items-center cursor-pointer";
  const labelClass = "text-[#37474F] font-medium text-xs block cursor-pointer";
  const inputClass = "block bg-[#F3F3F3] text-[#3B59C3] rounded outline-none text-xs px-3 py-1.5 w-full";

  return (
    <form onSubmit={onSubmit} className="">
      <div className={blockClass}>
        <label className={labelClass} htmlFor="name">Name</label>
        {errors && errors.validation?.name && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.name[0]}</div>
        )}
        <input
          id="name"
          type="text"
          value={nameText}
          onChange={e => setName(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="subcategory">Subcategory</label>
        <select 
          className={inputClass}
          value={subcategoryText}
          onChange={e => setSubcategory(e.target.value)}
        >
          {subcategories.map(subcategory => (
            <option key={subcategory.id} value={subcategory.id}>
              {subcategory.category.name}/{subcategory.name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-wrap gap-x-4 gap-y-1 max-w-sm">
        <div className={checkBlockClass}>
          <label className={labelClass} htmlFor="isNfcore">nf-core</label>
          <input
            id="isNfcore"
            type="checkbox"
            checked={isNfcoreText}
            onChange={e => setIsNfcore(e.target.checked)}
          />
        </div>
        <div className={checkBlockClass}>
          <label className={labelClass} htmlFor="isDemultiplex">demultiplex</label>
          <input
            id="isDemultiplex"
            type="checkbox"
            checked={isDemultiplexText}
            onChange={e => setIsDemultiplex(e.target.checked)}
          />
        </div>
        <div className={checkBlockClass}>
          <label className={labelClass} htmlFor="preparesGenome">prepares genome</label>
          <input
            id="preparesGenome"
            type="checkbox"
            checked={preparesGenomeText}
            onChange={e => setPreparesGenome(e.target.checked)}
          />
        </div>
        <div className={checkBlockClass}>
          <label className={labelClass} htmlFor="importsSamples">imports samples</label>
          <input
            id="importsSamples"
            type="checkbox"
            checked={importsSamplesText}
            onChange={e => setImportsSamples(e.target.checked)}
          />
        </div>
      </div>
      <div className="flex gap-2 text-xs mt-1">
        <Button type="submit" className="btn-primary text-white py-1 px-2" loading={loading}>Save</Button>
        <Button type="button" className="btn-secondary text-[#54618D] py-1 px-2" onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </form>
  );
};

PipelineForm.propTypes = {
  pipeline: PropTypes.object,
  repoId: PropTypes.string,
  setShow: PropTypes.func.isRequired,
  subcategories: PropTypes.array.isRequired
};

export default PipelineForm;