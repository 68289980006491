import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDocumentTitle } from "../hooks";
import { useFetch } from "../hooks";
import ExecutionTable2 from "../components/ExecutionsTable2";
import { ClipLoader } from "react-spinners";
import Base from "./Base";
import ObjectTitle2 from "../components/ObjectTitle2";
import GenomeFiles from "../components/GenomeFiles";
import Tabs from "../components/Tabs";
import ObjectAttributes from "../components/ObjectAttributes";

const GenomePage = () => {

  const { id } = useParams();

  const [genome, setGenome] = useState(null);
  const [tab, setTab] = useState("Preparations");
  const [preparationsPage, setPreparationsPage] = useState(1);
  const [preparationsCount, setPreparationsCount] = useState(null);
  const [preparations, setPreparations] = useState(null);
  const [preparationsFilter, setPreparationsFilter] = useState(null);
  const [hasPreparations, setHasPreparations] = useState(null);
  const preparationsPageSize = 25;

  const [analysisPage, setAnalysisPage] = useState(1);
  const [analysisCount, setAnalysisCount] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [analysisFilter, setAnalysisFilter] = useState(null);
  const [hasAnalysis, setHasAnalysis] = useState(null);
  const analysisPageSize = 25;

  const { loading, status } = useFetch(`/genomes/${id}`, {
    onCompleted: data => setGenome(data)
  });

  useDocumentTitle(genome ? `${genome.name} - Flow` : "Flow");
  
  const { loading: preparationsLoading } = useFetch(`/genomes/${id}/executions`, {
    params: {page: preparationsPage, count: preparationsPageSize, filter: preparationsFilter, preparations: true},
    onCompleted: data => {
      setPreparationsCount(data.count);
      setPreparations(data.executions);
      if (hasPreparations === null) setHasPreparations(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / preparationsPageSize);
      if (preparationsPage > maxPossiblePage) setPreparationsPage(maxPossiblePage || 1);
    }
  })

  const { loading: analysisLoading } = useFetch(`/genomes/${id}/executions`, {
    params: {page: analysisPage, count: analysisPageSize, filter: analysisFilter, preparations: false},
    onCompleted: data => {
      setAnalysisCount(data.count);
      setAnalysis(data.executions);
      if (hasAnalysis === null) setHasAnalysis(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / analysisPageSize);
      if (analysisPage > maxPossiblePage) setAnalysisPage(maxPossiblePage || 1);
    }
  })

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  return (
    <Base>
      <ObjectTitle2
        title={`${genome.name} (${genome.organism.name})`}
        type="genome"
      />
      <ObjectAttributes object={genome} className="mb-4" />
      <GenomeFiles genome={genome} className="mb-8" />
      <Tabs labels={["Preparations", "Analysis"]} selected={tab} setSelected={setTab} />
      {tab === "Preparations" && (
        <>
          {!preparationsLoading && !hasPreparations && (
            <div className="text-sm mb-6 info sm:mb-8">
              This genome has no genome preps yet.
            </div>
          )}
          {preparationsLoading && !hasPreparations && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasPreparations && (
            <ExecutionTable2
              executions={preparations}
              page={preparationsPage}
              pageSize={preparationsPageSize}
              setPage={setPreparationsPage}
              totalCount={preparationsCount}
              loading={preparationsLoading}
              onFilter={s => setPreparationsFilter(s || null)}
              noMessage="No genome preps match that term." 
            />
          )}
        </>
      )}
      {tab === "Analysis" && (
        <>
          {!analysisLoading && !hasAnalysis && (
            <div className="text-sm mb-6 info sm:mb-8">
              This genome has no analysis yet.
            </div>
          )}
          {analysisLoading && !hasAnalysis && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasAnalysis && (
            <ExecutionTable2
              executions={analysis}
              page={analysisPage}
              pageSize={analysisPageSize}
              setPage={setAnalysisPage}
              totalCount={analysisCount}
              loading={analysisLoading}
              onFilter={s => setAnalysisFilter(s || null)}
              noMessage="No analyses match that term." 
            />
          )}
        </>
      )}
    </Base>
  );
};

GenomePage.propTypes = {
  
};

export default GenomePage;