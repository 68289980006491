import React from "react";
import PropTypes from "prop-types";
import SampleRow from "./SampleRow";
import Table from "./Table";
import Itemiser from "./Itemiser";

const SampleTable = props => {

  const { samples, pageLength, noMessage, loading, currentPage, totalItems, pageChange, refetch } = props;

  const filter = (samples, query) => samples.filter(s => {
    return (
      s.name.toLowerCase().includes(query.toLowerCase())
    )
  })

  return (
    <Itemiser
      items={samples} pageLength={pageLength} keepHeight={true} noMessage={noMessage} showDownload={true}
      loading={loading} currentPage={currentPage} totalItems={totalItems} pageChange={pageChange}
      filter={filter} refetch={refetch}
    >
      {
        ({items, selectedIds, setSelectedIds}) => (
          <Table>
            {items.map(s => (
              <SampleRow sample={s} key={s.id} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
            ))}
          </Table>
        )
      }
    </Itemiser>
  );
};

SampleTable.propTypes = {
  samples: PropTypes.array.isRequired,
  pageLength: PropTypes.number.isRequired,
  keepHeight: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  pageChange: PropTypes.func,
  refetch: PropTypes.array,
};

export default SampleTable;