import { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ReportIcon } from "../images/report.svg";
import { ReactComponent as TimelineIcon } from "../images/timeline.svg";
import { ReactComponent as DagIcon } from "../images/dag.svg";
import FileModal from "./FileModal";
import { TOKEN } from "../queries";
import { useApolloClient } from "@apollo/client";

const ExecutionReports = props => {

  const { execution } = props;

  const [showReport, setShowReport] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showDag, setShowDag] = useState(false);

  const client = useApolloClient();

  let token;
  try {
    const cacheValue = client.cache.readQuery({query: TOKEN});
    token = `?token=${cacheValue.accessToken}&direct=yes`;
  } catch {
    token = "?direct=yes";
  }

  const reportUrl = `${process.env.REACT_APP_EXECUTIONS}/${execution.id}/report.html${token}`;
  const timelineUrl = `${process.env.REACT_APP_EXECUTIONS}/${execution.id}/timeline.html${token}`;
  const dagUrl = `${process.env.REACT_APP_EXECUTIONS}/${execution.id}/dag.html${token}`;

  const rowClass = `flex items-center gap-2 text-xs ${execution.has_report ? "opacity-90 cursor-pointer" : "opacity-50 pointer-events-none"} font-medium group`;
  const iconClass = "w-5 h-auto";
  const spanClass = "group-hover:underline";

  return (
    <div className="flex flex-col gap-x-6 gap-y-4 sm:flex-row sm:items-center">
      <div className={`${rowClass}`} onClick={() => setShowReport(true)}>
        <ReportIcon className={iconClass} fill="#37474F" />
        <span className={spanClass}>Report</span>
      </div>
      <div className={`${rowClass}`} onClick={() => setShowTimeline(true)}>
        <TimelineIcon className={iconClass} fill="#37474F" />
        <span className={spanClass}>Timeline</span>
      </div>
      <div className={`${rowClass}`} onClick={() => setShowDag(true)}>
        <DagIcon className={iconClass} fill="#37474F" />
        <span className={spanClass}>DAG</span>
      </div>
      {showReport && (
        <FileModal setShowModal={setShowReport} url={reportUrl} />
      )}
      {showTimeline && (
        <FileModal setShowModal={setShowTimeline} url={timelineUrl} />
      )}
      {showDag && (
        <FileModal setShowModal={setShowDag} url={dagUrl} />
      )}
    </div>
  );
};

ExecutionReports.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionReports;