import React, { useRef, useState } from "react";
import FileInput from "./FileInput";
import UploadButton from "./UploadButton";
import { useLazyFetch } from "../hooks";
import { upload } from "../upload";

const DataUploader2 = props => {

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(null);
  const [dataIds, setDataIds] = useState({});
  const [error, setError] = useState("");
  const canceled = useRef(false);

  const [,uploadData] = useLazyFetch("/upload", {method: "POST", form: true});

  const sendChunk = async (filename, chunk, expectedFileSize, isLast, previous, extra) => {
    const params = {
      blob: chunk, is_last: isLast, expected_file_size: expectedFileSize,
      data: previous?.data?.id || "", filename, ...extra
    }
    return await uploadData({params});
  }

  const onNetworkError = () => {
    setError("There was a network error while uploading the data.");
  }

  const onError = () => {
    setError("There was a problem uploading the data.");
  }

  const upload_ = async () => {
    return upload(files, 1_000_000, setProgress, setDataIds, canceled, sendChunk, onNetworkError, onError);
  }

  const cancel = () => {
    setError("");
    setProgress(null);
    setDataIds({});
    setFiles([]);
    canceled.current = true;
  }

  const canCancel = progress && Object.values(progress).length > 0 && !error && Object.values(progress).some(p => p < 1);
  
  return (
    <div className="max-w-2xl">
      <FileInput
        files={files}
        setFiles={setFiles}
        multiple={true}
        allowDirectories={true}
        progress={progress}
        setProgress={setProgress}
        dataIds={dataIds}
        setDataIds={setDataIds}
        error={error}
        setError={setError}
        className="mb-8"
      />
      <UploadButton files={files} onClick={upload_} cancel={canCancel ? cancel : null} />
    </div>
  );
};

DataUploader2.propTypes = {
  
};

export default DataUploader2;