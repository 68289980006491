import { useState } from "react";
import PipelinesGrid from "../components/PipelinesGrid";
import Tabs from "../components/Tabs";
import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";

const PipelinesPage = () => {

  const [tab, setTab] = useState(null);

  useDocumentTitle("Run Analysis - Flow");

  const { loading, data: categories } = useFetch("/pipelines");

  if (loading) return <Base loading={true} />

  const categoryNames = categories.map(cat => cat.name);
  const category = categories.filter(c => tab ? c.name === tab : true)[0];
  const tabName = category?.name;

  if (!category) return <Base><h1>Run Analysis</h1></Base>

  return (
    <Base>
      <h1>Run Analysis</h1>
      <Tabs labels={categoryNames} selected={tabName} setSelected={setTab} />
      <div>
        <div className="mt-6 text-sm max-w-3xl mb-8 info sm:text-base sm:pl-2 md:mb-12 text-[#565656]">{category.description}</div>
        <div className="flex flex-col gap-20">
          {category.subcategories.map(subcategory => (
            <PipelinesGrid
              key={subcategory.name}
              name={subcategory.name}
              description={subcategory.description}
              pipelines={subcategory.pipelines}
            />
          ))}
        </div>
      </div>
    </Base>
  );
};

PipelinesPage.propTypes = {
  
};

export default PipelinesPage;