import { useState } from "react";
import { useDocumentTitle } from "../hooks";
import ProjectsGrid2 from "../components/ProjectsGrid2";
import { ClipLoader } from "react-spinners";
import { useFetch } from "../hooks";
import Base from "./Base";

const PublicProjectsPage = () => {

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [projects, setProjects] = useState(null);
  const [filter, setFilter] = useState(null);
  const [hasProjects, setHasProjects] = useState(null);

  const pageSize = 12;

  useDocumentTitle("Public Projects - Flow");

  const { loading } = useFetch("/projects/public", {
    params: {page, count, filter},
    onCompleted: data => {
      setCount(data.count);
      setProjects(data.projects);
      if (hasProjects === null) setHasProjects(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    }
  })

  const textClass = "mb-8 info sm:mb-10";

  const label = (hasProjects === false) ? "There are currently no public projects." : "These are the Flow Projects which have been set to be publicly available by their owners.";
  const showTable = (hasProjects === true);

  return (
    <Base>
      <h1>Public Projects</h1>
      <div className={textClass}>{label}</div>
      {loading && !showTable && (
        <div className="w-full h-48 flex justify-center items-center">
          <ClipLoader size={100} />
        </div>
      )}
      {showTable && (
        <ProjectsGrid2
          projects={projects}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          totalCount={count}
          loading={loading}
          onFilter={s => setFilter(s || null)}
          noMessage="No public projects match that term." 
        />
      )}
    </Base>
  );
};

PublicProjectsPage.propTypes = {
  
};

export default PublicProjectsPage;