import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Paginator from "./Paginator";
import { ClipLoader } from "react-spinners";
import BulkDownloadButton from "./BulkDownloadButton";
import BulkDeleteButton from "./BulkDeleteButton";

const Itemiser = props => {
  /**
   * Takes a list of items and filters them by those that should appear on
   * screen.
   * 
   * There are essentially two modes the component can operate in - network mode
   * where the component is given the first 'page' of items and the means to
   * fetch more, and non-network mode, where the component gets all the items
   * and just paginates them itself.
   */

  const {
    items, pageLength, keepHeight, noMessage, showDownload, refetch,
    loading, currentPage, totalItems, pageChange, // network
    filter // non-network
  } = props;

  // Generic state
  const [height, setHeight] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);

  // Non-network state
  const [page, setPage] = useState(1); // Non-network only
  const [query, setQuery] = useState("");

  const ref = useRef();

  // If the height should be fixed, set it on render and after any loading
  useEffect(() => {
    if (ref.current && keepHeight && !loading) {
      setHeight(ref.current.clientHeight)
    };
  }, [loading, keepHeight])

  // If there are no items, report that
  if (items.length === 0 && !loading) return <div className="text-sm info">{noMessage}</div>

  // Do we need to filter the items?
  let filteredItems = query ? filter(items, query) : items;
  filteredItems = filteredItems.filter(i => !removedIds.includes(i.id));

  // Need to know what items to show on screen and how many pages there are
  let visibleItems; let pageCount;

  let filteredPage = page;
  if (pageChange) {
    // Get page count from total items
    pageCount = Math.ceil(totalItems / pageLength);

    // Show everything we have
    visibleItems = filteredItems;

    // Are we on the last page? Limit what we show in that case.
    const isLastPage = currentPage === pageCount;
    if (isLastPage && currentPage > 1) {
      const targetLength = (totalItems % pageLength) || pageLength;
      visibleItems = filteredItems.slice(pageLength - targetLength);
    }
  } else {
    // Only show those which fit page
    const currentPageCount = Math.ceil(filteredItems.length / pageLength);
    filteredPage = Math.min(page, currentPageCount);
    visibleItems = filteredItems.slice((filteredPage - 1) * pageLength, filteredPage * pageLength);

    // Get page count from overspill
    pageCount = Math.ceil(filteredItems.length / pageLength);
  }

  // Should there be a paginator?
  const showPaginator = pageCount > 1;

  // Should there be a filterer?
  const showFilter = !pageChange && items.length > 1;

  // Does there need to be a top row at all?
  const hasTopRow = showPaginator || showFilter || showDownload;

  // Is anything selected?
  const selectedOnPage = visibleItems.filter(i => selectedIds.includes(i.id));
  const anySelected = selectedOnPage.length > 0;

  // What object types are the items?
  const isExecutions = items[0]?.__typename === "ExecutionType";
  const isSamples = items[0]?.__typename === "SampleType";

  const clearSelectedAfterDeletion = selectedIds => {
    setSelectedIds([]);
    setRemovedIds([...removedIds, ...selectedIds]);
  }


  return (
    <div className="w-full max-w-full">
      {hasTopRow && (
        <div className="flex flex-wrap mb-2 gap-2 justify-between md:mb-0 md:h-12 md:flex-row md:items-center">
          <div className="flex items-center gap-2">
            {showFilter && (
              <input
                className="bg-[#F3F3F3] rounded text-sm px-4 py-2"
                value={query}
                placeholder="Filter..."
                onChange={e => setQuery(e.target.value)}
              />
            )}
            {showPaginator && (
              <Paginator
                currentPage={pageChange ? currentPage : filteredPage}
                totalPages={pageCount}
                onChange={pageChange || setPage}
              />
            )}
          </div>
          {showDownload && !loading && (
            <div className="flex items-center gap-2">
              <div
                className="text-xs link hidden w-fit lg:block"
                onClick={() => setSelectedIds(anySelected ? [] : [...selectedIds, ...visibleItems.map(i => i.id)])}
              >
                {anySelected ? "Deselect All" : "Select All"}
              </div>
              <BulkDeleteButton
                className="md:ml-auto"
                selectedIds={selectedIds}
                isExecutions={isExecutions}
                isSamples={isSamples}
                refetch={refetch}
                postDelete={clearSelectedAfterDeletion}
              />
              <BulkDownloadButton
                className="md:ml-auto"
                selectedIds={selectedIds}
                isExecutions={isExecutions}
                isSamples={isSamples}
              />
            </div>
          )}
        </div>
      )}
      <div ref={ref} style={{minHeight: height}}>
        {loading && <ClipLoader speedMultiplier={0.5}/>}
        {!loading && props.children({items: visibleItems, selectedIds, setSelectedIds})}
      </div>
    </div>
  );
};

Itemiser.propTypes = {
  items: PropTypes.array.isRequired,
  pageLength: PropTypes.number.isRequired,
  keepHeight: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  showDownload: PropTypes.bool,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  pageChange: PropTypes.func,
  filter: PropTypes.func,
};

export default Itemiser;