import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import { useNavigate } from "react-router-dom";
import ExecutionStatus from "./ExecutionStatus";
import { formatTimestamp } from "../utils";

const ExecutionRow = props => {

  const { execution, selectedIds, setSelectedIds } = props;

  const navigate = useNavigate();

  const rowClick = () => navigate(`/executions/${execution.id}/`);

  const boxClick = e => {
    e.stopPropagation();
    if (selectedIds.includes(execution.id)) {
      setSelectedIds(selectedIds.filter(id => id !== execution.id))
    } else {
      setSelectedIds([...selectedIds, execution.id])
    }
  }

  const cellClass = "px-4 py-3 whitespace-nowrap text-xs cursor-pointer";

  return (
    <tr onClick={rowClick} className={`${props.className || ""} bg-opacity-80 hover:bg-opacity-100`}>
      <td className={cellClass} onClick={boxClick}>
        <Checkbox checked={selectedIds.includes(execution.id)} onChange={() => {}} />
      </td>
      <td className={cellClass}>{execution.pipelineVersion.pipeline.name}</td>
      <td className={cellClass}>{execution.pipelineVersion.name}</td>
      <td className={`${cellClass} font-mono text-2xs`}>{execution.identifier}</td>
      <td className={cellClass}>{formatTimestamp(execution.created)}</td> 
      <td className={cellClass}>{execution.owner.name}</td>
      <td className={cellClass}>{execution.genome ? `${execution.genome.organism.name} (${execution.genome.name})` : ""}</td>
      <td className={`${cellClass} flex justify-center`}>
        <ExecutionStatus execution={execution} small={true} />
      </td>
    </tr>
  );
};

ExecutionRow.propTypes = {
  execution: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};

export default ExecutionRow;