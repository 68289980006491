import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { useMutation } from "@apollo/client";
import Button from "./Button";
import { PIPELINE_ADMIN } from "../queries";
import { DELETE_PIPELINE_CATEGORY } from "../mutations";

const DeletePipelineCategory = props => {

  const { category } = props;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const [deleteCategory, deleteCategoryMutation] = useMutation(DELETE_PIPELINE_CATEGORY, {
    variables: {id: category.id},
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShowModal(false),
    onError: () => setError("There was a problem deleting the category.")
  });

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  const text = `This category has no pipelines associated with it.`

  return (
    <div className="font-normal text-base inline-block">
      <button
        className="text-xs text-red-600 inline-block relative bottom-2"
        onClick={() => setShowModal(true)}
      >
        Delete
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={`Delete ${category.name}?`} text={text}>
          {error && <div className="text-red-900 text-center text-sm mb-4">{error}</div>}
          <div className="flex gap-3 mx-auto w-fit mt-4">
            <Button className={primaryClass} loading={deleteCategoryMutation.loading} onClick={deleteCategory}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeletePipelineCategory.propTypes = {
  category: PropTypes.object.isRequired,
};

export default DeletePipelineCategory;