import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as DeleteIcon } from "../images/delete.svg";
import { useState } from "react";
import Modal from "./Modal";
import { useLazyFetch } from "../hooks";

const BulkDeleteButton = props => {
  
  const { selectedIds, setSelectedIds, isExecutions, isSamples, onDelete } = props;

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let objects = isExecutions ? "executions" : isSamples ? "samples" : "data objects";
  if (selectedIds.length === 1) objects = objects.slice(0, -1);
  const text = `Are you sure you want to delete ${selectedIds.length === 1 ? "this" : "these"} ${selectedIds.length} ${objects}? This is irreversible.`;

  const [{ error }, deleteData] = useLazyFetch(isExecutions ? "/executions/delete" : isSamples ? "/samples/delete" : "/data/delete", {
    method: "POST",
    params: { ids: selectedIds },
  });

  const onSubmit = async () => {
    setLoading(true);
    await deleteData({
      onCompleted: async () => {
        await onDelete();
        setShowModal(false);
        setLoading(false);
        setSelectedIds([]);
      },
      onError: () => setLoading(false),
    });
  }

  return (
    <>
      <Button
        className={`btn-primary gap-1 py-px text-xs px-1.5 bg-red-700 border-red-700 hover:border-red-800 hover:bg-red-800 ${selectedIds.length !== 0 || "opacity-20 pointer-events-none"} ${props.className || ""}`}
        onClick={() => setShowModal(true)}
      >
        <DeleteIcon className="w-3 h-auto fill-white" />
        Delete
      </Button>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          className="w-full max-w-sm"
          title={`Delete ${selectedIds.length} ${objects}?`} text={text}
        >
          {error && (
            <div className="text-red-600 text-sm text-center">{error.message}</div> 
          )}
          <div className="flex gap-3 flex-col w-full mx-auto mt-4 sm:w-64 sm:flex-row">
            <Button
              className="btn-primary py-1 w-full bg-red-800 border-red-800 hover:border-red-800 hover:bg-red-900 sm:w-1/2 sm:py-1.2"
              loading={loading}
              onClick={onSubmit}
            >
              Yes, delete
            </Button>
            <button className="btn-secondary py-1 w-full sm:w-1/2 sm:py-1.5" onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </>
  );
};

BulkDeleteButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  isExecutions: PropTypes.bool,
  isSamples: PropTypes.bool,
};

export default BulkDeleteButton;