import React from "react";
import PropTypes from "prop-types";
import { formatTimestamp } from "../utils";
import moment from "moment";
import { PulseLoader } from "react-spinners";

const ExecutionTiming = props => {

  const { execution } = props;

  const boxClass = "rounded bg-[#EDEDEF] text-xs py-3 px-4 w-fit font-medium sm:text-sm";

  const duration = moment.duration(
    ((execution.task_finished * 1000) || moment()) - (execution.task_started * 1000)
  ).humanize();

  return (
    <div className="flex flex-col items-center whitespace-nowrap md:flex-row">
      <div className={`${boxClass} ${!execution.task_finished && "text-center"}`}>
        {execution.task_started && formatTimestamp(execution.task_started)}
        {!execution.task_started && <PulseLoader color="#37474F80" size={8} speedMultiplier={1.2} />}
      </div>
      <div className="relative w-2 h-16 md:w-36 md:h-2">
        {execution.task_started && (
          <div className="absolute w-36 bg-white bg-opacity-50 -left-16 -ml-1 top-6 text-xs font-medium text-center md:w-full md:-top-4 md:left-0 md:ml-0">
            {duration}
          </div>
        )}
        <div className="w-full h-full bg-[#EDEDEF]" />
      </div>
      <div className={`${boxClass} ${!execution.task_finished && "text-center"}`}>
        {execution.task_finished && formatTimestamp(execution.task_finished)}
        {!execution.task_finished && <PulseLoader color="#37474F80" size={8} speedMultiplier={1.2} />}
      </div>
    </div>
  );
};

ExecutionTiming.propTypes = {
  execution: PropTypes.object.isRequired
};

export default ExecutionTiming;