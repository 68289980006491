import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as GenericOrganismIcon } from "../images/genome.svg";
import { ReactComponent as HumanIcon } from "../images/human.svg";
import { ReactComponent as MouseIcon } from "../images/mouse.svg";
import { ReactComponent as RatIcon } from "../images/rat.svg";
import { ReactComponent as ZebrafishIcon } from "../images/zebrafish.svg";
import { ReactComponent as DrosophilaIcon } from "../images/drosophila.svg";
import { ReactComponent as YeastIcon } from "../images/yeast.svg";
import { ReactComponent as ChickenIcon } from "../images/chicken.svg";
import { ReactComponent as EcoliIcon } from "../images/ecoli.svg";

const OrganismIcon = props => {

  const { organism, className, alt } = props;

  const Icon = {
    Hs: HumanIcon,
    Mm: MouseIcon,
    Rn: RatIcon,
    Dr: ZebrafishIcon,
    Dm: DrosophilaIcon,
    Sc: YeastIcon,
    Gg: ChickenIcon,
    Ec: EcoliIcon
  }[organism.id] || GenericOrganismIcon;

  const padding = {
    Hs: "px-2",
    Mm: "",
    Rn: "",
    Dr: "",
    Dm: "",
    Sc: "",
    Gg: "",
    Ec: ""
  }[organism.id] || "";

  return  <Icon className={`${padding} ${className}`} alt={alt} />;
};

OrganismIcon.propTypes = {
  organism: PropTypes.object.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string
};

export default OrganismIcon;