import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const FinishedEditingLink = () => {

  const { pathname } = useLocation();

  const url = pathname.replace("/edit", "");
  
  return (
      <Link to={url} className="btn-secondary w-fit text-xs px-1 py-1 flex-shrink-0 md:text-sm md:px-4 md:py-1.5">
        Finished Editing
      </Link>
  );
};

FinishedEditingLink.propTypes = {
  
};

export default FinishedEditingLink;