import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { DELETE_PIPELINE_CONFIG } from "../mutations";
import { useMutation } from "@apollo/client";
import Button from "./Button";
import { PIPELINE_CONFIG } from "../queries";

const DeletePipelineConfig = props => {

  const { config } = props;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const [deleteConfig, deleteConfigMutation] = useMutation(DELETE_PIPELINE_CONFIG, {
    variables: {filename: config.filename},
    refetchQueries: [{query: PIPELINE_CONFIG}],
    awaitRefetchQueries: true,
    onCompleted: () => setShowModal(false),
    onError: () => setError("There was a problem deleting the config.")
  });

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  const text = `This will permanently delete the config - consider making a local copy first.`

  return (
    <div>
      <button
        className="btn-primary bg-red-700 border-red-700 hover:border-red-800 hover:bg-red-800 px-2 py-1 text-sm"
        onClick={() => setShowModal(true)}
      >
        Delete Config
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={`Delete ${config.filename}?`} text={text}>
          {error && <div className="text-red-900 text-center text-sm mb-4">{error}</div>}
          <div className="flex gap-3 mx-auto w-fit mt-4">
            <Button className={primaryClass} loading={deleteConfigMutation.loading} onClick={deleteConfig}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeletePipelineConfig.propTypes = {
  config: PropTypes.object.isRequired,
};

export default DeletePipelineConfig;