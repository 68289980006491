import React from "react";
import PropTypes from "prop-types";
import ExecutionRow from "./ExecutionRow";
import Table from "./Table";
import Itemiser from "./Itemiser";

const ExecutionTable = props => {

  const { executions, pageLength, noMessage, loading, currentPage, totalItems, pageChange, refetch } = props;

  const filter = (executions, query) => executions.filter(e => {
    const status = e.status === "OK" ? "completed" : e.finished ? "error" : e.started ? "running" : "waiting";
    return (
      e.pipelineVersion.pipeline.name.toLowerCase().includes(query.toLowerCase()) ||
      e.owner.name.toLowerCase().includes(query.toLowerCase()) ||
      e.identifier.includes(query.toLowerCase()) ||
      status.toLowerCase().includes(query.toLowerCase())
    )
  })

  return (
    <Itemiser
      items={executions} pageLength={pageLength} keepHeight={true} noMessage={noMessage} showDownload={true}
      loading={loading} currentPage={currentPage} totalItems={totalItems} pageChange={pageChange}
      filter={filter} refetch={refetch}
    >
      {
        ({items, selectedIds, setSelectedIds}) => (
          <Table>
            {items.map(e => (
              <ExecutionRow execution={e} key={e.id} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
            ))}
          </Table>
        )
      }
    </Itemiser>
  );
};

ExecutionTable.propTypes = {
  executions: PropTypes.array.isRequired,
  pageLength: PropTypes.number.isRequired,
  keepHeight: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  pageChange: PropTypes.func,
  refetch: PropTypes.array,
};

export default ExecutionTable;