import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import { fileSize, formatTimestamp, processName } from "../utils";
import { useNavigate } from "react-router-dom";

const DataRow = props => {

  const { data, selectedIds, setSelectedIds } = props;

  const navigate = useNavigate();

  const rowClick = () => navigate(`/data/${data.id}/`);

  const boxClick = e => {
    e.stopPropagation();
    if (selectedIds.includes(data.id)) {
      setSelectedIds(selectedIds.filter(id => id !== data.id))
    } else {
      setSelectedIds([...selectedIds, data.id])
    }
  }

  const cellClass = "px-4 py-3 whitespace-nowrap text-xs cursor-pointer";

  return (
    <tr onClick={rowClick} className={`${props.className || ""} bg-opacity-80 hover:bg-opacity-100`}>
      <td className={cellClass} onClick={boxClick}>
        <Checkbox checked={selectedIds.includes(data.id)} onChange={() => {}} />
      </td>
      <td className={`${cellClass} w-full`}>{data.filename}</td>
      <td className={cellClass}>{fileSize(data.size)}</td>
      <td className={cellClass}>{formatTimestamp(data.created)}</td>
      <td className={cellClass}>{data.owner.name}</td>
      <td className={cellClass}>
        {processName(data.upstreamProcessExecution?.processName || "") || "Uploaded"}
      </td>
    </tr>
  );
};

DataRow.propTypes = {
  data: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};

export default DataRow;