import React from "react";
import PropTypes from "prop-types";
import Toggle from "./Toggle";
import { ClipLoader } from "react-spinners";
import { useLazyFetch } from "../hooks";

const DependencyToggle = props => {

  const { execution, setExecution } = props;

  const [{loading}, setDependency] = useLazyFetch(`/executions/${execution.id}/edit`, {
    method: "POST",
    onCompleted: data => setExecution(data)
  })

  const toggleClicked = value => {
    setDependency({params: {dependent: value}})
  }

  return (
    <div className="edit-section">
      <div className="edit-heading">Independence</div>

      <div className="info mb-2 text-sm max-w-lg md:text-base md:mb-4">
        Executions can be part of samples or projects. By default, permissions on
        that sample/project don't apply to the execution. If you want to inherit
        permissions, you can do so here.
      </div>

      <div className="flex items-center gap-2">
        <Toggle
          value={execution.dependent} onChange={toggleClicked}
          trueLabel="Inherit permissions" falseLabel="Don't inherit permissions"
          className={!loading|| "opacity-60 pointer-events-none"}
        />
        {loading && <ClipLoader size={20} />}
      </div>
    </div>
  );
};

DependencyToggle.propTypes = {
  execution: PropTypes.object.isRequired,
  setExecution: PropTypes.func.isRequired,
};

export default DependencyToggle;