import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts";
import GroupsList from "./GroupsList";
import SiteLinks from "./SiteLinks";

const Sidebar = () => {

  const [user,] = useContext(UserContext);
  
  return (
    <div className="hidden flex-shrink-0 w-32 pl-6 py-4 left-0 flex-col justify-between overflow-y-auto gap-y-8 sm:flex xl:pb-6">
      <div>
        {user && user.memberships.length > 0 && <GroupsList />}
        <SiteLinks />
      </div>
      <div className="text-[#CCCCCC] text-xs flex flex-col gap-1">
        <Link to="/terms/">Terms {"&"} Conditions</Link>
        <Link to="/privacy/">Privacy Policy</Link>
        <a href="https://flow.bio">About Flow</a>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  
};

export default Sidebar;