import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { DELETE_GENOME } from "../mutations";
import { useMutation } from "@apollo/client";
import Button from "./Button";
import { GENOMES_ADMIN, PIPELINE_ADMIN } from "../queries";

const DeleteGenome = props => {

  const { genome } = props;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const [deleteGenome, deleteGenomeMutation] = useMutation(DELETE_GENOME, {
    variables: {id: genome.id},
    refetchQueries: [{query: GENOMES_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShowModal(false),
    onError: () => setError("There was a problem deleting the genome.")
  });

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  const text = `This genome has no executions associated with it.`

  return (
    <div className="font-normal text-base inline-block">
      <button
        className="text-xs text-red-600 inline-block"
        onClick={() => setShowModal(true)}
      >
        Delete
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={`Delete ${genome.name}?`} text={text}>
          {error && <div className="text-red-900 text-center text-sm mb-4">{error}</div>}
          <div className="flex gap-3 mx-auto w-fit mt-4">
            <Button className={primaryClass} loading={deleteGenomeMutation.loading} onClick={deleteGenome}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteGenome.propTypes = {
  genome: PropTypes.object.isRequired,
};

export default DeleteGenome;